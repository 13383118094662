import React, { useState } from "react";

import NavLink from "./NavLink";

const NavBar = ({ navLabels, handlePageSelect }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const activeLink = "bg-gradient-to-r from-blue-800 to-blue-700 text-white  font-semibold";
  const inactiveLink = "text-gray-900  font-semibold";

  return (
    <div className='flex items-center'>
      <div className='sm:flex-start flex flex-col sm:flex-row'>
        {navLabels.map((label, index) => (
          <NavLink
            key={index}
            className={`${selectedPage === index + 1 ? activeLink : inactiveLink} 
						${index === 0 ? "border border-gray-900" : " border-t border-b border-l border-r border- border-gray-900"} ${
              index === navLabels.length - 1 ? "  " : ""
            }  `}
            label={label}
            onClick={() => {
              handlePageSelect(index + 1);
              setSelectedPage(index + 1);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NavBar;
