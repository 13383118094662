import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerCommisionares from "../sidedrawer/EditSideDrawerCommisionares";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";
import PasswordModal from "../modals/PasswordModal";

import "react-toastify/dist/ReactToastify.css";

const CommisionaresTable = (onClose) => {
  const [komesari, setKomesari] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCommisionare, setSelectedCommisionare] = useState(null);
  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCommisionaresPerPage, setSelectedCommisionaresPerPage] = useState(12);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const limit = 12;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/commisionare?page=${currentPage}&limit=${selectedCommisionaresPerPage}`
      )
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedCommisionaresPerPage]);

  useEffect(() => {
    console.log("Komesari data updated:", komesari);
  }, [komesari]);

  const handleDelete = (commisionare) => {
    setShowPasswordModal(true);
    setSelectedCommisionare(commisionare);
  };

  const handlePasswordSubmit = () => {
    // Proverite da li je uneta ispravna lozinka
    if (password === "12344321") {
      // Postavite odgovarajuću lozinku
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      // Prikazati poruku o neispravnoj lozinci
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteConfirmation = (commisionareId) => {
    toast.success("Uspješno ste obrisali sudiju");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/commisionare/${commisionareId}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali sudiju");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedCommisionare(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedCommisionare(null);
  };

  const handleEditButtonClick = (selectedCommisionareData) => {
    setIsEditSideDrawerOpen(true);

    const formattedStartDate = selectedCommisionareData.startDate ? new Date(selectedCommisionareData.startDate) : null;
    const formattedEndDate = selectedCommisionareData.endDate ? new Date(selectedCommisionareData.endDate) : null;

    setSelectedCommisionare({
      ...selectedCommisionareData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleSort = () => {
    setIsSortingAsc(!isSortingAsc);

    const sortDirection = isSortingAsc ? "asc" : "desc";
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/commisionare?page=${currentPage}&limit=${limit}&sort=${sortDirection}`
      )
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCommisionaresPerPageChange = (e) => {
    const newCommisionaresPerPage = parseInt(e.target.value, 10);
    setSelectedCommisionaresPerPage(newCommisionaresPerPage);
    setCurrentPage(1);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commisionare?page=1&limit=${newCommisionaresPerPage}`)
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10 '>
              <tr>
                <th className='py-5 px-5 text-gray-200'>Ime</th>
                <th className='py-5 px-5 text-gray-200'>Prezime</th>
                <th
                  className='py-5 px-5 text-gray-200 cursor-pointer items-center justify-center align-middle text-center '
                  onClick={handleSort}
                >
                  Rating{" "}
                  {isSortingAsc ? (
                    <span className='transform -rotate-90 inline-block text-gray-200 '>🡆</span>
                  ) : (
                    <span className='transform rotate-90 inline-block text-gray-200'>🡆</span>
                  )}
                </th>

                <th className='py-5 px-5 text-gray-200 text-center'>Članarina</th>

                <th className='py-5 px-5 text-gray-200 text-center'>Akcije</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {komesari.map((komesar) => (
                <tr key={komesar._id} className=' cursor-pointer border border-gray-200 '>
                  <td className='py-2 px-5'>{komesar.firstName}</td>
                  <td className='py-2 px-5'>{komesar.lastName}</td>
                  <td className='py-2 px-5 text-center'>{komesar.commisionareRating}</td>

                  <td className='py-2 px-5 text-center'>
                    {komesar.price} KM / {komesar.commisionareRating === 2 ? "180 KM" : "100 KM"}
                  </td>

                  <td className='py-2 text-center flex flex-row justify-center'>
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/editIcon.png'
                        alt='edit'
                        onClick={() => handleEditButtonClick(komesar)}
                        className='w-6 cursor-pointer'
                      />
                    </div>

                    {isEditSideDrawerOpen && (
                      <EditSideDrawerCommisionares
                        selectedCommisionare={selectedCommisionare}
                        onClose={handleEditSideDrawerClose}
                      />
                    )}
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        onClick={() => handleDelete(komesar)}
                        className='w-6 cursor-pointer'
                      />
                    </div>
                  </td>
                </tr>
              ))}
              <tr className='border-t-2 '></tr>
            </tbody>
          </table>

          <div className='flex justify-between w-full'>
            <div className='my-5 px-5'>
              <div className='mr-2 mb-1'>Redovi:</div>
              <select
                className='px-2 py-1 border rounded-md'
                value={selectedCommisionaresPerPage}
                onChange={handleCommisionaresPerPageChange}
              >
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={36}>36</option>
              </select>
            </div>
            <div className='my-5 px-5 flex justify-center items-center'>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${selectedCommisionare?.firstName} ${selectedCommisionare?.lastName}`}
          onDelete={() => handleDeleteConfirmation(selectedCommisionare._id)}
          onCancel={handleDeleteCancel}
          type='sudiju'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default CommisionaresTable;
