import { useState, useEffect } from "react";
import axios from "axios";

import MainLayout from "../maincontent/MainLayout";
import HelpersTable from "../tables/HelpersTable";
import ResponsiveHelpersTable from "../tables/ResponsiveHelpersTable";
import SideDrawerHelpers from "../sidedrawer/SideDrawerHelpers";
import CreateButton from "../other/CreateButton";
import EditSideDrawerHelpers from "../sidedrawer/EditSideDrawerHelpers";
import RefereeSearch from "../other/ReactSearch";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HelpersPage = () => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define breakpoint as needed
  const [isLoading, setIsLoading] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]); // Initialize with an empty array
  const [selectedHelpersPerPage, setSelectedHelpersPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [helperi, setHelperi] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateButtonClick = () => {
    setIsSideDrawerOpen(true);
  };

  const handleSideDrawerClose = () => {
    setIsSideDrawerOpen(false);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers`)
      .then((response) => {
        setFilteredResults(response.data.helpers);
      })
      .catch((error) => {
        toast.error("Error fetching helpers:", error);
      });
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers?firstName=${searchQuery}`)
      .then((response) => {
        setHelperi(response.data.helpers);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching helpers:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedHelpersPerPage, searchQuery]);

  return (
    <MainLayout>
      <div className={`flex ${isMobile ? "flex-col " : ""} pt-20 px-9 justify-between text-center items-center`}>
        <div className={` ${isMobile ? "pb-3 text-4xl " : ""}text-center text-3xl font-bold `}>
          LISTA POMOĆNIH SUDIJA
        </div>

        <div className='justify-end'>
          <CreateButton buttonName='Kreiraj pomoćnog sudiju' onClick={handleCreateButtonClick} />
        </div>
      </div>
      {isSideDrawerOpen && <SideDrawerHelpers onClose={handleSideDrawerClose} />}
      <div className={`flex ${isMobile ? "justify-center " : ""} px-8 pt-2 justify-end`}>
        <RefereeSearch onSearch={handleSearch} placeholder='Pretraži...' />
      </div>
      <div className='flex w-full justify-center items-center'>
        <div className='w-full p-8 rounded-xl '>
          {isMobile ? <ResponsiveHelpersTable searchQuery={searchQuery} /> : <HelpersTable searchQuery={searchQuery} />}
          {isEditSideDrawerOpen && <EditSideDrawerHelpers onClose={handleEditSideDrawerClose} />}
        </div>
      </div>
      <ToastContainer />
    </MainLayout>
  );
};

export default HelpersPage;
