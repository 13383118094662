import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerHalls from "../sidedrawer/EditSideDrawerHall";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import LoadingSpinner from "../other/LoadingSpinner";
import PasswordModal from "../modals/PasswordModal";

import "react-toastify/dist/ReactToastify.css";

const HallsTable = (onClose) => {
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [halls, setHalls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [currentPage] = useState(1);
  const [hallsPerPage] = useState(12);
  const [, setTotalHallsCount] = useState(0);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    fetchHalls();
  }, [currentPage, hallsPerPage]);

  const fetchHalls = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/hall`)
      .then((response) => {
        setHalls(response.data);
        setTotalHallsCount(response.data.length);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching halls:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (hallId) => {
    setShowPasswordModal(true);
    setSelectedHall(hallId);
  };

  const handlePasswordSubmit = () => {
    if (password === "12344321") {
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteConfirmation = () => {
    toast.success("Uspješno ste obrisali dvoranu");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/hall/${selectedHall}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali dvoranu");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedHall(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedHall(null);
  };

  const handleEditButtonClick = (selectedHallData) => {
    setIsEditSideDrawerOpen(true);
    setSelectedHall(selectedHallData);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left'>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-10'>
              <tr>
                <th className='py-5 text-gray-200 text-center'>Naziv dvorane</th>
                <th className='py-5 text-gray-200 text-center'>Potreban rating</th>
                <th className='py-5 text-gray-200 text-center'>Akcije</th>
              </tr>
            </thead>
            <tbody className='p-5'>
              {halls.map((hall) => (
                <tr key={hall._id} className=' cursor-pointer border border-gray-200 '>
                  <td className='py-2 text-center'>{hall.name}</td>
                  <td className='py-2 text-center'>{hall.rating}</td>
                  <td className='py-2 text-center flex flex-row justify-center'>
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/editIcon.png'
                        alt='edit'
                        onClick={() => handleEditButtonClick(hall)}
                        className='w-6 cursor-pointer'
                      />
                    </div>

                    {isEditSideDrawerOpen && (
                      <EditSideDrawerHalls selectedHall={selectedHall} onClose={handleEditSideDrawerClose} />
                    )}

                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        onClick={() => handleDelete(hall._id)}
                        className='w-6 cursor-pointer'
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      )}
      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={selectedHall ? halls.find((hall) => hall._id === selectedHall)?.name : ""}
          onDelete={() => handleDeleteConfirmation(selectedHall._id)}
          onCancel={handleDeleteCancel}
          type='dvorana'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default HallsTable;
