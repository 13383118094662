import React from "react";

import MainLayout from "../maincontent/MainLayout";
import HelpBattlesTable from "../tables/HelpBattlesTable";

const HelpBattlesPage = () => {
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>ZAVRŠENE UTAKMICE</div>
        <div className='flex w-full justify-center items-center'>
          <div className='w-9/12  rounded-xl '>
            <HelpBattlesTable />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default HelpBattlesPage;
