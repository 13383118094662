import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

const StatisticHelperTable = () => {
  const [helperi, setHelperi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, setTotalPages] = useState(1);
  const [finishedBattleStats, setFinishedBattleStats] = useState({});
  const [finishedSeniorBattleStats, setFinishedSeniorBattleStats] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const helpersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`);
        setHelperi(helpersResponse.data.helpers);
        setTotalPages(helpersResponse.data.totalPages);
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (helperi.length === 0) return;

    const fetchHelperStats = async () => {
      try {
        const statsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/helper/categorystats`
        );
        setFinishedBattleStats(statsResponse.data);
      } catch (error) {
        toast.error("Error fetching helper statistics: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchHelperSeniorStats = async () => {
      try {
        const seniorStatsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/helper/categorystats`
        );
        setFinishedSeniorBattleStats(seniorStatsResponse.data);
      } catch (error) {
        toast.error("Error fetching senior helper statistics: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHelperStats();
    fetchHelperSeniorStats();
  }, [helperi]);

  const downloadExcel = () => {
    try {
      const data = helperi.map((helper) => {
        const Reprezentacija = finishedSeniorBattleStats[helper._id]?.Reprezentacija || 0;
        const PLBIHM = finishedSeniorBattleStats[helper._id]?.PLBIHM || 0;
        const PLBIHZ = finishedSeniorBattleStats[helper._id]?.PLBIHZ || 0;
        const SenioriA1 = finishedSeniorBattleStats[helper._id]?.SenioriA1 || 0;
        const SenioriA2 = finishedSeniorBattleStats[helper._id]?.SenioriA2 || 0;
        const SenioriA1Z = finishedSeniorBattleStats[helper._id]?.SenioriA1Z || 0;
        const Juniori = finishedBattleStats[helper._id]?.Juniori || 0;
        const Kadeti = finishedBattleStats[helper._id]?.Kadeti || 0;
        const KadetiB = finishedBattleStats[helper._id]?.KadetiB || 0;
        const Pioniri = finishedBattleStats[helper._id]?.Pioniri || 0;
        const PioniriB = finishedBattleStats[helper._id]?.PioniriB || 0;
        const Minibasket = finishedBattleStats[helper._id]?.Minibasket || 0;
        const Juniorke = finishedBattleStats[helper._id]?.Juniorke || 0;
        const Kadetkinje = finishedBattleStats[helper._id]?.Kadetkinje || 0;
        const Pionirke = finishedBattleStats[helper._id]?.Pionirke || 0;
        const MinibasketZ = finishedBattleStats[helper._id]?.MinibasketZ || 0;
        const Ostalo = finishedBattleStats[helper._id]?.Ostalo || 0;
        const Turnir = finishedBattleStats[helper._id]?.Turnir || 0;

        const Omladinska = Juniori + Kadeti + KadetiB + Pioniri + PioniriB + Juniorke + Kadetkinje + Pionirke;
        const Minibasketi = Minibasket + MinibasketZ;
        const AllCategories =
          Reprezentacija +
          PLBIHM +
          PLBIHZ +
          SenioriA1 +
          SenioriA2 +
          SenioriA1Z +
          Minibasketi +
          Omladinska +
          Ostalo +
          Turnir;

        return {
          "Ime i prezime": `${helper.firstName} ${helper.lastName}`,
          "R - BIH": Reprezentacija,
          "Prvenstvo BIH": PLBIHM,
          "Prvenstvo BIH Žene": PLBIHZ,
          "Seniori A1": SenioriA1,
          "Seniori A2": SenioriA2,
          "Seniori A1 Žene": SenioriA1Z,
          "Omladinska liga": Omladinska,
          Minibasket: Minibasketi,
          Ostalo: Ostalo,
          Turnir: Turnir,
          UKUPNO: AllCategories,
        };
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Statistika - Pomoćne sudije");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Statistika_Pomocnih_Sudija.xlsx";
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-cente '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left border-2 border-gray-900 mb-5'>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
              <tr>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ime i prezime</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>R - BIH </th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Prvenstvo BIH</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Prvenstvo BIH Žene</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Seniori A1</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Seniori A2</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Seniori A1 Žene</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Omladinska liga</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Minibasket</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ostalo</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Turnir</th>

                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO</th>
              </tr>
            </thead>
            <tbody className='p-2 text-sm'>
              {helperi.map((helper) => {
                const Reprezentacija = finishedSeniorBattleStats[helper._id]?.Reprezentacija || 0;
                const PLBIHM = finishedSeniorBattleStats[helper._id]?.PLBIHM || 0;
                const PLBIHZ = finishedSeniorBattleStats[helper._id]?.PLBIHZ || 0;
                const SenioriA1 = finishedSeniorBattleStats[helper._id]?.SenioriA1 || 0;
                const SenioriA2 = finishedSeniorBattleStats[helper._id]?.SenioriA2 || 0;
                const SenioriA1Z = finishedSeniorBattleStats[helper._id]?.SenioriA1Z || 0;
                const Juniori = finishedBattleStats[helper._id]?.Juniori || 0;
                const Kadeti = finishedBattleStats[helper._id]?.Kadeti || 0;
                const KadetiB = finishedBattleStats[helper._id]?.KadetiB || 0;
                const Pioniri = finishedBattleStats[helper._id]?.Pioniri || 0;
                const PioniriB = finishedBattleStats[helper._id]?.PioniriB || 0;
                const Minibasket = finishedBattleStats[helper._id]?.Minibasket || 0;
                const Juniorke = finishedBattleStats[helper._id]?.Juniorke || 0;
                const Kadetkinje = finishedBattleStats[helper._id]?.Kadetkinje || 0;
                const Pionirke = finishedBattleStats[helper._id]?.Pionirke || 0;
                const MinibasketZ = finishedBattleStats[helper._id]?.MinibasketZ || 0;
                const Ostalo = finishedBattleStats[helper._id]?.Ostalo || 0;
                const Turnir = finishedBattleStats[helper._id]?.Turnir || 0;

                const Omladinska = Juniori + Kadeti + KadetiB + Pioniri + PioniriB + Juniorke + Kadetkinje + Pionirke;

                const Minibasketi = Minibasket + MinibasketZ;

                const AllCategories =
                  Reprezentacija +
                  PLBIHM +
                  PLBIHZ +
                  SenioriA1 +
                  SenioriA2 +
                  SenioriA1Z +
                  Minibasketi +
                  Omladinska +
                  Ostalo +
                  Turnir;

                return (
                  <tr key={helper._id} className='hover:bg-gray-400 transition cursor-pointer'>
                    {helper.firstName !== "/" ? (
                      <>
                        <th className='py-1 text-gray-800 text-center border-r-2 border-b-2 border-gray-900 px-5'>
                          {helper.firstName} {helper.lastName}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {Reprezentacija}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {PLBIHM}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {PLBIHZ}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {SenioriA1}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {SenioriA2}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {SenioriA1Z}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {Omladinska}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {Minibasketi}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {Ostalo}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {Turnir}
                        </th>
                        <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                          {AllCategories}
                        </th>
                      </>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='flex justify-end items-center bg-green-900 rounded px-3 py-2 transform transition-transform hover:scale-105'>
            <img src='icons/excelLogo.svg' alt='EXCEL' className='w-7 h-7 mr-2' />
            <button onClick={downloadExcel} className='text-white font-semibold'>
              Download Excel
            </button>
          </div>
        </React.Fragment>
      )}
      <ToastContainer />
    </div>
  );
};

export default StatisticHelperTable;
