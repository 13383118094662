import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import MainLayout from "../maincontent/MainLayout";
import NavBar from "../navbars/NavBar.jsx";
import StatisticRefereeTable from "../tables/StatisticRefereeTable";
import StatisticHelperTable from "../tables/StatisticHelperTable";

const StatisticsPage = () => {
  const [activePage, setActivePage] = useState(1);
  const [statisticsData, setStatisticsData] = useState(null);

  const navLabels = [`Sudije`, "Pomoćne sudije"];

  useEffect(() => {
    // Fetch statistics data when the component mounts or when activePage changes
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle/totalMatchesByCategory`); // Update the endpoint as per your backend
        const data = response.data; // Use response.data instead of await response.json()
        setStatisticsData(data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchStatistics();
  }, [activePage]);
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>STATISTIKA</div>
        <div className='mt-5 flex flex-col'>
          <div className='mb-4 flex flex-wrap justify-center gap-4 '>
            <NavBar navLabels={navLabels} handlePageSelect={(page) => setActivePage(page)} />
          </div>
          <div className='w-full px-8 pb-8 rounded-xl '>
            {activePage === 1 && <StatisticRefereeTable />}
            {activePage === 2 && <StatisticHelperTable />}
          </div>
          {statisticsData && (
            <table className={`flex mb-5 justify-between  px-8 `}>
              <div>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Juniori:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Juniori}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Juniorke:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Juniorke || 0}</th>
                  </tr>
                </tbody>
              </div>
              <div>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Kadeti:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Kadeti}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Kadeti B:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.KadetiB}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Kadetkinje:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Kadetkinje}</th>
                  </tr>
                </tbody>
              </div>
              <div>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Pioniri:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Pioniri}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Pioniri B:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.PioniriB}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Pionirke:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Pionirke}</th>
                  </tr>
                </tbody>
              </div>
              <div>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      Minibasket:
                    </th>
                    <th className='w-32 text-gray-900 text-center '>{statisticsData.Minibasket}</th>
                  </tr>
                </tbody>
                <tbody className=' text-base border border-gray-900 mr-2 '>
                  <tr>
                    <th className='w-32 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center '>
                      MinibasketZ:
                    </th>
                    <th className='w-32 text-gray-900 text-center'>{statisticsData.MinibasketZ || 0}</th>
                  </tr>
                </tbody>
              </div>
            </table>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default StatisticsPage;
