import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerCommisionares from "../sidedrawer/EditSideDrawerCommisionares";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const ResponsiveCommisionaresTable = (onClose) => {
  const [komesari, setKomesari] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCommisionare, setSelectedCommisionare] = useState(null);
  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCommisionaresPerPage, setSelectedCommisionaresPerPage] = useState(12);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const limit = 12;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/commisionare?page=${currentPage}&limit=${selectedCommisionaresPerPage}`
      )
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedCommisionaresPerPage]);

  useEffect(() => {
    console.log("Komesari data updated:", komesari);
  }, [komesari]);

  const handleDelete = (commisionare) => {
    setShowDeleteModal(true);
    setSelectedCommisionare(commisionare);
  };

  const handleDeleteConfirmation = (commisionareId) => {
    toast.success("Uspješno ste obrisali sudiju");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/commisionare/${commisionareId}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali sudiju");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedCommisionare(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedCommisionare(null);
  };

  const handleEditButtonClick = (selectedCommisionareData) => {
    setIsEditSideDrawerOpen(true);

    const formattedStartDate = selectedCommisionareData.startDate ? new Date(selectedCommisionareData.startDate) : null;
    const formattedEndDate = selectedCommisionareData.endDate ? new Date(selectedCommisionareData.endDate) : null;

    setSelectedCommisionare({
      ...selectedCommisionareData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleSort = () => {
    setIsSortingAsc(!isSortingAsc);

    const sortDirection = isSortingAsc ? "asc" : "desc";
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/commisionare?page=${currentPage}&limit=${limit}&sort=${sortDirection}`
      )
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCommisionaresPerPageChange = (e) => {
    const newCommisionaresPerPage = parseInt(e.target.value, 10);
    setSelectedCommisionaresPerPage(newCommisionaresPerPage);
    setCurrentPage(1);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commisionare?page=1&limit=${newCommisionaresPerPage}`)
      .then((response) => {
        setKomesari(response.data.commisionares);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching commisionares:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-2 py-2 '>
              <tr>
                <th className='py-3 px-5 text-sm text-center text-gray-200'>Ime i prezime</th>

                <th className='py-3 px-5 text-gray-200 text-sm text-center'>Članarina</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {komesari.map((komesar, index) => (
                <React.Fragment key={komesar._id}>
                  <tr
                    key={komesar._id}
                    className={`hover:bg-gray-200  border border-gray-200 cursor-pointer ${
                      expandedRow === index ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setExpandedRow(index === expandedRow ? null : index)}
                  >
                    <td className='py-2 px-5 font-semibold'>
                      {komesar.firstName} {komesar.lastName}
                    </td>
                    <td className='py-2 px-5 text-center'>
                      {komesar.price} KM / {komesar.commisionareRating === 2 ? "180 KM" : "100 KM"}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan='3' className='px-10 py-2'>
                        <div>
                          <div className='flex justify-between font-medium '>
                            <p>Liga:</p>
                            <p className=' justify-end'>
                              {komesar.commisionareRating === 1 ? "A1, A2" : "Prvenstvo BiH"}
                            </p>
                          </div>
                        </div>
                        <div className='text-center justify-center items-center flex flex-col py-2'>
                          <button
                            onClick={() => handleEditButtonClick(komesar)}
                            className='border-2 w-full text-center text-white bg-gradient-to-r from-green-900 to-green-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg mb-2'
                          >
                            Uredi
                          </button>
                          <button
                            // onClick={() => handleDelete(komesar)}
                            className=' cursor-not-allowed border-2 w-full text-white bg-gradient-to-r from-red-900 to-red-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg'
                          >
                            Obriši
                          </button>
                        </div>
                        {isEditSideDrawerOpen && (
                          <EditSideDrawerCommisionares
                            selectedCommisionare={selectedCommisionare}
                            onClose={handleEditSideDrawerClose}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              <tr className='border-t-2 '></tr>
            </tbody>
          </table>

          <div className='flex justify-between w-full'>
            <div className='my-5 px-5'>
              <div className='mr-2 mb-1'>Redovi:</div>
              <select
                className='px-2 py-1 border rounded-md'
                value={selectedCommisionaresPerPage}
                onChange={handleCommisionaresPerPageChange}
              >
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={36}>36</option>
              </select>
            </div>
            <div className='my-5 px-5 flex justify-center items-center'>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${selectedCommisionare?.firstName} ${selectedCommisionare?.lastName}`}
          onDelete={() => handleDeleteConfirmation(selectedCommisionare._id)}
          onCancel={handleDeleteCancel}
          type='komesara'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ResponsiveCommisionaresTable;
