import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import jwtDecode from "jwt-decode";

import DatePicker from "react-datepicker";
import MainLayout from "../maincontent/MainLayout";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const RefAbsencePage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reason, setReason] = useState("");
  const [, setLoggedInUsername] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);

    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInUsername(decodedToken.firstName + " " + decodedToken.lastName);
      setLoggedInUserId(localStorage.getItem("loggedInUserId"));
    }

    if (loggedInUserId && token) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/referee/${loggedInUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (!data.startDate) {
            setStartDate(new Date());
          } else {
            setStartDate(new Date(data.startDate));
          }

          if (!data.endDate) {
            setEndDate(new Date());
          } else {
            setEndDate(new Date(data.endDate));
          }
          setReason(data.reason);
        })
        .catch((error) => {
          toast.error("Error fetching data:", error);
        });
    }
  }, [loggedInUserId, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!startDate || !endDate || !reason) {
      console.error("Please fill in all fields.");
      return;
    }

    const dataToSend = {
      startDate,
      endDate,
      reason,
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/referee/${loggedInUserId}`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Uspješno ste najavili odsustvo");
      })
      .catch((error) => {
        toast.error("Error updating absence:", error);
      });
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRemoveAbsence = (e) => {
    e.preventDefault();

    setStartDate(null);
    setEndDate(null);
    setReason("");

    const dataToSend = {
      startDate: null,
      endDate: null,
      reason: "",
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/referee/${loggedInUserId}`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Odsustvo je uklonjeno");
      })
      .catch((error) => {
        toast.error("Error removing absence:", error);
      });
  };

  return (
    <MainLayout>
      <div className='mt-20 text-center'>
        <div className='text-4xl font-bold pb-5 mb-4'>NAJAVA ODSUSTVA</div>
        <div className='flex justify-center items-center p-8   '>
          <form
            onSubmit={handleSubmit}
            className='text-left border p-9 rounded-xl bg-gray-200 bg-gradient-to-r from-gray-100 to-gray-100 border-gray-400 shadow-xl'
          >
            <p className=' text-center  border border-gray-400' />

            <div className='mb-4 mt-3'>
              <label className='block text-gray-700 text-lg text-center font-bold mb-2'>
                Datum i vrijeme početka odsustva:
              </label>
              <div className='text-center'>
                <DatePicker
                  id='startDate'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  dateFormat='dd.MM.yyyy, HH:mm'
                  timeFormat='HH:mm'
                  className='border border-gray-400 rounded px-2 py-1 w-full text-center'
                />
              </div>
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-lg font-bold mb-2 text-center'>
                Datum i vrijeme završetka odsustva:
              </label>
              <div className='text-center'>
                <DatePicker
                  id='endDate'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  dateFormat='dd.MM.yyyy, HH:mm'
                  timeFormat='HH:mm'
                  className='border border-gray-400 rounded px-2 py-1 w-full text-center'
                />
              </div>
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-lg font-bold mb-4 text-center'>Razlog odsustva:</label>
              <textarea
                className='w-full px-4 text-base  py-2 border border-gray-900 rounded-lg'
                rows='4'
                value={reason}
                onChange={handleReasonChange}
              ></textarea>
            </div>
            <div className='mb-4 bg-blue-600 rounded-lg text-center hover:bg-blue-700'>
              <button type='submit' className='  text-white text-center font-bold py-2 px-4 rounded'>
                Najavi odsustvo
              </button>
            </div>
            <div className='mb-4 bg-red-700 rounded-lg text-center hover:bg-red-800'>
              <button
                type='button'
                onClick={handleRemoveAbsence}
                className='text-white text-center font-bold py-2 px-4 rounded'
              >
                Ukloni odsustvo
              </button>
            </div>
            <p className=' text-center  border border-gray-400' />
          </form>
        </div>
      </div>
      <ToastContainer />
    </MainLayout>
  );
};

export default RefAbsencePage;
