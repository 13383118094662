import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";

import axios from "axios";

import "react-toastify/dist/ReactToastify.css";

const SideDrawerHelpers = ({ onClose }) => {
  const [, setHelperi] = useState([]);
  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [rating, setRating] = useState("1");
  const [statistika, setStatistika] = useState("ne");
  const [price, setPrice] = useState("0");

  useEffect(() => {
    fetchHelpers();
  }, []);

  const fetchHelpers = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers`)
      .then((response) => {
        setHelperi(response.data);
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      });
  };

  const handleCreateHelper = () => {
    if (!ime || !prezime) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const newHelper = {
      firstName: ime,
      lastName: prezime,
      helperRating: Number(rating),
      statistic: statistika,
      price: Number(price),
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/helpers`, newHelper)
      .then((response) => {
        toast.success("Uspješno ste kreirali sudiju");
        fetchHelpers();
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while creating the helper");
        }
      });
  };

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.3 }}
        className='fixed z-20 w-full right-0 top-0 h-screen md:w-2/5 lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg'
      >
        <div className='p-5'>
          <div className='flex flex-row justify-left items-center '>
            <img
              src='icons/leftArrow.png'
              alt='delete'
              className='w-6 h-6 cursor-pointer '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            ></img>
            <h1
              className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110  '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            >
              Back
            </h1>
          </div>

          <div className='my-4 mx-5'>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='firstName'>
              Ime:
            </label>
            <input
              id='firstName'
              placeholder='Unesite ime helperi'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
          </div>
          <div className='my-4 mx-5'>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='lastName'>
              Prezime:
            </label>
            <input
              id='lastName'
              placeholder='Unesite prezime helperi'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={prezime}
              onChange={(e) => setPrezime(e.target.value)}
            />
          </div>
          <div className='mt-4 mb-1 mx-5 text-left '>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='rating'>
              Rating:
            </label>
            <select
              id='helperRating'
              className='border rounded px-2 py-1 w-full'
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              {[...Array(4)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='mx-5 text-red-600 font-semibold text-sm'>
            <p>1 - Zapisnik, 2 - 24/14, Semafor, Zapisnik</p>
            <p>3 - Napredno, 4 - Expert (Sve dvorane)</p>
          </div>
          <div className='mt-2 mb-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='price'>
              Članarina:
            </label>
            <input
              id='price'
              placeholder='Unesite članarinu'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className='mt-2 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='lastName'>
              Statistika:
            </label>
            <div className='flex items-center'>
              <label htmlFor='da' className='mr-2'>
                <span className='text-gray-200 font-semibold'>DA</span>
              </label>
              <input
                type='checkbox'
                id='da'
                name='odgovor'
                value='da'
                className='form-radio'
                checked={statistika === "da"}
                onChange={() => setStatistika("da")}
              />
            </div>

            <div className='flex items-center'>
              <label htmlFor='ne' className='mr-2'>
                <span className='text-gray-200 font-semibold'>NE</span>
              </label>
              <input
                type='checkbox'
                id='ne'
                name='odgovor'
                value='ne'
                className='form-radio'
                checked={statistika === "ne"}
                onChange={() => setStatistika("ne")}
              />
            </div>
          </div>
          <div className='flex items-center justify-end mx-5'>
            <button
              className='border text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-10 rounded'
              onClick={() => {
                handleCreateHelper();
              }}
            >
              Kreiraj
            </button>
          </div>
        </div>
        <ToastContainer />
      </motion.div>
    </>
  );
};

export default SideDrawerHelpers;
