import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const MoneyRefTable = () => {
  const [refereeData, setRefereeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataForReferee = async (refereeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/referee/debt/${refereeId}`
      );
      setRefereeData(response.data);
    } catch (error) {
      console.error("Greška prilikom dohvatanja podataka:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loggedInUsername = localStorage.getItem("loggedInUsername");

  useEffect(() => {
    const loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      fetchDataForReferee(loggedInUserId);
    }
  }, []);

  return (
    <div className='flex flex-col justify-center '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='flex justify-center items-center'></div>
      )}
      <div className='flex justify-center'>
        <table className={`w-full md:w-4/5 lg:w-3/4 xl:w-3/5 text-left border-2 border-gray-900 mb-5`}>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                SUDIJA
              </th>
              <th className='py-2 text-gray-200 text-center text-lg border-r-2 bg-gradient-to-r from-indigo-800 to-indigo-800 border-gray-900'>
                {loggedInUsername}
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Juniori
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtJuniori} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Juniorke
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtJuniorke} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Kadeti
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtKadeti + refereeData.totalDebtKadetiB} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Kadetkinje
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtKadetkinje} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Pioniri
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtPioniri + refereeData.totalDebtPioniriB} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Pionirke{" "}
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtPionirke} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Minibasket{" "}
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtMinibasket} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                MinibasketZ
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtMinibasketZ} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Ostalo
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtOstalo} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Turnir
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {refereeData.totalDebtTurnir} KM
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='flex justify-center'>
        <table className={`w-10/12 md:w-3/5 lg:w-2/4 xl:w-4/12 text-left border-2 border-gray-900 mb-5`}>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO ISPLAĆENO
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {refereeData.totalPaidDebtUkupno} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO NEPLAĆENO
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {refereeData.totalDebtUkupno} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO{" "}
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {refereeData.totalPaidUnpaid} KM
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MoneyRefTable;
