import { useState, useEffect } from "react";

import MainLayout from "../maincontent/MainLayout";
import CommisionaresTable from "../tables/CommisionaresTable";
import ResponsiveCommisionaresTable from "../tables/ResponsiveCommisionaresTable";
import SideDrawerCommisionares from "../sidedrawer/SideDrawerCommisionares";
import CreateButton from "../other/CreateButton";
import EditSideDrawerCommisionares from "../sidedrawer/EditSideDrawerCommisionares";

const CommisionaresPage = () => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define breakpoint as needed

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateButtonClick = () => {
    setIsSideDrawerOpen(true);
  };

  const handleSideDrawerClose = () => {
    setIsSideDrawerOpen(false);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };
  return (
    <MainLayout>
      <div className={`flex ${isMobile ? "flex-col " : ""} pt-20 px-9 justify-between text-center items-center`}>
        <div className={` ${isMobile ? "pb-3 text-4xl " : ""}text-center text-3xl font-bold `}>LISTA KOMESARA</div>
        <div className='justify-end'>
          <CreateButton buttonName='Kreiraj komesara' onClick={handleCreateButtonClick} />
        </div>
      </div>
      {isSideDrawerOpen && <SideDrawerCommisionares onClose={handleSideDrawerClose} />}
      <div className='flex w-full justify-center items-center'>
        <div className='w-full p-8 rounded-xl '>
          {isMobile ? <ResponsiveCommisionaresTable /> : <CommisionaresTable />}
          {isEditSideDrawerOpen && <EditSideDrawerCommisionares onClose={handleEditSideDrawerClose} />}
        </div>
      </div>
    </MainLayout>
  );
};

export default CommisionaresPage;
