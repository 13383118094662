import React, { useState } from "react";

const PoHelpSeniorBattlesTable = ({ seniorBattle }) => {
  const [isPaid] = useState(seniorBattle.isPaid);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  return (
    <div className='flex flex-row items-center '>
      <table key={seniorBattle._id} className='text-left border-2 border-gray-900 mb-5 w-full'>
        <thead className=' py-3 text-sm border-2 border-gray-900'>
          <tr>
            <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900 w-32'>
              <div class='flex flex-col items-center '>
                <span class='text-gray-900 text-center '>UTAKMICA</span>
              </div>
            </th>
            <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900 w-36'>
              <div class='flex flex-col items-center'>
                <td className='py-2 text-center font-bold text-gray-900 border-gray-900'>
                  {seniorBattle && seniorBattle.date ? formatDate(seniorBattle.date) : ""}
                </td>
                <span class='text-gray-900 text-center'>
                  {" "}
                  {seniorBattle && seniorBattle.hall ? seniorBattle.hall.name : ""}
                </span>
              </div>
            </th>

            <tr>
              <th className='py-4 text-red-600 text-center border-r-2 bg-gray-200 border-gray-900 px-8 border-b-2 w-96'>
                {seniorBattle && seniorBattle.teamA ? seniorBattle.teamA.name : ""}
              </th>
              <th className='py-4 text-red-600 text-center border-b-2 bg-gray-200 border-gray-900 px-8 w-96 '>
                {seniorBattle && seniorBattle.teamB ? seniorBattle.teamB.name : ""}
              </th>
            </tr>
            <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Zapisnik</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                  {seniorBattle?.helpers && seniorBattle.helpers[0]
                    ? `${seniorBattle.helpers[0]?.firstName} ${seniorBattle.helpers[0]?.lastName}`
                    : "No Helper"}
                </td>
              </tr>
              {seniorBattle.helpers[5] && (
                <tr className='bg-white px-2 text-center'>
                  <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Pom. zapisnik</td>
                  <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                    {seniorBattle?.helpers && seniorBattle.helpers[5]
                      ? `${seniorBattle.helpers[5]?.firstName} ${seniorBattle.helpers[5]?.lastName}`
                      : "No Helper"}
                  </td>
                </tr>
              )}
            <tr className='bg-white px-2 text-center'>
              <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>Semafor</td>
              <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                {seniorBattle.helpers[1]
                  ? `${seniorBattle.helpers[1].firstName} ${seniorBattle.helpers[1].lastName}`
                  : "No Helper"}
              </td>{" "}
            </tr>
            <tr className='bg-white px-2 text-center'>
              <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>24/14</td>
              <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                {seniorBattle.helpers[2]
                  ? `${seniorBattle.helpers[2].firstName} ${seniorBattle.helpers[2].lastName}`
                  : "No Helper"}
              </td>{" "}
            </tr>
            <tr className='bg-white px-2 text-center'>
              <td className='border-r-2  border-gray-900 px-2 py-3 font-bold text-sm'>Statistika</td>
              <td className=' border-gray-900 px-2 py-3 font-bold  '>
              {seniorBattle.helpers[3] && seniorBattle.helpers[3].firstName !== "/"
                      ? `${seniorBattle.helpers[3].firstName} ${seniorBattle.helpers[3].lastName}, `
                      : ""}
                    {seniorBattle.helpers[4] && seniorBattle.helpers[4].firstName !== "/"
                      ? `${seniorBattle.helpers[4].firstName} ${seniorBattle.helpers[4].lastName}`
                      : ""}
              </td>
            </tr>
          </tr>
        </thead>
      </table>
      <div className='flex ml-4 w-24 font-bold'>
        <label className={isPaid ? "text-green-500" : "text-red-500"}>{isPaid ? "Isplaćeno" : "Neplaćeno"}</label>
      </div>
    </div>
  );
};

export default PoHelpSeniorBattlesTable;
