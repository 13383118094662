import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

const StatisticRefereeTable = () => {
  const [referee, setReferee] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, setTotalPages] = useState(1);
  const [finishedBattleStats, setFinishedBattleStats] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const refereesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=9999`);
        setReferee(refereesResponse.data.referees);
        setTotalPages(refereesResponse.data.totalPages);
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (referee.length === 0) return;

    const fetchRefereeStats = async () => {
      try {
        const statsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/referee/categorystats`
        );
        setFinishedBattleStats(statsResponse.data);
      } catch (error) {
        toast.error("Error fetching referee statistics: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRefereeStats();
  }, [referee]);

  const downloadExcel = () => {
    try {
      const data = referee.map((referee) => {
        const Juniori = finishedBattleStats[referee._id]?.Juniori || 0;
        const Kadeti = finishedBattleStats[referee._id]?.Kadeti || 0;
        const KadetiB = finishedBattleStats[referee._id]?.KadetiB || 0;
        const Pioniri = finishedBattleStats[referee._id]?.Pioniri || 0;
        const PioniriB = finishedBattleStats[referee._id]?.PioniriB || 0;
        const Minibasket = finishedBattleStats[referee._id]?.Minibasket || 0;
        const Juniorke = finishedBattleStats[referee._id]?.Juniorke || 0;
        const Kadetkinje = finishedBattleStats[referee._id]?.Kadetkinje || 0;
        const Pionirke = finishedBattleStats[referee._id]?.Pionirke || 0;
        const MinibasketZ = finishedBattleStats[referee._id]?.MinibasketZ || 0;
        const Ostalo = finishedBattleStats[referee._id]?.Ostalo || 0;
        const Turnir = finishedBattleStats[referee._id]?.Turnir || 0;

        const Ukupno =
          Juniori +
          Juniorke +
          (Kadeti + KadetiB) +
          Kadetkinje +
          (Pioniri + PioniriB) +
          Pionirke +
          Minibasket +
          MinibasketZ +
          Ostalo +
          Turnir;

        console.log(`Ukupno for ${referee.firstName} ${referee.lastName}: ${Ukupno}`);

        return {
          "Ime i prezime": `${referee.firstName} ${referee.lastName}`,
          Juniori,
          Juniorke,
          Kadeti: Kadeti + KadetiB,
          Kadetkinje,
          Pioniri: Pioniri + PioniriB,
          Pionirke,
          Minibasket,
          MinibasketZ,
          Ostalo,
          Turnir,
          Ukupno,
        };
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Statistika - Sudije");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Statistika_Sudija.xlsx";
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-cente '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left border-2 border-gray-900 mb-5'>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
              <tr>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ime i prezime</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniori</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniorke</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadeti</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadetkinje</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pioniri</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pionirke</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Minibasket</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>MinibasketZ</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ostalo</th>
                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Turnir</th>

                <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO</th>
              </tr>
            </thead>
            <tbody className='p-2 text-sm'>
              {referee.map((referee) => {
                const Juniori = finishedBattleStats[referee._id]?.Juniori || 0;
                const Kadeti = finishedBattleStats[referee._id]?.Kadeti || 0;
                const KadetiB = finishedBattleStats[referee._id]?.KadetiB || 0;
                const Pioniri = finishedBattleStats[referee._id]?.Pioniri || 0;
                const PioniriB = finishedBattleStats[referee._id]?.PioniriB || 0;
                const Minibasket = finishedBattleStats[referee._id]?.Minibasket || 0;
                const Juniorke = finishedBattleStats[referee._id]?.Juniorke || 0;
                const Kadetkinje = finishedBattleStats[referee._id]?.Kadetkinje || 0;
                const Pionirke = finishedBattleStats[referee._id]?.Pionirke || 0;
                const MinibasketZ = finishedBattleStats[referee._id]?.MinibasketZ || 0;
                const Ostalo = finishedBattleStats[referee._id]?.Ostalo || 0;
                const Turnir = finishedBattleStats[referee._id]?.Turnir || 0;

                const Ukupno =
                  Juniori +
                  Juniorke +
                  Kadeti +
                  KadetiB +
                  Kadetkinje +
                  Pioniri +
                  PioniriB +
                  Pionirke +
                  Minibasket +
                  MinibasketZ +
                  Ostalo +
                  Turnir;

                const PioniriUkupno = Pioniri + PioniriB;
                const KadetiUkupno = Kadeti + KadetiB;

                return (
                  <tr key={referee._id} className='hover:bg-gray-400 transition cursor-pointer'>
                    <th className='py-1 text-gray-800 text-center border-r-2 border-b-2 border-gray-900 px-5'>
                      {referee.firstName} {referee.lastName}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Juniori}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Juniorke}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {KadetiUkupno}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Kadetkinje}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {PioniriUkupno}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Pionirke}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Minibasket}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {MinibasketZ}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Ostalo}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Turnir}
                    </th>
                    <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                      {Ukupno}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='flex justify-end items-center bg-green-900 rounded px-3 py-2 transform transition-transform hover:scale-105'>
            <img src='icons/excelLogo.svg' alt='EXCEL' className='w-7 h-7 mr-2' />
            <button onClick={downloadExcel} className='text-white font-semibold'>
              Download Excel
            </button>
          </div>
        </React.Fragment>
      )}
      <ToastContainer />
    </div>
  );
};

export default StatisticRefereeTable;
