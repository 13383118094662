const PasswordModal = ({ onPasswordSubmit, onCancel, password, setPassword }) => {
  return (
    <div className='fixed inset-0  flex z-20 items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white w-3/12 px-4 pb-5 rounded-lg shadow-lg text-center'>
        <div className='flex justify-center'>
          <img src='icons/warning.svg' alt='delete' className='w-28 ml-1  justify-center items-center' />
        </div>
        <div>
          <p className='text-3xl text-gray-800 pb-5'>Unesite lozinku</p>
        </div>
        <p className='text-lg text-gray-600'>
          <input
            type='password'
            placeholder='Lozinka'
            className='border-2 bg-gray-200 w-full text-gray-600 py-1 px-4 mb-4'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </p>
        <div className='flex justify-center mt-1'>
          <button
            className='border-2  bg-gray-400 text-white py-1 px-4  mr-2 transform transition-transform hover:scale-105'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className='border-2 text-white  bg-red-800 py-1 px-4  flex items-center transform transition-transform hover:scale-105'
            onClick={onPasswordSubmit}
          >
            Submit
            <img
              src='icons/deleteIcon.png'
              alt='delete'
              className='w-4 ml-1 cursor-pointer filter brightness-0 invert'
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
