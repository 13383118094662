const SemaforSelect = ({ helpers, battleDate, onSelectHelper, selectedHallRating }) => {
  const filteredHelpers = helpers
    .filter((helper) => {
      if (selectedHallRating === 1) {
        return helper.helperRating >= 2;
      } else if (selectedHallRating === 2) {
        return helper.helperRating === 3 || helper.helperRating === 4;
      } else if (selectedHallRating === 3) {
        return helper.helperRating === 4;
      } else {
        return true;
      }
    })
    .filter((helper) => {
      if (!helper.startDate || !helper.endDate) {
        return true;
      }

      const battleDateObj = new Date(battleDate);
      return battleDateObj < new Date(helper.startDate) || battleDateObj > new Date(helper.endDate);
    });

  return (
    <select
      className='text-gray-800 text-center font-semibold w-44'
      id='helperSelect2'
      onChange={(e) => onSelectHelper(e.target.value)}
    >
      <option value=''>Semafor</option>
      {filteredHelpers.map((helper, index) => (
        <option key={index} value={helper._id}>
          {helper.firstName} {helper.lastName}
        </option>
      ))}
    </select>
  );
};

export default SemaforSelect;
