import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import PoCommisionareBattlesTable from "../potable/PoCommisionareBattlesTable";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const CommisionareBattlesTable = () => {
  const [commisionareBattles, setCommisionareBattles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [pdfLabelText, setPdfLabelText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchCommisionareBattles = async () => {
      try {
        const page = currentPage;
        const limit = 10;

        const apiUrl = selectedCategory
          ? `${process.env.REACT_APP_API_BASE_URL}/battles?category=${selectedCategory}&page=${page}&limit=${limit}`
          : `${process.env.REACT_APP_API_BASE_URL}/battles?page=${page}&limit=${limit}`;

        const response = await axios.get(apiUrl);
        setCommisionareBattles(response.data.battles);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCommisionareBattles();
  }, [selectedCategory, currentPage]);

  const handleDownloadPDF = () => {
    const input = tableRef.current;

    const elementsToHide = input.querySelectorAll(".exclude-from-pdf");
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    const headerToHide = input.querySelector(".exclude-from-pdf");
    headerToHide.style.display = "none";

    const labelElement = document.createElement("div");
    labelElement.style.position = "absolute";
    labelElement.style.top = "420px";
    labelElement.style.left = "50px";
    labelElement.textContent = pdfLabelText;
    input.appendChild(labelElement);

    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      elementsToHide.forEach((element) => {
        element.style.display = "";
      });

      headerToHide.style.display = "table-cell";
      input.removeChild(labelElement);
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "SVG", 10, 10, pdfWidth, pdfHeight);
      pdf.save("NOMINACIJE.pdf");
    });
  };

  const filteredBattles =
    selectedCategory === ""
      ? commisionareBattles
      : commisionareBattles.filter((battle) => battle.category === selectedCategory);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className='flex flex-col items-center justify-center '>
      <React.Fragment>
        <div className='flex items-center justify-center text-center p-4 px-4 pt-4 pb-6'>
          <img className='w-28 ' src='/images/logouksk.svg' alt='logo' />
        </div>

        {isLoading ? (
          <div className='flex justify-center items-center h-80'>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {filteredBattles.map((battle) => (
              <PoCommisionareBattlesTable key={battle._id} battle={battle} />
            ))}
          </>
        )}
      </React.Fragment>
      {totalPages > 1 && (
        <div className=' mb-4 exclude-from-pdf'>
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='mr-2'>
            <img src='icons/backIcon.png' alt='next' className='w-4 ml-1 cursor-pointer filter invert' />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={
                currentPage === index + 1
                  ? "mr-2 bg-gradient-to-r from-indigo-800 to-indigo-700 px-3 py-1 rounded-xl border-2 border-gray-900 text-xl text-white"
                  : "mr-2 font-bold px-3 py-1 rounded-xl text-xl border-2 border-gray-900"
              }
            >
              {index + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            <img
              src='icons/backIcon.png'
              alt='next'
              className='w-4 ml-1 cursor-pointer filter invert transform rotate-180'
            />
          </button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default CommisionareBattlesTable;
