function Switcher({ isPaid, setIsPaid }) {
  const handleToggle = () => {
    setIsPaid(!isPaid);
  };

  return (
    <div className='flex flex-row justify-center items-center pl-4'>
      <div
        className={`md:w-14 md:h-6 w-12 h-6 flex items-center pl-1 rounded-full cursor-pointer ${
          isPaid
            ? "bg-gradient-to-r from-green-600 to-green-900 border border-gray-900"
            : "bg-gradient-to-r from-red-900 to-red-600 border border-gray-900"
        }`}
        onClick={handleToggle}
      >
        <div
          className={`w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
            isPaid ? "translate-x-6 bg-white border border-gray-900" : "bg-white border border-gray-900"
          }`}
        ></div>
      </div>
      <div className={`ml-2 w-24 ${isPaid ? "text-green-600 font-bold" : "text-red-600 font-bold"}`}>
        {isPaid ? "Isplaćeno" : "Neisplaćeno"}
      </div>
    </div>
  );
}

export default Switcher;
