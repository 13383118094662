import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../other/LoadingSpinner";
import PoHelpActiveSeniorBattlesTable from "../potable/PoHelpActiveSeniorBattlesTable";
const HelpActiveSeniorBattlesTable = () => {

  const [activeSeniorBattles, setActiveSeniorBattles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUserId = localStorage.getItem("loggedInUserId");
  const loggedInUsername = localStorage.getItem("loggedInUsername");
  const loggedInUserTitle = localStorage.getItem("loggedInUserTitle");

  const fetchHelpersSeniorBattlesWithFilters = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/seniorBattle/helper/seniorbattles/${loggedInUserId}`
      );
      setActiveSeniorBattles(response.data.SeniorBattles);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching helpers battles:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHelpersSeniorBattlesWithFilters();
  }, [loggedInUserId]);




  return (
    <div className='flex flex-col items-center justify-center '>
      <div>
        <React.Fragment>
          <div className='flex  justify-center items-center'>
          </div>
          {isLoading ? (
            <div className='flex justify-center items-center h-80'>
              <LoadingSpinner />
            </div>
          ) : (
            activeSeniorBattles
              .map((seniorBattle) => (
                <PoHelpActiveSeniorBattlesTable
                  key={seniorBattle._id}
                  seniorBattle={seniorBattle}
                  loggedInUsername={loggedInUsername}
                  loggedInUserTitle={loggedInUserTitle}
                />
              ))
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

export default HelpActiveSeniorBattlesTable;
