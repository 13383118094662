import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerHelpers from "../sidedrawer/EditSideDrawerHelpers";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const ResponsiveHelpersTable = (onClose) => {
  const [helperi, setHelperi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHelper, setSelectedHelper] = useState(null);
  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedHelpersPerPage, setSelectedHelpersPerPage] = useState(12);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const limit = 12;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=${currentPage}&limit=${selectedHelpersPerPage}`)
      .then((response) => {
        setHelperi(response.data.helpers);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching helpers:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedHelpersPerPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  useEffect(() => {
  }, [helperi]);

  const handleDelete = (helper) => {
    setShowDeleteModal(true);
    setSelectedHelper(helper);
  };

  const hasAbsenceDates = (helper) => {
    return helper.startDate && helper.endDate;
  };

  const handleDeleteConfirmation = (helperId) => {
    toast.success("Uspješno ste obrisali pomočnog sudiju");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/helpers/${helperId}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali pomoćnog sudiju");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedHelper(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedHelper(null);
  };

  const handleEditButtonClick = (selectedHelperData) => {
    setIsEditSideDrawerOpen(true);

    const formattedStartDate = selectedHelperData.startDate ? new Date(selectedHelperData.startDate) : null;
    const formattedEndDate = selectedHelperData.endDate ? new Date(selectedHelperData.endDate) : null;

    setSelectedHelper({
      ...selectedHelperData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleSort = () => {
    setIsSortingAsc(!isSortingAsc);

    const sortDirection = isSortingAsc ? "asc" : "desc";
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=${currentPage}&limit=${limit}&sort=${sortDirection}`)
      .then((response) => {
        setHelperi(response.data.helpers);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching helpers:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleHelpersPerPageChange = (e) => {
    const newHelpersPerPage = parseInt(e.target.value, 10);
    setSelectedHelpersPerPage(newHelpersPerPage);
    setCurrentPage(1);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=${newHelpersPerPage}`)
      .then((response) => {
        setHelperi(response.data.helpers);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching helpers:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10 '>
              <tr>
                <th className='py-3 px-5 text-sm text-center text-gray-200'>Ime i prezime</th>
                <th className='py-3 px-5 text-sm text-gray-200 text-center'>Datum početka odsustva</th>
                <th className='py-3 px-5 text-sm text-gray-200 text-center'>Datum završetka odsustva</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {helperi.map((helper, index) => (
                <React.Fragment key={helper._id}>
                  <tr
                    key={helper._id}
                    className={`hover:bg-gray-200  border border-gray-200 cursor-pointer ${
                      expandedRow === index ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setExpandedRow(index === expandedRow ? null : index)}
                  >
                    <td className='py-2 px-5 font-semibold'>
                      {helper.firstName} {helper.lastName}
                    </td>
                    {hasAbsenceDates(helper) ? (
                      <>
                        <td className='py-2 px-5 text-center'>{formatDate(helper.startDate)}</td>
                        <td className='py-2 px-5 text-center'>{formatDate(helper.endDate)}</td>
                      </>
                    ) : (
                      <>
                        <td className='py-2 px-5 text-center'>
                          <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                            ✔️
                          </span>
                        </td>
                        <td className='py-2 px-5 text-center'>
                          <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                            ✔️
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan='3' className='px-10 py-2'>
                        <div>
                          <div className='flex justify-between font-medium '>
                            <p>Rating:</p>
                            <p className=' justify-end'>{helper.helperRating}</p>
                          </div>
                          <div className='flex justify-between  font-medium '>
                            <p>Članarina:</p>
                            <p className=' justify-end'>{helper.price} KM / 60 KM</p>
                          </div>
                          <div className='flex justify-between  font-medium '>
                            <p>Statistika:</p>
                            <p className=' justify-end uppercase	'>{helper.statistic} </p>
                          </div>
                        </div>
                        <div className='text-center justify-center items-center flex flex-col py-2'>
                          <button
                            onClick={() => handleEditButtonClick(helper)}
                            className='border-2 w-full text-center text-white bg-gradient-to-r from-green-900 to-green-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg mb-2'
                          >
                            Uredi
                          </button>
                          <button
                            // onClick={() => handleDelete(helper)}
                            className='cursor-not-allowed border-2 w-full text-white bg-gradient-to-r from-red-900 to-red-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg'
                          >
                            Obriši
                          </button>
                        </div>
                        {isEditSideDrawerOpen && (
                          <EditSideDrawerHelpers selectedHelper={selectedHelper} onClose={handleEditSideDrawerClose} />
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              <tr className='border-t-2 '></tr>
            </tbody>
          </table>

          <div className='flex justify-between w-full'>
            <div className='my-5 px-5'>
              <div className='mr-2 mb-1'>Redovi:</div>
              <select
                className='px-2 py-1 border rounded-md'
                value={selectedHelpersPerPage}
                onChange={handleHelpersPerPageChange}
              >
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={36}>36</option>
              </select>
            </div>
            <div className='my-5 px-5 flex justify-center items-center'>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${selectedHelper?.firstName} ${selectedHelper?.lastName}`}
          onDelete={() => handleDeleteConfirmation(selectedHelper._id)}
          onCancel={handleDeleteCancel}
          type='pomoćnog sudiju'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ResponsiveHelpersTable;
