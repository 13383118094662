import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const EditSideDrawerCommisionares = ({ selectedCommisionare, onClose }) => {
  const [, setSudije] = useState([]);
  const [ime, setIme] = useState(selectedCommisionare.firstName);
  const [prezime, setPrezime] = useState(selectedCommisionare.lastName);
  const [rating, setRating] = useState(String(selectedCommisionare.commisionareRating));
  const [price, setPrice] = useState(String(selectedCommisionare.price));

  useEffect(() => {
    fetchCommisionares();
  }, []);

  const fetchCommisionares = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commisionare`)
      .then((response) => {
        setSudije(response.data);
      })
      .catch((error) => {
        console.error("Error fetching commisionares:", error);
      });
  };

  const handleUpdateCommisionare = () => {
    if (!ime || !prezime) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const updateCommisionare = {
      firstName: ime,
      lastName: prezime,
      commisionareRating: Number(rating),
      price: Number(price),
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/commisionare/${selectedCommisionare._id}`, updateCommisionare)
      .then((response) => {
        toast.success("Uspješno ste updateovali podatke");
        fetchCommisionares();
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      });
  };

  useEffect(() => {
    setIme(selectedCommisionare.firstName);
    setPrezime(selectedCommisionare.lastName);
    setRating(String(selectedCommisionare.commisionareRating));
  }, [selectedCommisionare]);

  return (
    <>
      <div className='fixed z-20 w-full right-0 top-0 h-screen md:w-2/5 lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg'>
        <div className='p-5'>
          <div className='flex flex-row justify-left items-center '>
            <img
              src='icons/leftArrow.png'
              alt='delete'
              className='w-6 h-6 cursor-pointer '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            ></img>
            <h1
              className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110  '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            >
              Back
            </h1>
          </div>

          <div className='my-4 mx-5'>
            <label className='block mb-2 text-xl text-left font-bold text-gray-200' htmlFor='firstName'>
              Ime:
            </label>
            <input
              id='firstName'
              placeholder='Unesite ime sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
          </div>
          <div className='my-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='lastName'>
              Prezime:
            </label>
            <input
              id='lastName'
              placeholder='Unesite prezime sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={prezime}
              onChange={(e) => setPrezime(e.target.value)}
            />
          </div>
          <div className='my-2 mx-5 text-left '>
            <label className='block mb-2 text-xl font-bold text-gray-200' htmlFor='rating'>
              Rating:
            </label>
            <select
              id='rating'
              className='border rounded px-2 py-1 w-full'
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              {[...Array(2)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='mx-5 mb-1 text-red-600 font-semibold text-sm text-left'>
            <p>1 - A1, A2 Liga, 2 - Prvenstvo BIH</p>
          </div>
          <div className='mt-2 mb-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='price'>
              Članarina:
            </label>
            <input
              id='price'
              placeholder='Unesite članarinu'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className='flex items-center justify-end mx-'>
            <button
              className='border-2 text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-xl'
              onClick={() => {
                handleUpdateCommisionare();
              }}
            >
              Uredi
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditSideDrawerCommisionares;
