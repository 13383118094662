import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";
import axios from "axios";

import HamburgerMenu from "./HamburgerMenu";
import SidebarMenu from "../sidebar/SidebarMenu";

const MainLayout = ({ activeMenuItem, children }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [loggedInUserTitle, setLoggedInUserTitle] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");

  const [refereeId, setRefereeId] = useState("");

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInUsername(decodedToken.firstName + " " + decodedToken.lastName);
      setLoggedInUserTitle(localStorage.getItem("loggedInUserTitle"));
      setLoggedInUserId(localStorage.getItem("loggedInUserId"));
    }
  }, [token]);

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      let route;
      let apiUrl;

      if (loggedInUserTitle === "Pomoćni sudija") {
        route = "/helpers";
        apiUrl = `${process.env.REACT_APP_API_BASE_URL}/helpers/${loggedInUserId}`;
      } else {
        route = "/referees";
        apiUrl = `${process.env.REACT_APP_API_BASE_URL}/referee/${loggedInUserId}`;
      }

      axios
        .put(apiUrl, { password: newPassword })
        .then((response) => {
          console.log("Password updated successfully");
          setIsPasswordModalOpen(false);
          localStorage.removeItem("token");
          localStorage.removeItem("loggedInUserTitle");
          navigate("/");
        })
        .catch((error) => {
          console.error("Error updating password:", error);
        });
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsPasswordModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loggedInUserTitle");
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("tables");

    navigate("/");
  };

  const openPasswordModal = (id) => {
    setRefereeId(id);
    setIsPasswordModalOpen(true);
  };
  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className='flex min-h-screen'>
      <div className=' hidden items-center w-1/5 h-max justify-center bg-gradient-to-r from-indigo-800 to-indigo-700 gap-[10px] border-r  md:block'>
        <div className='flex flex-row justify-between items-center pt-3 pb-3 px-5 border-b-2 border-gray-800'>
          <div className='flex items-center justify-start w-60 '>
            <img className='w-28' src='/images/logouksk.svg' alt='logo' />
          </div>
          <div className='flex items-center flex-col justify-end w-60'>
            <div className='text-center'>
              <p className='text-xl font-bold text-white'>{loggedInUsername}</p>
              <p className='text-sm text-white pb-2'>{loggedInUserTitle}</p>
              <button
                onClick={handleDropdownToggle}
                className=' items-center font-semibold border-blue-900 border-2 text-sm px-5 py-1 w-28 text-white rounded-md bg-gradient-to-r from-gray-700 to-gray-800 transform transition-transform hover:scale-105'
              >
                <span className='flex justify-center items-center'>
                  <img src='icons/settingsIcon.svg' alt='settings' className='w-6 pr-1 cursor-pointer' />
                  Settings
                </span>
              </button>
              {isDropdownOpen && (
                <div className='absolute z-10 mt-2 w-40 bg-white border border-gray-300  shadow-lg'>
                  <ul>
                    <li className='hover:bg-gray-300 cursor-pointer '>
                      <button
                        onClick={() => openPasswordModal(refereeId)}
                        className='block cursor-pointer px-4 py-2 text-gray-800 font-medium w-40 '
                      >
                        Change Password
                      </button>
                    </li>
                    <li className='hover:bg-gray-300 cursor-pointer '>
                      <button
                        onClick={handleLogout}
                        className='block  px-4 py-2 font-medium text-gray-800 w-40 text-left'
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {isPasswordModalOpen && (
              <div className='fixed inset-0 flex items-center justify-center z-10'>
                <div className='fixed inset-0 bg-black opacity-50' />

                <div className='bg-white w-3/12 p-6 rounded-md shadow-2xl relative z-10 '>
                  <h2 className='text-lg font-semibold mb-4'>Change Password</h2>
                  {errorMessage && <p className='text-red-500 mb-1'>{errorMessage}</p>}
                  <input
                    type='password'
                    placeholder='Enter new password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className='block w-full p-3 border rounded mb-4'
                  />
                  <input
                    type='password'
                    placeholder='Confirm new password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className='block w-full p-3 border rounded mb-4'
                  />

                  <div className='flex justify-end'>
                    <button
                      onClick={closePasswordModal}
                      className='bg-gray-300 text-gray-700 px-4 py-2 rounded transform transition-transform hover:scale-105'
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleChangePassword}
                      className='bg-blue-600 text-white ml-2 px-4 py-2 rounded transform transition-transform hover:scale-105'
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='pb-6  '>
          <SidebarMenu
            activeMenuItem={activeMenuItem}
            isAdmin={loggedInUserTitle === "Admin"}
            isReferee={loggedInUserTitle === "Sudija"}
            isHelper={loggedInUserTitle === "Pomoćni sudija"}
            isKomesar={loggedInUserTitle === "Komesar"}
          />
        </div>
      </div>
      <HamburgerMenu activeMenuItem={activeMenuItem} />

      <div className='flex-1'>{children}</div>
    </div>
  );
};

export default MainLayout;
