import React from "react";

import MainLayout from "../maincontent/MainLayout";
import HelpSeniorBattlesTable from "../tables/HelpSeniorBattlesTable";

const HelpSeniorBattlesPage = () => {
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>ZAVRŠENI STOLOVI</div>
        <div className='flex w-full justify-center items-center'>
          <div className='w-4/6 p-8 rounded-xl '>
            <HelpSeniorBattlesTable />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default HelpSeniorBattlesPage;
