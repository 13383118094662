import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";
import axios from "axios";

import SidebarMenu from "../sidebar/SidebarMenu";

const HamburgerMenu = (activeMenuItem) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [loggedInUserTitle, setLoggedInUserTitle] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");

  const [refereeId, setRefereeId] = useState("");

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInUsername(decodedToken.firstName + " " + decodedToken.lastName);
      setLoggedInUserTitle(localStorage.getItem("loggedInUserTitle"));
      setLoggedInUserId(localStorage.getItem("loggedInUserId"));
    }
  }, [token]);

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      let route;

      if (loggedInUserTitle === "Pomoćni sudija") {
        route = "/helpers";
      } else {
        route = "/referee";
      }

      axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/referee/${loggedInUserId}`, { password: newPassword })
        .then((response) => {
          console.log("Password uspješno promjenjen");
          setIsPasswordModalOpen(false);
          localStorage.removeItem("token");
          localStorage.removeItem("loggedInUserTitle");
          navigate("/");
        })
        .catch((error) => {
          console.error("Error updating password:", error);
        });
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsPasswordModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loggedInUserTitle");
    navigate("/");
  };

  const openPasswordModal = (id) => {
    setRefereeId(id);
    setIsPasswordModalOpen(true);
  };
  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className='fixed left-0 top-0 z-50 w-full bg-indigo-800 md:hidden'>
      <div className='flex items-center justify-between px-4 py-2'>
        <div>
          <motion.button
            className='text-3xl'
            onClick={() => {
              setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
              setMenuOpen(!isMenuOpen);
            }}
            animate={{ rotate: isMenuOpen ? 90 : 0 }}
          >
            {isMenuOpen ? (
              <img className='h-8 w-8' src='icons/closeIcon.svg' alt='Close icon' />
            ) : (
              <img src='icons/hamburgerIcon.svg' alt='|||' />
            )}
          </motion.button>
        </div>
        <div>
          <img
            alt='logo'
            src='images/uksk.svg'
            className='absolute left-0 right-0 top-0 ml-auto mr-auto w-24 mt-2 py-[20px]'
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
      {isHamburgerMenuOpen && (
        <div className='h-full fixed z-30 left-0 w-full overflow-y-auto border-b border-gray-900 bg-gradient-to-r from-indigo-800 to-indigo-700 px-4 py-2'>
          <div className=' justify-center items-center text-center mt-4 w-full'>
            <div className='flex justify-between border-b border-gray-900'>
              <img alt='logo' src='images/logouksk.svg' className=' w-24 pb-2' />

              <div className='mt-2'>
                <p className='text-xl font-bold text-white'>{loggedInUsername}</p>
                <p className='text-sm text-white pb-2'>{loggedInUserTitle}</p>

                <button
                  onClick={handleDropdownToggle}
                  className=' items-center font-semibold border-blue-900 border-2 text-sm px-5 py-1 w-28 text-white rounded-md bg-gradient-to-r from-gray-700 to-gray-800 transform transition-transform hover:scale-105'
                >
                  <span className='flex justify-center items-center'>
                    <img src='icons/settingsIcon.svg' alt='settings' className='w-6 pr-1 cursor-pointer' />
                    Settings
                  </span>
                </button>
              </div>
            </div>

            {isDropdownOpen && (
              <div className='absolute right-2 z-10 mr-5  w-40 bg-white border border-gray-300  shadow-lg'>
                <ul>
                  <li className='hover:bg-gray-300 cursor-pointer '>
                    <button
                      onClick={() => openPasswordModal(refereeId)}
                      className='block cursor-pointer px-4 py-2 text-gray-800 font-medium w-40 '
                    >
                      Change Password
                    </button>
                  </li>
                  <li className='hover:bg-gray-300 cursor-pointer '>
                    <button
                      onClick={handleLogout}
                      className='block  px-4 py-2 font-medium text-gray-800 w-40 text-left'
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {isPasswordModalOpen && (
            <div className='fixed inset-0 flex items-center justify-center z-10'>
              <div className='fixed inset-0 bg-black opacity-50' />

              <div className='bg-white w-9/12 p-6 rounded-md shadow-2xl relative z-10 '>
                <h2 className='text-lg font-semibold mb-4'>Change Password</h2>
                {errorMessage && <p className='text-red-500 mb-1'>{errorMessage}</p>}
                <input
                  type='password'
                  placeholder='Enter new password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className='block w-full p-3 border rounded mb-4'
                />
                <input
                  type='password'
                  placeholder='Confirm new password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='block w-full p-3 border rounded mb-4'
                />

                <div className='flex justify-end'>
                  <button
                    onClick={closePasswordModal}
                    className='bg-gray-300 text-gray-700 px-4 py-2 rounded transform transition-transform hover:scale-105'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleChangePassword}
                    className='bg-blue-600 text-white ml-2 px-4 py-2 rounded transform transition-transform hover:scale-105'
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className='py-5'>
            <SidebarMenu
              activeMenuItem={activeMenuItem}
              isAdmin={loggedInUserTitle === "Admin"}
              isReferee={loggedInUserTitle === "Sudija"}
              isHelper={loggedInUserTitle === "Pomoćni sudija"}
              isKomesar={loggedInUserTitle === "Komesar"}
              isHamburgerMenu={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
