const Error404Page = () => {
  return (
    <div className='bg-gray-50 h-screen  justify-center items-center'>
      <div className='flex h-4/5 flex-col justify-center text-center items-center'>
        <img className='w-28 mb-7' src='/images/logouksk.svg' alt='logo' />

        <content className='justify-center text-gray-400 text-center font-light flex text-xl'>404 | NOT FOUND</content>
      </div>
    </div>
  );
};

export default Error404Page;
