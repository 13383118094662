import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../other/LoadingSpinner";
import PoRefActiveBattlesTable from "../potable/PoRefActiveBattlesTable";

const RefActiveBattlesTable = () => {
  const [activeBattles, setActiveBattles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUserId = localStorage.getItem("loggedInUserId");
  const loggedInUsername = localStorage.getItem("loggedInUsername");
  const loggedInUserTitle = localStorage.getItem("loggedInUserTitle");

  const fetchRefereesBattlesWithFilters = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/battles/referee/battles/${loggedInUserId}`
      );
      setActiveBattles(response.data.Battles);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching referees battles:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRefereesBattlesWithFilters();
  }, [loggedInUserId]);

  return (
    <div className="flex flex-col items-center justify-center ">
      <div>
        <React.Fragment>
          {isLoading ? (
            <div className="flex justify-center items-center h-80">
              <LoadingSpinner />
            </div>
          ) : (
            activeBattles.map((battle) => (
              <PoRefActiveBattlesTable
                key={battle._id}
                battle={battle}
                loggedInUsername={loggedInUsername}
                loggedInUserTitle={loggedInUserTitle}
              />
            ))
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

export default RefActiveBattlesTable;
