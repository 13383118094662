import React, { useState } from "react";

const PoRefBattlesTable = ({ battle }) => {
  const [isPaid] = useState(battle.isPaid);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  return (
    <div className='flex flex-row items-center '>
      {" "}
      <table key={battle._id} className='w-full text-left border-2 border-gray-900 mb-5'>
        <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
          <tr>
            <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-32'>Kategorija</th>
            <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-12'>Br.</th>
            <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-52'>{battle.teamA?.name}</th>
            <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-52'>{battle.teamB?.name}</th>
            <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-32'>
              <div class='flex flex-col items-center '>
                <span class='text-gray-200 text-center'>Mjesto,</span>
                <span class='text-gray-200 text-center'>Dvorana</span>
              </div>
            </th>
            <th className='py-1 text-gray-200 text-center'>
              <div class='flex flex-col items-center'>
                <span class='text-gray-200 text-center'>Datum,</span>
                <span class='text-gray-200 text-center'>Vrijeme</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='p-2 text-sm'>
          <tr>
            <th className='py-1 text-gray-800 text-center border-r-2 border-gray-900 px-5'>{battle.category}</th>

            <td className='py-2 px-1 text-center border-r-2 border-gray-900'>{battle.matchNumber}</td>
            <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
              <div className='flex flex-col items-center'>
                <span className='text-black text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                {battle.helpers.map((helper) => (
                  <span key={helper._id} className='font-semibold'>
                    {helper.firstName} {helper.lastName}
                  </span>
                ))}
              </div>
            </th>
            <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
              <div className='flex flex-col items-center'>
                <span className='text-black text-center font-bold pb-1'>SUDIJE:</span>
                {battle.referees.map((referee) => (
                  <span key={referee._id} className='font-semibold'>
                    {referee.firstName} {referee.lastName}
                  </span>
                ))}
              </div>
            </th>
            <td className='py-2 px-5 text-center border-r-2 border-gray-900 font-bold'>
              {battle && battle.hall ? battle.hall.name : ""}
            </td>

            <td className='py-2 px-5 text-center font-bold '>{formatDate(battle.date)}</td>
          </tr>
        </tbody>
      </table>
      <div className='flex ml-4 w-24 font-bold'>
        <label className={isPaid ? "text-green-500" : "text-red-500"}>{isPaid ? "Isplaćeno" : "Neplaćeno"}</label>
      </div>
    </div>
  );
};

export default PoRefBattlesTable;
