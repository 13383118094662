import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import PoDraftBattleTable from "../potable/PoDraftBattleTable";
import PoDraftSeniorBattleTable from "../potable/PoDraftSeniorBattleTable";
import LoadingSpinner from "../other/LoadingSpinner";
import PaginationButtons from "../other/PaginationButtons";
import NavBar from "../navbars/NavBar";
import "react-toastify/dist/ReactToastify.css";

const DraftBattleTable = () => {
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [draftBattle, setDraftBattle] = useState([]);
  const [draftSeniorBattle, setDraftSeniorBattle] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const navLabels = [`Omladinska liga`, "Stolovi - Seniori"];

  // useEffect to store currentPage in sessionStorage
  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  // useEffect to set currentPage from sessionStorage on initial render
  useEffect(() => {
    const storedPage = sessionStorage.getItem("currentPage");
    if (storedPage) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams({ page: currentPage, limit: 10 });
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/draftBattle?${params.toString()}`);
        setDraftBattle(response.data);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/draftSeniorBattle`)
      .then((response) => {
        setDraftSeniorBattle(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      });
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='flex flex-col items-center justify-center '>
      <div ref={tableRef}>
        <div className='flex  justify-center items-center'></div>

        <React.Fragment>
        <div className='mb-2 flex flex-wrap justify-center gap-4 '>
            <NavBar navLabels={navLabels} handlePageSelect={(page) => setActivePage(page)} />
          </div>
          {isLoading ? (
            <div className='flex justify-center items-center h-80'>
              <LoadingSpinner />
            </div>
            
          ) : (
            <>
          {draftBattle.map((draftBattle) => (
  activePage === 1 && <PoDraftBattleTable key={draftBattle._id} draftBattle={draftBattle} />
))}
{draftSeniorBattle.map((draftSeniorBattle) => (
  activePage === 2 && <PoDraftSeniorBattleTable key={draftSeniorBattle._id} draftSeniorBattle={draftSeniorBattle} />
))}

            </>
          )}
        </React.Fragment>
        <PaginationButtons currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} showPageNumbers={true} />
      </div>

      <ToastContainer />
    </div>
  );
};

export default DraftBattleTable;
