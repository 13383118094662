import React from "react";

import MainLayout from "../maincontent/MainLayout";
import HelpActiveBattlesTable from "../tables/HelpActiveBattlesTable";
import HelpActiveSeniorBattlesTable from "../tables/HelpActiveSeniorBattlesTable";

const HelpActiveBattlesPage = () => {
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>NAREDNE UTAKMICE</div>
        <div className='flex w-full justify-center items-center'>
          <div className='w-full px-16 justify-center rounded-xl '>
            <HelpActiveBattlesTable />
            
            <HelpActiveSeniorBattlesTable />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default HelpActiveBattlesPage;
