import React, { useState } from "react";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HoverTooltip from "../other/HoverTooltip";

const PoBattlesTable = ({ battle, onDelete }) => {
  const [, setActiveBattles] = useState([]);
  const [isTableVisible, setTableVisible] = useState(true);
  const [isRed, setIsRed] = useState(false); // New state to track whether the text should be red
  const [isRedHelpers, setIsRedHelpers] = useState(false);
  const [isRedReferees, setIsRedReferees] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  const handleDelete = (battleId) => {
    setTableVisible(false);
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battleId}`)
      .then(() => {
        setActiveBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((battle) => battle._id !== battleId);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno obrisana");
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleDone = (battle) => {
    const finishedBattleData = {
      category: battle.category,
      matchNumber: battle.matchNumber,
      teamA: battle.teamA._id,
      teamB: battle.teamB._id,
      hall: battle.hall._id,
      date: battle.date,
      referees: battle.referees.map((referee) => referee._id),
      helpers: battle.helpers.map((helper) => helper._id),
      ostaloPrice: battle.ostaloPrice,
      ostaloHelperPrice: battle.ostaloHelperPrice,
      turnirPrice: battle.turnirPrice,
      turnirHelperPrice: battle.turnirHelperPrice,
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle`, finishedBattleData)
      .then((response) => {
        console.log("Battle data sent to the backend:", response.data);
        setActiveBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((b) => b._id !== battle._id);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno spašena!");
        setTableVisible(false);
      })
      .catch((error) => {
        console.error("Error sending battle data to the backend:", error);
        toast.error("Error sending battle data!");
      });

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battle._id}`)
      .then(() => {
        console.log("Battle deleted from the backend");
      })
      .catch((error) => {
        console.error("Error deleting battle from the backend:", error);
        toast.error("Error deleting battle!");
      });
  };

  const handleDraft = () => {
    const hallId = document.getElementById("hallSelect")?.value || "";

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/draftBattle`, {
        category: battle.category,
        matchNumber: battle.matchNumber,
        helpers: battle.helpers.map((helper) => helper._id),
        teamA: battle.teamA,
        teamB: battle.teamB,
        hall: battle.hall,
        date: battle.date,
      })
      .then((response) => {
        toast.success("Uspješno ste prebacili utakmicu u predstojeće utakmice");
        setTableVisible(false);
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battle._id}`)
      .then(() => {
        console.log("Battle deleted from the backend");
      })
      .catch((error) => {
        console.error("Error deleting battle from the backend:", error);
        toast.error("Error deleting battle!");
      });
  };

  const handleColorChange = () => {
    setIsRed(!isRed);
  };

  const handleColorChangeHelpers = () => {
    setIsRedHelpers(!isRedHelpers);
  };

  const handleColorChangeReferees = () => {
    setIsRedReferees(!isRedReferees);
  };

  return (
    <div>
      {isTableVisible && (
        <table key={battle._id} className='w-full text-left  mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900 '>
            <tr>
              <th className='pb-1 text-gray-200 text-center border-r-2 border-gray-900 hidden'>Kategorija</th>
              <th className='pb-4 text-gray-200 text-center  border-gray-900 w-12 h-14'>{battle.matchNumber}</th>
              <th className='pb-4 border-l-2 text-gray-200 text-center border-r-2 border-gray-900 w-52'>
                {battle.teamA?.name}
              </th>
              <th className='pb-4 text-gray-200 text-center border-r-2 border-gray-900 w-52'>{battle.teamB?.name}</th>
              <th className='pb-4 text-gray-200 text-center border-r-2 border-gray-900 w-36'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-200 text-center'>Mjesto,</span>
                  <span class='text-gray-200 text-center'>Dvorana</span>
                </div>
              </th>
              <th className='pb-4 text-gray-200 text-center border-r-2 border-gray-900 w-28'>
                <div class='flex flex-col items-center'>
                  <span class='text-gray-200 text-center'>Datum,</span>
                  <span class='text-gray-200 text-center'>Vrijeme</span>
                </div>
              </th>
              <th className='pb-1 text-gray-200 text-center border-r-2 border-gray-900 px-6 exclude-from-pdf'>
                Akcije
              </th>
            </tr>
          </thead>
          <tbody className='p-2 text-sm'>
            <tr>
              <th className='py-b text-gray-800 text-center border-r-2 border-gray-900 px-5 hidden'>
                {battle.category}
              </th>

              <td className='pb-2 px-5 text-center '></td>
              <th className='pb-2 px-5 text-black text-center border-r-2 border-l-2 border-b-2 border-gray-900'>
                <div className='flex flex-col items-center pb-4 '>
                  <span className='text-black text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                  {battle.helpers.slice(0, 3).map((helper) => (
                    <span
                      onClick={handleColorChangeHelpers}
                      key={helper._id}
                      className={`font-semibold cursor-pointer ${isRedHelpers ? "text-red-500" : ""}`}
                    >
                      {" "}
                      {helper.firstName} {helper.lastName}
                    </span>
                  ))}{" "}
                  {battle.category === "Turnir" && (
                    <>
                      <span className='text-black text-center font-bold pt-1'>STATISTIKA:</span>
                      {battle.helpers.slice(3).map((helper) => (
                        <span key={helper._id} className='font-semibold'>
                          {" "}
                          {helper.firstName} {helper.lastName}
                        </span>
                      ))}
                    </>
                  )}
                </div>
              </th>

              <th className='pb-2 px-5 text-black text-center border-r-2 border-gray-900 border-b-2'>
                <div className='flex flex-col items-center pb-4 '>
                  <span className='text-black text-center font-bold pb-1'>SUDIJE:</span>
                  {battle.referees.map((referee) => (
                    <span
                      onClick={handleColorChangeReferees}
                      key={referee._id}
                      className={`font-semibold cursor-pointer ${isRedReferees ? "text-red-500 font-semibold" : ""}`}
                    >
                      {" "}
                      {referee.firstName} {referee.lastName}
                    </span>
                  ))}
                </div>
              </th>
              <td className='pb-6 px-5 cursor-pointer text-center border-r-2 border-gray-900 font-bold border-b-2'>
                <div onClick={handleColorChange} className={`font-semibold ${isRed ? "text-red-500" : ""}`}>
                  {battle.hall ? battle.hall.name : ""}
                </div>
              </td>

              <td className='pb-5 cursor-pointer px-5 text-center font-bold border-r-2 border-gray-900 border-b-2'>
                <div onClick={handleColorChange} className={`font-semibold ${isRed ? "text-red-500" : ""}`}>
                  {formatDate(battle.date)}
                </div>
              </td>
              {battle.saved ? (
                <td className='  py-2 text-center items-center exclude-from-pdf'>
                  <div className='flex items-center justify-center'>
                    <span className='text-green-500'>Saved</span>
                  </div>
                </td>
              ) : (
                <td className='py-2 border-b-2 border-r-2 border-gray-900 text-center items-center exclude-from-pdf'>
                  <div className='flex items-center justify-center'>
                    <HoverTooltip text='Draft' translate='-translate-y-7 -translate-x-4'>
                      <img
                        src='icons/curvedIcon.svg'
                        alt='draft'
                        className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                        onClick={handleDraft}
                      />
                    </HoverTooltip>
                    <HoverTooltip text='Save' translate='-translate-y-7 -translate-x-4'>
                      <img
                        src='icons/saveIcon.svg'
                        alt='save'
                        onClick={() => handleDone(battle)}
                        className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                      />
                    </HoverTooltip>

                    <img
                      src='icons/deleteIcon.png'
                      alt='delete'
                      onClick={() => handleDelete(battle._id)}
                      className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                    />
                  </div>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default PoBattlesTable;
