import { useState, useEffect } from "react";

import MainLayout from "../maincontent/MainLayout";
import HallsTable from "../tables/HallsTable";
import ResponsiveHallsTable from "../tables/ResponsiveHallsTable";
import SideDrawerHalls from "../sidedrawer/SideDrawerHall";
import CreateButton from "../other/CreateButton";
import EditSideDrawerHalls from "../sidedrawer/EditSideDrawerHall";

const HallsPage = () => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateButtonClick = () => {
    setIsSideDrawerOpen(true);
  };

  const handleSideDrawerClose = () => {
    setIsSideDrawerOpen(false);
  };

  const handleEditButtonClick = (selectedHallData) => {
    setIsEditSideDrawerOpen(true);
    setSelectedHall(selectedHallData);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  return (
    <MainLayout>
      <div className={`flex ${isMobile ? "flex-col " : ""} pt-20 px-9 justify-between text-center items-center`}>
        <div className={` ${isMobile ? "pb-3 text-4xl " : ""}text-center text-3xl font-bold `}>LISTA DVORANA</div>

        <div className='justify-end'>
          <CreateButton buttonName='Kreiraj dvoranu' onClick={handleCreateButtonClick} />
        </div>
      </div>
      {isSideDrawerOpen && <SideDrawerHalls onClose={handleSideDrawerClose} />}
      <div className='flex w-full justify-center items-center'>
        <div className='w-full p-8 rounded-xl '>
          {isMobile ? (
            <ResponsiveHallsTable
              onEditButtonClick={handleEditButtonClick}
              isEditSideDrawerOpen={isEditSideDrawerOpen}
            />
          ) : (
            <HallsTable onEditButtonClick={handleEditButtonClick} isEditSideDrawerOpen={isEditSideDrawerOpen} />
          )}

          {isEditSideDrawerOpen && (
            <EditSideDrawerHalls selectedHall={selectedHall} onClose={handleEditSideDrawerClose} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default HallsPage;
