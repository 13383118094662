import { Link } from "react-router-dom";

const SidebarMenuItem = ({ to, label, isActive, isDisabled, isHamburgerMenu }) => {
  const activeStyle = "font-gilroy-bold font-bold italic bg-indigo-900";
  const inactiveStyle = "font-gilroy-medium font-medium transform transition-transform hover:scale-125";
  const textSizeClass = isHamburgerMenu ? "text-3xl" : "text-xl";

  const MenuItemContent = (
    <>
      <span className={`leading-[17px] text-gray-200  py-1 ${textSizeClass}`}>{label}</span>
    </>
  );

  return (
    <>
      {isDisabled ? (
        <div
          className={`flex-start  flex items-center gap-[10px] rounded-md py-[10px] pl-[14px] pr-0 ${
            isActive ? activeStyle : inactiveStyle
          } `}
        >
          {MenuItemContent}
        </div>
      ) : (
        <Link
          to={to}
          className={`flex-start flex w-full text-center justify-center  items-center py-1  rounded-md  ${
            isActive ? activeStyle : inactiveStyle
          } `}
        >
          {MenuItemContent}
        </Link>
      )}
    </>
  );
};

export default SidebarMenuItem;
