import React, { useEffect, useState } from "react";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";
import PaginationButtons from "../other/PaginationButtons";
import PoHelpBattlesTable from "../potable/PoHelpBattlesTable";
import ActiveCategorySelect from "../selectOptions/ActiveCategorySelect";

const HelpBattlesTable = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeBattles, setActiveBattles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUserId = localStorage.getItem("loggedInUserId");
  const loggedInUsername = localStorage.getItem("loggedInUsername");
  const loggedInUserTitle = localStorage.getItem("loggedInUserTitle");
  const [isPaidFilter, setIsPaidFilter] = useState("all");

  const fetchHelpersBattlesWithFilters = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/helper/battles/${loggedInUserId}?category=${selectedCategory}&isPaid=${isPaidFilter}&page=${currentPage}`
      );
      setActiveBattles(response.data.finishedBattles);
      setTotalPages(Math.ceil(response.data.totalCount / 10));
      setCurrentPage(response.data.currentPage);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching helpers battles:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHelpersBattlesWithFilters();
  }, [loggedInUserId, selectedCategory, isPaidFilter, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const categories = [
    "Juniori",
    "Kadeti",
    "KadetiB",
    "Pioniri",
    "PioniriB",
    "Minibasket",
    "Juniorke",
    "Kadetkinje",
    "Pionirke",
    "MinibasketZ",
    "Ostalo",
    "Turnir",
  ];

  const handleIsPaidFilterChange = (filterValue) => {
    setIsPaidFilter(filterValue);
  };

  return (
    <div className='flex flex-col items-center justify-center '>
      <div>
        <React.Fragment>
          <div className='flex  justify-center items-center'>
            <div className='flex flex-col items-center exclude-from-pdf'>
              <span className='mb-2 font-semibold'>Sortiraj po kategorijama:</span>
              <ActiveCategorySelect
                onSelect={handleCategoryChange}
                categories={categories}
                className='border flex rounded px-2 py-1 max-w-full text-black'
              />
            </div>
          </div>
          <div className='flex pb-3 w-full justify-center space-x-2 mt-3 '>
            <button
              className={`${isPaidFilter === "all" ? "bg-blue-500" : "bg-gray-500"} text-white px-4 py-2 rounded`}
              onClick={() => handleIsPaidFilterChange("all")}
            >
              Sve utakmice
            </button>
            <button
              className={`${isPaidFilter === "true" ? "bg-green-800" : "bg-gray-500"} text-white px-4 py-2 rounded`}
              onClick={() => handleIsPaidFilterChange("true")}
            >
              Plaćene utakmice
            </button>
            <button
              className={`${isPaidFilter === "false" ? "bg-red-800" : "bg-gray-500"} text-white px-4 py-2 rounded`}
              onClick={() => handleIsPaidFilterChange("false")}
            >
              Neplaćene utakmice
            </button>
          </div>
          {isLoading ? (
            <div className='flex justify-center items-center h-80'>
              <LoadingSpinner />
            </div>
          ) : (
            activeBattles.map((battle) => (
              <PoHelpBattlesTable
                key={battle._id}
                battle={battle}
                loggedInUsername={loggedInUsername}
                loggedInUserTitle={loggedInUserTitle}
              />
            ))
          )}
        </React.Fragment>
        <PaginationButtons currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default HelpBattlesTable;
