import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SessionLogTable = () => {
  const [content, setContent] = useState("");
  const [text, setText] = useState("");
  const [adminTexts, setAdminTexts] = useState([]);
  const [loggedInUserId, setLoggedInUserId] = useState(""); // Dodano
  const [comments, setComments] = useState([]); // Dodano
  


  useEffect(() => {
    handleGetText();
    handleGetComments(); // Dodano

    // Dohvati loggedInUserId iz local storage-a i postavi ga u state
    const storedUsername = localStorage.getItem("loggedInUserId");
    setLoggedInUserId(storedUsername);
  }, []);


  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSendText = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/comments`, {
        content: content,
        author: loggedInUserId, // Dodano
      });

      toast.success("Tekst uspješno poslan!");

      handleGetComments();
    } catch (error) {
      toast.error("Došlo je do greške prilikom slanja teksta.");
    }
  };
  const handleGetComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/comments`);
      setComments(response.data);
    } catch (error) {
      toast.error("Došlo je do greške prilikom dohvaćanja komentara.");
    }
  };

  const handleGetText = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/adminText`);
      setAdminTexts(response.data.adminTexts);
      setText(response.data.adminTexts.map(text => text.content).join('\n'));
    } catch (error) {
      toast.error("Došlo je do greške prilikom dohvaćanja teksta.");
    }
  };


  function formatDate(unformattedDate) {
    const date = new Date(unformattedDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months start from 0
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Adding leading zeros to day, month, hours, and minutes if they are less than 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Formatted date and time
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  }

  const handleDeleteComment = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/comments/${id}`);
      toast.success("Komentar uspješno izbrisan!");
      handleGetComments();
    } catch (error) {
      toast.error("Došlo je do greške prilikom brisanja komentara.");
    }
  };


  return (
    <div className='max-w-3xl mx-auto mt-8 '>
      <ToastContainer />
      <div className='mb-4 p-4 bg-gray-100 shadow-md rounded-md'>
        <label htmlFor='text' className='block text-gray-900 font-semibold'>
          Dešavanja na sastanku
        </label>
        {adminTexts.map(text => 
          (<div>
            <p class="font-light text-sm mb-2">{formatDate(text.createdAt)}</p>
          <div key={text._id} class="">
            <p className="">{text.content}</p>
          </div>
          </div>

        ))}
      </div>
              <p htmlFor='content' className='ml-2 font-bold mb-2'>Napiši komentar</p>

      <div className='mb-4 p-4 bg-gray-100 shadow-md rounded-md'>
        <button type="submit" onClick={handleSendText} class="font-semibold mb-2">Pošalji</button>
        <textarea
          id='content'
          name='content'
          rows='5'
          className='w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500'
          value={content}
          onChange={handleContentChange}
          required
        />
      </div>
      <div class="font-bold ml-2 mb-2">Moji komentari</div>
      {comments
  .filter(comment => comment.author === loggedInUserId)
  .map(comment => (
    <div key={comment._id} className="p-4 bg-gray-100 shadow-md rounded-md justify-between items-start h-fit">
      <div className="flex flex-col">
      <span className="font-light text-sm">{formatDate(comment.createdAt)}</span>
      <div className=" flex items-center">
      <span className="text-justify mt-3 ">{comment.content}</span>
        <img
              src='icons/deleteIcon.png'
              alt='delete'
              className='w-5 ml-1 cursor-pointer h-5 ml-4 '
              onClick={() => handleDeleteComment(comment._id)}
            />
      </div>
     
            </div>
    </div>
  ))}
  <div className="border-t border-gray-300 mt-4 pt-4"></div>

      <div class="font-bold ml-2 mb-2">Komentari</div>
      <div>   
      {comments.map(comment => (
  <div key={comment._id} className=" bg-gray-100 shadow-md rounded-md p-4 justify-between mb-4 ">
    <div className=" flex flex-col border-b border-gray-300 pb-3 mb-2">
    <span className="text-lg font-semibold">{comment.author}</span>
                <span className="text-sm font-light">{formatDate(comment.createdAt)}</span>
        </div>

        <span className="text-justify ml-5 mr-15">{comment.content}</span>
        </div>

))}     </div>
      </div>
  );
};

export default SessionLogTable;
