import { useState } from "react";

import MainLayout from "../maincontent/MainLayout";
import NavBar from "../navbars/NavBar.jsx";
import MembershipCommisionaresTable from "../tables/MembershipCommisionaresTable.jsx";
import MembershipHelperTable from "../tables/MembershipHelperTable";
import MembershipRefereeTable from "../tables/MembershipRefereeTable";

const MembershipPage = () => {
  const [activePage, setActivePage] = useState(1);

  const navLabels = [`Sudije`, "Pomoćne sudije", "Komesari"];

  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>MEMBERSHIP</div>
        <div className='mt-5 flex flex-col'>
          <div className='mb-6 flex flex-wrap justify-center gap-4 '>
            <NavBar navLabels={navLabels} handlePageSelect={(page) => setActivePage(page)} />
          </div>
          <div className='w-full px-8 pb-8 rounded-xl  '>
            {activePage === 1 && <MembershipRefereeTable />}
            {activePage === 2 && <MembershipHelperTable />}
            {activePage === 3 && <MembershipCommisionaresTable />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default MembershipPage;
