import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import DatePicker from "react-datepicker";

import TeamASelect from "../selectOptions/TeamASelect";
import TeamBSelect from "../selectOptions/TeamBSelect";
import RefereeSelect from "../selectOptions/RefereeSelect";
import LoadingSpinner from "../other/LoadingSpinner";
import ZapisnikSelect from "../selectOptions/ZapisnikSelect";
import SemaforSelect from "../selectOptions/SemaforSelect";
import TasterSelect from "../selectOptions/TasterSelect";
import CategorySelect from "../selectOptions/CategorySelect";
import HoverTooltip from "../other/HoverTooltip";
import StatisticSelect from "../selectOptions/StatisticSelect";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const BattlesTable = ({ onDelete }) => {
  const [, setBattles] = useState([]);
  const [currentPage] = useState(1);
  const [battlesPerPage] = useState(12);
  const [, setTotalBattlesCount] = useState(0);
  const [halls, setHalls] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [teams, setTeams] = useState([]);
  const [teamA, setTeamA] = useState("");
  const [matchNumber, setMatchNumber] = useState(0);
  const [teamB, setTeamB] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [, setSelectedReferee] = useState("");
  const [, setSelectedHelper] = useState("");
  const [helpers, setHelpers] = useState([]);
  const [referees, setReferees] = useState([]);
  const [selectedHallRating, setSelectedHallRating] = useState(1);
  const [ostaloPrice, setOstaloPrice] = useState("20");
  const [turnirPrice, setTurnirPrice] = useState("20");
  const [ostaloHelperPrice, setOstaloHelperPrice] = useState("10");
  const [turnirHelperPrice, setTurnirHelperPrice] = useState("10");

  const [selectedData, setSelectedData] = useState({
    category: "Juniori",
    matchNumber: 0,
    teamA: "",
    teamB: "",
    hall: "",
    date: new Date(),
    referees: [],
    helpers: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hallsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/hall`);
        setHalls(hallsResponse.data);

        const refereesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=9999`);
        setReferees(refereesResponse.data.referees);
        const helpersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`);
        setHelpers(helpersResponse.data.helpers);

        const teamsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/teams?page=1&limit=9999`);
        const teamsData = teamsResponse.data.teams;
        setTeams(teamsData);
        setFilteredTeams(teamsData);
        const uniqueCategories = [...new Set(teamsData.map((team) => team.category))];
        setSelectedData((prevData) => ({ ...prevData, category: uniqueCategories[0] }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchBattles = async () => {
    setIsLoading(true);
    try {
      const battlesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/battles`);
      setBattles(battlesResponse.data);
      setTotalBattlesCount(battlesResponse.data.length);
    } catch (error) {
      console.error("Error fetching battles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBattles();
  }, [currentPage, battlesPerPage]);

  const handleSave = () => {
    const selectedReferee1 = document.getElementById("refereeSelect1")?.value || "";
    const selectedReferee2 = document.getElementById("refereeSelect2")?.value || "";
    const selectedReferee3 = document.getElementById("refereeSelect3")?.value || "";
    const selectedHelper1 = document.getElementById("helperSelect1")?.value || "";
    const selectedHelper2 = document.getElementById("helperSelect2")?.value || "";
    const selectedHelper3 = document.getElementById("helperSelect3")?.value || "";
    const selectedHelper4 = document.getElementById("helperSelect4")?.value || "";

    const hallId = document.getElementById("hallSelect")?.value || "";

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/battles`, {
        category: selectedData.category,
        matchNumber: matchNumber,
        teamA: teamA,
        teamB: teamB,
        hall: hallId,
        referees: [selectedReferee1, selectedReferee2, selectedReferee3].filter(Boolean),
        helpers: [selectedHelper1, selectedHelper2, selectedHelper3, selectedHelper4].filter(Boolean),
        date: selectedDate,
        ostaloPrice: ostaloPrice,
        ostaloHelperPrice: ostaloHelperPrice,
        turnirPrice: turnirPrice,
        turnirHelperPrice: turnirHelperPrice,
      })
      .then((response) => {
        toast.success("Uspješno ste kreirali utakmicu");
        setTimeout(() => {
          onDelete();
        }, 1500);
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
  };

  const handleDraft = () => {
    const hallId = document.getElementById("hallSelect")?.value || "";

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/draftBattle`, {
        category: selectedData.category,
        matchNumber: matchNumber,
        teamA: teamA,
        teamB: teamB,
        hall: hallId,
        date: selectedDate,
        ostaloPrice: ostaloPrice,
        ostaloHelperPrice: ostaloHelperPrice,
        turnirPrice: turnirPrice,
        turnirHelperPrice: turnirHelperPrice,
      })
      .then((response) => {
        toast.success("Uspješno ste prebacili utakmicu u predstojeće utakmice");
        setTimeout(() => {
          onDelete();
        }, 1500);
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
  };

  const handleCategorySelect = (selectedCategory) => {
    setSelectedData((prevData) => ({
      ...prevData,
      category: selectedCategory,
    }));

    if (selectedCategory) {
      const teamsForSelectedCategory = teams.filter((team) => team.category === selectedCategory);
      setFilteredTeams(teamsForSelectedCategory);
    } else {
      setFilteredTeams(teams);
    }
  };

  const handleRefereeSelect = (selectedRefereeId) => {
    setSelectedReferee(selectedRefereeId);
  };
  const handleHelperSelect = (selectedHelperId) => {
    setSelectedHelper(selectedHelperId);
  };

  const handleDeleteTable = () => {
    onDelete();
  };

  return (
    <div className='flex flex-col items-center justify-center '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <div className='flex justify-center items-center '>
            <table className='w-full text-left border-2 border-gray-900 mb-5'>
              <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
                <tr>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 px-6'>Kategorija</th>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Br.</th>
                  <th className='py-1 text-gray-900 text-center border-r-2 border-gray-900'>
                    <TeamASelect filteredTeams={filteredTeams} teamA={teamA} onTeamAChange={setTeamA} />
                  </th>
                  <th className='py-1 text-gray-900 text-center border-r-2 border-gray-900'>
                    <TeamBSelect filteredTeams={filteredTeams} teamB={teamB} onTeamBChange={setTeamB} />
                  </th>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                    <div class='flex flex-col items-center '>
                      <span class='text-gray-200 text-center'>Mjesto,</span>
                      <span class='text-gray-200 text-center'>Dvorana</span>
                    </div>
                  </th>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                    <div class='flex flex-col items-center'>
                      <span class='text-gray-200 text-center'>Datum,</span>
                      <span class='text-gray-200 text-center'>Vrijeme</span>
                    </div>
                  </th>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 px-6'>Akcije</th>
                </tr>
              </thead>
              <tbody className='text-sm'>
                <tr>
                  <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                    <CategorySelect onCategorySelect={handleCategorySelect} className='px-10' />
                  </th>
                  <td className='py-2 w-1 text-center border-r-2 border-gray-900 px-2'>
                    <input
                      type='number'
                      className='w-12 border text-center rounded border-gray-200 py-1'
                      value={matchNumber}
                      onChange={(e) => setMatchNumber(e.target.value)}
                    />
                  </td>
                  <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900 '>
                    <div className='flex flex-col items-center w-44'>
                      <span className='text-blue-400 text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                        name='Zapisnik'
                        helperselect='helperSelect1'
                      />
                      <SemaforSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                        selectedHallRating={selectedHallRating}
                      />
                      {selectedData.category === "Turnir" && (
                        <>
                          <TasterSelect
                            helpers={helpers}
                            battleDate={selectedDate}
                            onSelectHelper={handleHelperSelect}
                          />

                          <span className='text-blue-400 text-center font-bold pb-1'>STATISTIKA:</span>
                          <StatisticSelect
                            helpers={helpers}
                            battleDate={selectedDate}
                            onSelectHelper={handleHelperSelect}
                          />
                        </>
                      )}
                    </div>
                  </th>
                  <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
                    <div className='flex flex-col items-center w-44'>
                      <span className='text-red-600 text-center font-bold pb-1'>SUDIJE:</span>

                      <div className='flex flex-col items-center'>
                        <RefereeSelect
                          referees={referees}
                          onSelectReferee={handleRefereeSelect}
                          refereeNumber='Glavni sudija'
                          battleDate={selectedDate}
                          refereeSelect='refereeSelect1'
                        />

                        <RefereeSelect
                          referees={referees}
                          onSelectReferee={handleRefereeSelect}
                          refereeNumber='Prvi sudija'
                          battleDate={selectedDate}
                          refereeSelect='refereeSelect2'
                        />
                      </div>
                      <RefereeSelect
                        referees={referees}
                        onSelectReferee={handleRefereeSelect}
                        refereeNumber='Drugi sudija'
                        battleDate={selectedDate}
                        refereeSelect='refereeSelect3'
                      />
                    </div>
                  </th>
                  <td className='py-2 px-5 text-center border-r-2 border-gray-900 font-bold'>
                    <select
                      className='w-44'
                      id='hallSelect'
                      onChange={(e) => {
                        const selectedHallId = e.target.value;
                        const selectedHall = halls.find((hall) => hall._id === selectedHallId);
                        setSelectedHallRating(selectedHall ? selectedHall.rating : 1);
                      }}
                    >
                      {halls.map((hall) => (
                        <option key={hall._id} value={hall._id}>
                          {hall.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className='py-2 text-center font-bold border-r-2 border-gray-900'>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      className='text-center'
                      dateFormat='dd.MM.yyyy HH:mm'
                    />
                  </td>
                  <td className='py-2 text-center items-center'>
                    <div className=' flex items-center justify-center'>
                      <HoverTooltip text='Draft' translate='-translate-y-7 -translate-x-4'>
                        <img
                          src='icons/draftIcon.svg'
                          alt='draft'
                          className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                          onClick={handleDraft}
                        />
                      </HoverTooltip>
                      <HoverTooltip text='Save' translate='-translate-y-7 -translate-x-4'>
                        <img
                          src='icons/saveIcon.svg'
                          alt='save'
                          className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                          onClick={handleSave}
                        />
                      </HoverTooltip>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        className='w-6 cursor-pointer icon-container  transform transition-transform hover:scale-125'
                        onClick={handleDeleteTable}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {selectedData.category === "Ostalo" && (
              <div className='justify-center items-center text-center pl-2'>
                <div className='pb-1'>
                  <input
                    id='ostaloPrice'
                    placeholder='Sudije KM'
                    type='text'
                    value={ostaloPrice}
                    onChange={(e) => setOstaloPrice(e.target.value)}
                    className='border border-gray-700 rounded px-2 py-1 w-16 text-center bg-red-300'
                  />
                </div>

                <input
                  id='ostaloHelperPrice'
                  placeholder='P. Sudije KM'
                  type='text'
                  value={ostaloHelperPrice}
                  onChange={(e) => setOstaloHelperPrice(e.target.value)}
                  className='border border-gray-700 rounded px-2 py-1 w-16 text-center bg-blue-200'
                />
              </div>
            )}
            {selectedData.category === "Turnir" && (
              <div className='justify-center items-center text-center pl-2'>
                <div className='pb-1'>
                  <input
                    id='turnirPrice'
                    placeholder='Sudije KM'
                    type='text'
                    value={turnirPrice}
                    onChange={(e) => setTurnirPrice(e.target.value)}
                    className='border border-gray-700 rounded px-2 py-1 w-16 text-center bg-red-300'
                  />
                </div>

                <input
                  id='turnirHelperPrice'
                  placeholder='P. Sudije KM'
                  type='text'
                  value={turnirHelperPrice}
                  onChange={(e) => setTurnirHelperPrice(e.target.value)}
                  className='border border-gray-700 rounded px-2 py-1 w-16 text-center bg-blue-200'
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      <ToastContainer />
    </div>
  );
};

export default BattlesTable;
