import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginForm = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [fullName, setFullName] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("Sudija");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleLogin = async () => {
    try {
      const [firstName, lastName] = fullName.split(" ");

      let route;

      switch (selectedTitle) {
        case "Pomoćni sudija":
          route = "helpers";
          break;
        case "Komesar":
          route = "commisionare";
          break;
        case "Sudija":
          route = "referee";
          break;
        default:
          route = "referee";
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/${route}/login`, {
        firstName,
        lastName,
        password,
      });

      const { token, title, userId } = response.data;

      if (token) {
        if (title === "Admin" || title === "Sudija" || title === "Pomoćni sudija" || title === "Komesar") {
          localStorage.setItem("token", token);
          localStorage.setItem("loggedInUsername", fullName);
          localStorage.setItem("loggedInUserTitle", title);
          localStorage.setItem("loggedInUserId", userId);
          setLoginError("");

          switch (title) {
            case "Pomoćni sudija":
              navigate("/helpbattles");
              break;
            case "Sudija":
              navigate("/refbattles");
              break;
            case "Admin":
              navigate("/referees");
              break;
            case "Komesar":
              navigate("/listpage");
              break;
            default:
              navigate("/refbattles");
              break;
          }

          window.location.reload();
        } else {
          setLoginError(`Morate biti ${selectedTitle.toLowerCase()} da biste se prijavili.`);
        }
      } else {
        setLoginError("Pogrešno korisničko ime ili lozinka.");
      }
    } catch (error) {
      setLoginError("Pogrešno korisničko ime ili lozinka.");
    }
  };

  const handlePasswordKeyPress = (e) => {
    if (e.key === "Enter" && fullName && password) {
      handleLogin();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`flex h-screen flex-col items-center justify-center ${windowWidth < 1024 ? "w-full" : "w-9/12"}`}>
      {windowWidth < 1024 && (
        <div className='fixed inset-0 z-10'>
          <img
            src='/images/mobile-background.jpg'
            alt='Background image'
            className='h-full w-full object-cover opacity-50'
          />
        </div>
      )}
      {windowWidth < 1024 && (
        <div className='fixed inset-0 z-20 text-center justify-center items-center h-auto flex'>
          <img
            src='/images/logouksk.svg'
            alt='Logo image'
            className='text-center justify-center flex items-center w-11/12 object-cover opacity-5'
          />
        </div>
      )}
      <div className='w-full z-30 '>
        <h2 className='text-xl mb-5 font-bold uppercase justify-center text-center pt-12'>
          <p className='text-black text-3xl font-bold pb-8'>PRIJAVITE SE KAO</p>
          <div className='flex justify-between space-x-4 '>
            <button
              className={`${
                selectedTitle === "Sudija"
                  ? "border-green-400 p-1 rounded-lg border-4 flex text-center justify-center animate-pulse"
                  : "border-4 rounded-lg p-1 border-gray-300 flex text-center justify-center"
              }`}
              onClick={() => setSelectedTitle("Sudija")}
            >
              <div>
                <img src='/images/referee.png' alt='Referee' className='w-40' />
              </div>
            </button>

            <button
              className={`${
                selectedTitle === "Pomoćni sudija"
                  ? "border-green-600 p-1 border-4 rounded-lg flex text-center justify-center animate-pulse"
                  : "border-4 p-1 border-gray-300 rounded-lg flex text-center justify-center"
              }`}
              onClick={() => setSelectedTitle("Pomoćni sudija")}
            >
              <div>
                <img src='/images/helper.png' alt='Helper' className='w-40' />
              </div>
            </button>
            <button
              className={`${
                selectedTitle === "Komesar"
                  ? "border-green-600 p-1 border-4 rounded-lg flex text-center justify-center animate-pulse"
                  : "border-4 p-1 border-gray-300 rounded-lg flex text-center justify-center"
              }`}
              onClick={() => setSelectedTitle("Komesar")}
            >
              <div>
                <img src='/images/komesar.png' alt='Komesar' className='w-40' />
              </div>
            </button>
          </div>
        </h2>
        <div className='flex mb-5 items-center justify-center text-center '>
          <h2 className='text-3xl font-bold  '>Prijava </h2>
        </div>

        <div className='mb-4'>
          <div className='mb-2'>
            <label className='font-bold text-lg'>Ime i prezime</label>
          </div>
          <input
            type='text'
            placeholder='Ime i prezime'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            onKeyPress={handlePasswordKeyPress}
            className='p-3 border border-gray-300 rounded w-full'
          />
        </div>
        <div className='mb-2'>
          <label className='font-bold text-lg'>Password</label>
        </div>
        <div className='mb-4'>
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handlePasswordKeyPress}
            className='p-3 border border-gray-300 rounded w-full'
          />
        </div>
        {loginError && <p className='text-red-500 my-2'>{loginError}</p>}

        <button
          onClick={handleLogin}
          className='border border-gray-400 bg-gradient-to-r from-blue-400 to-green-400 w-full hover:bg-blue-800 text-white font-semibold px-4 py-3 rounded-lg'
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
