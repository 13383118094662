import React, { useState } from "react";

const HoverTooltip = ({ text, children, translate }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className='relative inline-block'
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && (
        <div className={`absolute top-0 transform ${translate} bg-gray-900 px-4 text-white rounded`}>{text}</div>
      )}
      {children}
    </div>
  );
};

export default HoverTooltip;
