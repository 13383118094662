import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const EditSideDrawerHalls = ({ selectedHall, onClose }) => {
  const [ime, setIme] = useState(selectedHall.name);
  const [rating, setRating] = useState(String(selectedHall.rating));

  const handleUpdateHall = () => {
    if (!ime) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const updateHall = {
      name: ime,
      rating: Number(rating),
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/hall/${selectedHall._id}`, updateHall)
      .then((response) => {
        toast.success("Uspješno ste updateovali podatke");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Error updating hall:", error);
      });
  };

  return (
    <div className='fixed z-20 w-full right-0 top-0 h-screen md:w-2/5 lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg'>
      <div className='p-5'>
        <div className='flex flex-row justify-left items-center'>
          <img src='icons/leftArrow.png' alt='delete' className='w-6 h-6 cursor-pointer' onClick={onClose}></img>
          <h1
            className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110'
            onClick={onClose}
          >
            Back
          </h1>
        </div>

        <div className='my-4 mx-5'>
          <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='hallName'>
            Naziv dvorane:
          </label>
          <input
            id='hallName'
            placeholder='Unesite naziv dvorane'
            type='text'
            className='border rounded px-2 py-1 w-full'
            value={ime}
            onChange={(e) => setIme(e.target.value)}
          />
        </div>
        <div className='my-4 mx-5 text-left'>
          <label className='block mb-2 text-xl font-bold text-gray-200' htmlFor='hallRating'>
            Rating:
          </label>
          <select
            id='hallRating'
            className='border rounded px-2 py-1 w-full'
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          >
            {[1, 2, 3].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <div className=' mb-1 mt-2 text-red-600 font-semibold text-sm '>
            <p>1 - Ostalo, 2 - Skenderija D1, 3 - Grbavica</p>
          </div>
        </div>
        <div className='flex items-center justify-end mx-5'>
          <button
            className='border-2 text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-xl'
            onClick={() => {
              handleUpdateHall();
            }}
          >
            Uredi
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditSideDrawerHalls;
