const ZapisnikSelect = ({ helpers, battleDate, onSelectHelper, name, helperselect }) => {
  const filteredHelpers = helpers.filter((helper) => {
    if (
      helper.helperRating === 1 ||
      helper.helperRating === 2 ||
      helper.helperRating === 3 ||
      helper.helperRating === 4
    ) {
      if (!helper.startDate || !helper.endDate) {
        return true;
      }

      const battleDateObj = new Date(battleDate);
      return battleDateObj < new Date(helper.startDate) || battleDateObj > new Date(helper.endDate);
    }
    return false;
  });

  return (
    <select
      className='text-gray-800 text-center font-semibold w-44'
      id={helperselect}
      onChange={(e) => onSelectHelper(e.target.value)}
    >
      <option value=''>{name}</option>
      {filteredHelpers.map((helper, index) => (
        <option key={index} value={helper._id}>
          {helper.firstName} {helper.lastName}
        </option>
      ))}
    </select>
  );
};

export default ZapisnikSelect;
