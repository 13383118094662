const CategorySelect = ({ onCategorySelect }) => {
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    onCategorySelect(selectedCategory);
  };

  return (
    <select id='clubCategory' className='border rounded px-2 py-1 w-28 text-black' onChange={handleCategoryChange}>
      <option value=''>Kategorija</option>
      <option value='Juniori'>Juniori</option>
      <option value='Kadeti'>Kadeti</option>
      <option value='KadetiB'>Kadeti B</option>
      <option value='Pioniri'>Pioniri</option>
      <option value='PioniriB'>Pioniri B</option>
      <option value='Minibasket'>Minibasket</option>
      <option value='Juniorke'>Juniorke</option>
      <option value='Kadetkinje'>Kadetkinje</option>
      <option value='Pionirke'>Pionirke</option>
      <option value='MinibasketZ'>Minibasket djevojčice</option>
      <option value='Ostalo'>Ostalo</option>
      <option value='Turnir'>Turnir</option>
    </select>
  );
};

export default CategorySelect;
