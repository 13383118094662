import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SessionLogTable = () => {
  const [content, setContent] = useState("");
  const [texts, setTexts] = useState("");
  const [adminTexts, setAdminTexts] = useState([]);
  const [comments, setComments] = useState([]); // Dodano

  useEffect(() => {
    handleGetText();
    handleGetComments(); // Dodano

  }, []);

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSendText = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/adminText`, {
        content: content
      });

      toast.success("Tekst uspješno poslan!");

      handleGetText();
    } catch (error) {
      toast.error("Došlo je do greške prilikom slanja teksta.");
    }
  };

  const handleGetText = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/adminText`);
      setAdminTexts(response.data.adminTexts);
      setTexts(response.data.adminTexts.map(text => text.content).join('\n'));
    } catch (error) {
      toast.error("Došlo je do greške prilikom dohvaćanja teksta.");
    }
  };

  const handleGetComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/comments`);
      setComments(response.data);
      console.log(response.data, '123')
    } catch (error) {
      toast.error("Došlo je do greške prilikom dohvaćanja komentara.");
    }
  };

  const handleDeleteText = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/adminText/${id}`);
      toast.success("Tekst uspješno obrisan!");
      handleGetText();
    } catch (error) {
      toast.error("Došlo je do greške prilikom brisanja teksta.");
    }
  };

function formatDate(unformattedDate) {
    const date = new Date(unformattedDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months start from 0
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Adding leading zeros to day, month, hours, and minutes if they are less than 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Formatted date and time
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <div className='max-w-3xl mx-auto mt-8 '>
      <ToastContainer />

      <div className='mb-4 bg-gray-100 shadow-md rounded-md p-4'>
        <button type="submit" onClick={handleSendText} class="font-semibold">Pošalji</button>
        <label htmlFor='content' className='block text-gray-900 mb-2'>
          Sadržaj sastanka
        </label>
        <textarea
          id='content'
          name='content'
          rows='5'
          className='w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500'
          value={content}
          onChange={handleContentChange}
          required
        />
      </div>
      <div className='mb-4 bg-gray-100 shadow-md rounded-md p-4'>
        <label htmlFor='texts' className='block text-gray-900 font-semibold'>
          Dešavanja na sastanku
        </label>
        {adminTexts.map(text => 
          (<div>
            <span className="text-sm font-light" >{formatDate(text.createdAt)}</span>
          <div key={text._id} class="flex justify-between items-center">
            <span className="max-w-3xl text-justify mt-2 ">{text.content}</span>
             <img
              src='icons/deleteIcon.png'
              alt='delete'
              className='w-5 cursor-pointer h-5 ml-4 '
onClick={() => handleDeleteText(text._id)}            />
          </div>
          </div>

        ))}
      </div>
      <div class="font-semibold mb-3">Komentari</div>
      {comments.map(comment => (
  <div key={comment._id} className=" bg-gray-100 shadow-md rounded-md p-4 justify-between mb-4 ">
    <div className=" flex flex-col border-b border-gray-300 pb-3 mb-2">
    <span className="text-lg font-semibold">{comment.author}</span>
                <span className="text-sm font-light">{formatDate(comment.createdAt)}</span>
        </div>

        <span className="text-justify ml-5 mr-16">{comment.content}</span>
        </div>



))}  
    </div>
  );
};

export default SessionLogTable;
