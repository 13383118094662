// Updated PaginationButtons component

import React from "react";

const PaginationButtons = ({ currentPage, totalPages, onPageChange }) => {
  const renderPages = () => {
    const visiblePages = [];
    const totalPagesToShow = 3; // Number of visible pages before and after the current page

    for (let page = 1; page <= totalPages; page++) {
      const isNearCurrentPage = Math.abs(currentPage - page) <= totalPagesToShow;
      const isFirstPage = page === 1;
      const isLastPage = page === totalPages;

      if (isFirstPage || isLastPage || isNearCurrentPage) {
        visiblePages.push(page);
      } else if (visiblePages[visiblePages.length - 1] !== "ellipsis") {
        visiblePages.push("ellipsis");
      }
    }

    return visiblePages;
  };

  const renderPagination = () => {
    const pages = renderPages();

    const pagination = pages.map((page, index, array) => {
      const isCurrentPage = currentPage === page;
      const isEllipsis = page === "ellipsis";

      if (isEllipsis) {
        return <span key={`ellipsis${index}`}>...</span>;
      }

      return (
        <button
          key={page}
          className={`px-4 py-2 mx-1 ${
            isCurrentPage
              ? "bg-gradient-to-r from-indigo-800 to-indigo-700 transform transition-transform hover:scale-105 text-white"
              : "bg-blue-200 text-blue-700"
          } rounded-lg`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      );
    });

    return pagination;
  };

  return (
    <div className='flex items-center justify-center'>
      <button
        className='px-4 py-2 mx-1 border-1 border-black bg-gradient-to-r from-indigo-800 to-indigo-700 transform transition-transform hover:scale-105 text-white rounded-lg'
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {renderPagination()}
      <button
        className='px-4 py-2 mx-1 border-1 border-black bg-gradient-to-r from-indigo-800 to-indigo-700 transform transition-transform hover:scale-105 text-white rounded-lg'
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationButtons;
