import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerHalls from "../sidedrawer/EditSideDrawerHall";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const ResponsiveHallsTable = (onClose) => {
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [halls, setHalls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHall, setSelectedHall] = useState(null);
  const [currentPage] = useState(1);
  const [hallsPerPage] = useState(12);
  const [, setTotalHallsCount] = useState(0);
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    fetchHalls();
  }, [currentPage, hallsPerPage]);

  const fetchHalls = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/hall`)
      .then((response) => {
        setHalls(response.data);
        setTotalHallsCount(response.data.length);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching halls:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (hallId) => {
    setShowDeleteModal(true);
    setSelectedHall(hallId);
  };

  const handleDeleteConfirmation = () => {
    toast.success("Uspješno ste obrisali dvoranu");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/hall/${selectedHall}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali dvoranu");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedHall(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedHall(null);
  };

  const handleEditButtonClick = (selectedHallData) => {
    setIsEditSideDrawerOpen(true);
    setSelectedHall(selectedHallData);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left'>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-10'>
              <tr>
                <th className='py-5 text-gray-200 text-center'>Naziv dvorane</th>
                <th className='py-5 text-gray-200 text-center'>Potreban rating</th>
              </tr>
            </thead>
            <tbody className='p-5'>
              {halls.map((hall, index) => (
                <React.Fragment key={hall._id}>
                  <tr
                    key={hall._id}
                    className={`hover:bg-gray-200  border border-gray-200 cursor-pointer ${
                      expandedRow === index ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setExpandedRow(index === expandedRow ? null : index)}
                  >
                    <td className='py-2 text-center'>{hall.name}</td>
                    <td className='py-2 text-center'>{hall.rating}</td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan='3' className='px-10 py-2'>
                        <div className='text-center justify-center items-center flex flex-col py-2'>
                          <button
                            onClick={() => handleEditButtonClick(hall)}
                            className='border-2 w-full text-center text-white bg-gradient-to-r from-green-900 to-green-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg mb-2'
                          >
                            Uredi
                          </button>
                          <button
                            //   onClick={() => handleDelete(hall._id)}
                            className='cursor-not-allowed border-2 w-full text-white bg-gradient-to-r from-red-900 to-red-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg'
                          >
                            Obriši
                          </button>
                        </div>
                        {isEditSideDrawerOpen && (
                          <EditSideDrawerHalls selectedHall={selectedHall} onClose={handleEditSideDrawerClose} />
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={selectedHall ? halls.find((hall) => hall._id === selectedHall)?.name : ""}
          onDelete={() => handleDeleteConfirmation(selectedHall._id)}
          onCancel={handleDeleteCancel}
          type='dvorana'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ResponsiveHallsTable;
