import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerClubs from "../sidedrawer/EditSideDrawerClubs";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";
import PasswordModal from "../modals/PasswordModal";

import "react-toastify/dist/ReactToastify.css";

const ClubsTable = ({ selectedCategory }) => {
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [klubovi, setKlubovi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [teamsPerPage, setTeamsPerPage] = useState(12);
  const [totalTeamsCount, setTotalTeamsCount] = useState(0);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    fetchTeams();
  }, [currentPage, teamsPerPage, selectedCategory]);

  const fetchTeams = () => {
    let url;
    if (selectedCategory === "All") {
      url = `${process.env.REACT_APP_API_BASE_URL}/teams?page=${currentPage}&limit=${teamsPerPage}`;
    } else {
      url = `${process.env.REACT_APP_API_BASE_URL}/teams?category=${selectedCategory}&page=${currentPage}&limit=${teamsPerPage}`;
    }

    axios
      .get(url)
      .then((response) => {
        setKlubovi(response.data.teams);
        setTotalTeamsCount(response.data.totalTeamsCount);
      })
      .catch((error) => {
        toast.error("Error fetching clubs:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (clubId) => {
    setShowPasswordModal(true);
    setSelectedClub(clubId);
  };

  const handlePasswordSubmit = () => {
    if (password === "12344321") {
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteConfirmation = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/teams/${selectedClub}`)
      .then((response) => {
        toast.success("Klub uspješno obrisan");
        window.location.reload();
      })
      .catch((error) => {
        console.log("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedClub(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedClub(null);
  };

  const handleEditButtonClick = (selectedClubData) => {
    setIsEditSideDrawerOpen(true);
    setSelectedClub(selectedClubData);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTeamsPerPageChange = (e) => {
    setCurrentPage(1);
    setTeamsPerPage(parseInt(e.target.value));
  };

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10 '>
              <tr>
                <th className='py-5  text-gray-200 text-center'>Ime kluba</th>
                <th className='py-5 px-5 text-gray-200 cursor-pointer items-center justify-center align-middle text-center '>
                  Rating{" "}
                </th>
                <th className='py-5 px-5 text-gray-200 text-center'>Kategorija</th>
                <th className='py-5 px-5 text-gray-200 text-center'>Akcije</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {klubovi.map((klub) => (
                <tr key={klub._id} className=' cursor-pointer border border-gray-200 '>
                  <td className='py-2 px-5 text-center'>{klub.name}</td>
                  <td className='py-2 px-5 text-center'>{klub.rating}</td>
                  <td className='py-2 px-5 text-center'>{klub.category}</td>
                  <td className='py-2 text-center flex flex-row justify-center'>
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/editIcon.png'
                        alt='edit'
                        onClick={() => handleEditButtonClick(klub)}
                        className='w-6 cursor-pointer'
                      />
                    </div>

                    {isEditSideDrawerOpen && (
                      <EditSideDrawerClubs selectedClub={selectedClub} onClose={handleEditSideDrawerClose} />
                    )}

                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        onClick={() => handleDelete(klub._id)}
                        className='w-6 cursor-pointer'
                      />
                    </div>
                  </td>
                </tr>
              ))}
              <tr className='border-t-2 '></tr>
            </tbody>
          </table>

          <div className='flex justify-between w-full'>
            <div className='my-5 px-5'>
              <div className='mr-2 mb-1'>Redovi:</div>
              <select className='px-2 py-1 border rounded-md' value={teamsPerPage} onChange={handleTeamsPerPageChange}>
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={36}>36</option>
              </select>
            </div>
            <div className='my-5 px-5 flex justify-center items-center'>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={Math.ceil(totalTeamsCount / teamsPerPage)}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={selectedClub ? klubovi.find((club) => club._id === selectedClub)?.name : ""}
          onDelete={() => handleDeleteConfirmation(selectedClub._id)}
          onCancel={handleDeleteCancel}
          type='klub'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ClubsTable;
