import { useLocation } from "react-router-dom";
import SidebarMenuItem from "./SidebarMenuItem";

const SidebarMenu = ({ isAdmin, isReferee, isHelper, isHamburgerMenu, isKomesar }) => {
  const location = useLocation();

  return (
    <div className='flex flex-col items-center gap-[8px] '>
      {isAdmin && (
        <>
          <SidebarMenuItem
            to='/draftbattle'
            label='NAREDNE UTAKMICE'
            isActive={location.pathname === "/draftbattle"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/referees'
            label='Sudije'
            isActive={location.pathname === "/referees"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/helpers'
            label='Pomoćne sudije'
            isActive={location.pathname === "/helpers"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/commisionares'
            label='Komesari'
            isActive={location.pathname === "/commisionares"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/clubs'
            label='Klubovi'
            isActive={location.pathname === "/clubs"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/halls'
            label='Dvorane'
            isActive={location.pathname === "/halls"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/battles'
            label='Kreiraj utakmicu'
            isActive={location.pathname === "/battles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/seniorbattles'
            label='Kreiraj stolove'
            isActive={location.pathname === "/seniorbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/activebattles'
            label='Aktivne utakmice'
            isActive={location.pathname === "/activebattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/listbattles'
            label='Završene utakmice'
            isActive={location.pathname === "/listbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/listseniorbattles'
            label='Završeni stolovi'
            isActive={location.pathname === "/listseniorbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/money'
            label='Dugovanja'
            isActive={location.pathname === "/money"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/statistics'
            label='Statistika'
            isActive={location.pathname === "/statistics"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/membership'
            label='Membership'
            isActive={location.pathname === "/membership"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/sessionlog'
            label='Zapisnik sa sastanka'
            isActive={location.pathname === "/sessionlog"}
            isHamburgerMenu={isHamburgerMenu}
          />
        </>
      )}
      {isReferee && (
        <>
          <SidebarMenuItem
            to='/refactivebattles'
            label='Naredne utakmice'
            isActive={location.pathname === "/refactivebattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/refbattles'
            label='Utakmice'
            isActive={location.pathname === "/refbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/statsref'
            label='Statistika'
            isActive={location.pathname === "/statsref"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/moneyref'
            label='Dugovanja'
            isActive={location.pathname === "/moneyref"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/refabsence'
            label='Najava odsustva'
            isActive={location.pathname === "/refabsence"}
            isHamburgerMenu={isHamburgerMenu}
          />
           <SidebarMenuItem
            to='/sessionlogall'
            label='Zapisnik sa sastanka'
            isActive={location.pathname === "/sessionlogall"}
            isHamburgerMenu={isHamburgerMenu}
          />
        </>
      )}
      {isHelper && (
        <>
          <SidebarMenuItem
            to='/helpactivebattles'
            label='Naredne utakmice'
            isActive={location.pathname === "/helpactivebattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/helpbattles'
            label='Utakmice'
            isActive={location.pathname === "/helpbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/helpseniorbattles'
            label='Stolovi'
            isActive={location.pathname === "/helpseniorbattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/statshelp'
            label='Statistika'
            isActive={location.pathname === "/statshelp"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/moneyhelp'
            label='Dugovanja'
            isActive={location.pathname === "/moneyhelp"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/helpabsence'
            label='Najava odsustva'
            isActive={location.pathname === "/helpabsence"}
            isHamburgerMenu={isHamburgerMenu}
          />
            <SidebarMenuItem
            to='/sessionlogall'
            label='Zapisnik sa sastanka'
            isActive={location.pathname === "/sessionlogall"}
            isHamburgerMenu={isHamburgerMenu}
          />
        </>
      )}
      {isKomesar && (
        <>
          <SidebarMenuItem
            to='/listpage'
            label='Lista članova UKSK'
            isActive={location.pathname === "/listpage"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/commisionarebattles'
            label='Aktivne utakmice'
            isActive={location.pathname === "/commisionarebattles"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/money'
            label='Dugovanja'
            isActive={location.pathname === "/money"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/statistics'
            label='Statistika'
            isActive={location.pathname === "/statistics"}
            isHamburgerMenu={isHamburgerMenu}
          />
          <SidebarMenuItem
            to='/membership'
            label='Membership'
            isActive={location.pathname === "/membership"}
            isHamburgerMenu={isHamburgerMenu}
          />
           <SidebarMenuItem
            to='/sessionlogall'
            label='Zapisnik sa sastanka'
            isActive={location.pathname === "/sessionlogall"}
            isHamburgerMenu={isHamburgerMenu}
          />
        </>
      )}
    </div>
  );
};

export default SidebarMenu;
