import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Switcher from "../other/Switcher";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PasswordModal from "../modals/PasswordModal";

import "react-toastify/dist/ReactToastify.css";

const PoBattlesListTable = ({ battle }) => {
  const [isPaid, setIsPaid] = useState(battle.isPaid);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [, setSelectedBattle] = useState(null);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  const handlePaidStatusChange = () => {
    const updatedIsPaid = !isPaid;
    setIsPaid(updatedIsPaid);

    const url = `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/setpaid/${battle._id}`;

    axios
      .put(url, { isPaid: updatedIsPaid })
      .then((response) => {
        console.log("Paid status updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating paid status:", error);
      });
  };

  const handleDeleteConfirmation = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle/${battle._id}`)
      .then(() => {
        toast.success("Utakmica je uspješno obrisana");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleDelete = (battleid) => {
    setShowPasswordModal(true);
    setSelectedBattle(battleid);
  };

  const handlePasswordSubmit = () => {
    // Proverite da li je uneta ispravna lozinka
    if (password === "12344321") {
      // Postavite odgovarajuću lozinku
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      // Prikazati poruku o neispravnoj lozinci
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedBattle(null);
  };

  return (
    <div className='flex'>
      <>
        <table key={battle._id} className='w-full text-left border-2 border-gray-900 mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-32'>Kategorija</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-12'>Br.</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-52'>{battle.teamA?.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-52'>{battle.teamB?.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-32'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-200 text-center'>Mjesto,</span>
                  <span class='text-gray-200 text-center'>Dvorana</span>
                </div>
              </th>
              <th className='py-1 flex justify-center text-gray-200 text-center relative'>
                <div class='flex flex-col items-center'>
                  <span class='text-gray-200 text-center'>Datum,</span>
                  <span class='text-gray-200 text-center'>Vrijeme</span>
                </div>
                <div className='absolute top-0 right-0'>
                  <img
                    src='icons/deleteIcon.png'
                    alt='delete'
                    onClick={() => handleDelete(battle._id)}
                    className='w-4 mr-1 mt-1  cursor-pointer h-4 brightness-0 filter invert'
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='p-2 text-sm'>
            <tr>
              <th className='py-1 text-gray-800 text-center border-r-2 border-gray-900 px-5'>
                {" "}
                {battle.category === "KadetiB"
                  ? "Kadeti B"
                  : battle.category === "PioniriB"
                  ? "Pioniri B"
                  : battle.category}
              </th>

              <td className='py-2 px-1 text-center border-r-2 border-gray-900'>{battle.matchNumber}</td>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
                <div className='flex flex-col items-center'>
                  <span className='text-black text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                  {battle.helpers.map((helper) => (
                    <span key={helper._id} className='font-semibold'>
                      {helper.firstName} {helper.lastName}
                    </span>
                  ))}
                </div>
              </th>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
                <div className='flex flex-col items-center'>
                  <span className='text-black text-center font-bold pb-1'>SUDIJE:</span>
                  {battle.referees.map((referee) => (
                    <span key={referee._id} className='font-semibold'>
                      {referee.firstName} {referee.lastName}
                    </span>
                  ))}
                </div>
              </th>
              <td className='py-2 px-5 text-center border-r-2 border-gray-900 font-bold'>
                {battle && battle.hall ? battle.hall.name : ""}
              </td>

              <td className='py-2 px-5 text-center font-bold '>{formatDate(battle.date)}</td>
            </tr>
          </tbody>
        </table>
        <Switcher isPaid={isPaid} setIsPaid={handlePaidStatusChange} />
      </>

      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${battle.category} |  ${battle.teamA.name} - ${battle.teamB.name}`}
          onDelete={() => handleDeleteConfirmation(battle._id)}
          onCancel={handleDeleteCancel}
          type='utakmicu'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default PoBattlesListTable;
