import React from "react";

import MainLayout from "../maincontent/MainLayout";
import StatsHelpTable from "../tables/StatsHelpTable";

const StatsHelpPage = () => {
  return (
    <MainLayout>
      <div className='mt-20'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>STATISTIKA</div>
        <div className='flex w-full justify-center items-center'>
          <div className='w-full p-8 rounded-xl '>
            <StatsHelpTable />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default StatsHelpPage;
