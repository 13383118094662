import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../other/LoadingSpinner";
import * as XLSX from "xlsx";

const MembershipRefereeTable = () => {
  const [sudije, setSudije] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editReferee, setEditReferee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee`)
      .then((response) => {
        setSudije(response.data.referees);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();

    return `${year}.`;
  };

  const handleEditClick = (referee) => {
    setEditReferee(referee);
    setSelectedDate(new Date(referee.membershipDay));
  };

  const handleSaveClick = async () => {
    try {
      const updatedReferee = {
        ...editReferee,
        membershipDay: selectedDate,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/referee/${editReferee._id}`,
        updatedReferee
      );

      setSudije((prevSudije) => prevSudije.map((sudija) => (sudija._id === editReferee._id ? response.data : sudija)));

      toast.success("Referee updated successfully");
      setEditReferee(null);
    } catch (error) {
      toast.error("Error updating referee:", error);
    }
  };

  const downloadExcel = () => {
    try {
      const data = sudije.map((sudija) => ({
        Ime: sudija.firstName,
        Prezime: sudija.lastName,
        Članarina: `${sudija.price} KM / ${
          sudija.refereeRating < 6 ? "80 KM" : sudija.refereeRating === 10 ? "240 KM" : "120 KM"
        }`,
        "Član od": editReferee && editReferee._id === sudija._id ? selectedDate : formatDate(sudija.membershipDay),
      }));

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sudije");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Sudije članarina.xlsx";
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full  text-left'>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-2 py-2'>
              <tr>
                <th className='py-2 px-5 text-gray-200'>Ime</th>
                <th className='py-2 px-5 text-gray-200'>Prezime</th>
                <th className='py-2 px-5 text-gray-200'>Članarina</th>

                <th className='py-2 px-5 text-gray-200 text-center'>Član od:</th>
              </tr>
            </thead>
            <tbody className='p-2 px-5'>
              {sudije.map((sudija, index) => (
                <React.Fragment key={sudija._id}>
                  <tr
                    className={`cursor-pointer hover:bg-gray-200 border border-gray-300 ${
                      expandedRow === index ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setExpandedRow(index === expandedRow ? null : index)}
                  >
                    <td className='py-1 px-5'>{sudija.firstName}</td>
                    <td className='py-1 px-5'>{sudija.lastName}</td>
                    <td className='py-1 px-5'>
                      {sudija.price} KM /{" "}
                      {sudija.refereeRating < 6 ? "80 KM" : sudija.refereeRating === 10 ? "240 KM" : "120 KM"}
                    </td>

                    <td className='py-1 px-5 text-center'>
                      {editReferee && editReferee._id === sudija._id ? (
                        <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                      ) : (
                        formatDate(sudija.membershipDay)
                      )}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className='border border-gray-300'>
                      <td colSpan='3' className='px-10 py-2'>
                        <div>
                          <div className='flex justify-center font-medium '>
                            <p></p>
                            <td className=''>
                              {editReferee && editReferee._id === sudija._id ? (
                                <button
                                  onClick={handleSaveClick}
                                  className='bg-blue-600 hover:bg-blue-700 text-white font-bold py-1 px-36 rounded '
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleEditClick(sudija)}
                                  className='bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-36 rounded '
                                >
                                  Edit
                                </button>
                              )}
                            </td>{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className='flex mt-5 justify-end items-center bg-green-900 rounded px-3 py-2 transform transition-transform hover:scale-105'>
            <img src='icons/excelLogo.svg' alt='EXCEL' className='w-7 h-7 mr-2' />
            <button onClick={downloadExcel} className='text-white font-semibold'>
              Download Excel
            </button>
          </div>
        </React.Fragment>
      )}

      <ToastContainer />
    </div>
  );
};

export default MembershipRefereeTable;
