import React, { useState, useEffect } from "react";

import MainLayout from "../maincontent/MainLayout";
import SeniorBattlesTable from "../tables/SeniorBattlesTable";
import CreateButton from "../other/CreateButton";

const SeniorBattlesPage = () => {
  const [tables, setTables] = useState([]);
  const [tableCounter, setTableCounter] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateTable = () => {
    setTables((prevTables) => [...prevTables, { id: tableCounter + 1 }]);
    setTableCounter((prevCounter) => prevCounter + 1);
  };

  const handleTableDelete = (tableId) => {
    setTables((prevTables) => prevTables.filter((table) => table.id !== tableId));
  };

  return (
    <MainLayout>
      <div className={`flex ${isMobile ? "flex-col " : ""} pt-20 px-9 justify-between text-center items-center`}>
        <div className={` ${isMobile ? "pb-3 text-4xl " : ""}text-center text-3xl font-bold `}>KREIRAJ STOLOVE</div>

        <div className='justify-end'>
          <CreateButton buttonName='Kreiraj utakmicu' onClick={handleCreateTable} />
        </div>
      </div>
      {tables.map((table) => (
        <div key={table.id} className='flex flex-col items-center'>
          <div className='flex w-full justify-center items-center'>
            <div className='w-4/6 p-8 rounded-xl'>
              <SeniorBattlesTable onDelete={() => handleTableDelete(table.id)} />
            </div>
          </div>
        </div>
      ))}
    </MainLayout>
  );
};

export default SeniorBattlesPage;
