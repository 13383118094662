import { useState, useEffect } from "react";

import ListTable from "../tables/ListTable";

import MainLayout from "../maincontent/MainLayout";

const ListPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MainLayout>
      <div className='w-full p-8  '>
        <ListTable />
      </div>
    </MainLayout>
  );
};

export default ListPage;
