import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ClubsPage from "./components/pages/ClubsPage";
import RefereesPage from "./components/pages/RefereesPage";
import HelpersPage from "./components/pages/HelpersPage";
import HallsPage from "./components/pages/HallsPage";
import BattlesPage from "./components/pages/BattlesPage";
import DraftBattlePage from "./components/pages/DraftBattlePage";
import ActiveBattlesPage from "./components/pages/ActiveBattlesPage";
import StatsRefPage from "./components/pages/StatsRefPage";
import MoneyRefPage from "./components/pages/MoneyRefPage";
import MoneyHelpPage from "./components/pages/MoneyHelpPage";
import StatsHelpPage from "./components/pages/StatsHelpPage";
import HelpAbsencePage from "./components/pages/HelpAbsencePage ";
import RefActiveBattlesPage from "./components/pages/RefActiveBattlesPage";
import HelpActiveBattlesPage from "./components/pages/HelpActiveBattlesPage";
import MoneyPage from "./components/pages/MoneyPage";
import ListBattlesPage from "./components/pages/ListBattlesPage";
import SeniorBattlesPage from "./components/pages/SeniorBattlesPage";
import ListSeniorBattlesPage from "./components/pages/ListSeniorBattlesPage";
import Error404Page from "./components/pages/Error404Page";
import HelpBattlesPage from "./components/pages/HelpBattlesPage";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import RefBattlesPage from "./components/pages/RefBattlesPage";
import RefAbsencePage from "./components/pages/RefAbsencePage";
import HelpSeniorBattlesPage from "./components/pages/HelpSeniorBattlesPage";
import CommisionaresPage from "./components/pages/CommisionaresPage";
import StatisticsPage from "./components/pages/StatisticsPage";
import MembershipPage from "./components/pages/MembershipPage";
import Login from "./components/pages/loginPage/Login";
import ListPage from "./components/pages/ListPage";
import SessionLogPage from "./components/pages/SessionLogPage";
import SessionLogPageAll from "./components/pages/SessionLogPageAll";

import CommisionareBattlesPage from "./components/pages/CommisionareBattlesPage";
if (process.env.NODE_ENV === "production") disableReactDevTools();

const App = () => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />

          {localStorage.getItem("loggedInUserTitle") === "Admin" ? (
            <>
              <Route path='/referees' element={<RefereesPage />} />
              <Route path='/commisionares' element={<CommisionaresPage />} />
              <Route path='/clubs' element={<ClubsPage />} />
              <Route path='/helpers' element={<HelpersPage />} />
              <Route path='/halls' element={<HallsPage />} />
              <Route path='/battles' element={<BattlesPage />} />
              <Route path='/draftbattle' element={<DraftBattlePage />} />
              <Route path='/seniorbattles' element={<SeniorBattlesPage />} />
              <Route path='/activebattles' element={<ActiveBattlesPage />} />
              <Route path='/listbattles' element={<ListBattlesPage />} />
              <Route path='/listseniorbattles' element={<ListSeniorBattlesPage />} />
              <Route path='/statistics' element={<StatisticsPage />} />
              <Route path='/membership' element={<MembershipPage />} />
              <Route path='/money' element={<MoneyPage />} />
              <Route path='/sessionlog' element={<SessionLogPage />} />
            </>
          ) : null}

          {localStorage.getItem("loggedInUserTitle") === "Pomoćni sudija" ? (
            <>
              <Route path='/helpactivebattles' element={<HelpActiveBattlesPage />} />
              <Route path='/helpbattles' element={<HelpBattlesPage />} />
              <Route path='/helpseniorbattles' element={<HelpSeniorBattlesPage />} />
              <Route path='/statshelp' element={<StatsHelpPage />} />
              <Route path='/moneyhelp' element={<MoneyHelpPage />} />
              <Route path='/helpabsence' element={<HelpAbsencePage />} />
              <Route path='/sessionlogall' element={<SessionLogPageAll />} />

            </>
          ) : null}

          {localStorage.getItem("loggedInUserTitle") === "Sudija" ? (
            <>
              <Route path='/refbattles' element={<RefBattlesPage />} />
              <Route path='/refactivebattles' element={<RefActiveBattlesPage />} />
              <Route path='/statsref' element={<StatsRefPage />} />
              <Route path='/moneyref' element={<MoneyRefPage />} />
              <Route path='/refabsence' element={<RefAbsencePage />} />
              <Route path='/sessionlogall' element={<SessionLogPageAll />} />

            </>
          ) : null}

          {localStorage.getItem("loggedInUserTitle") === "Komesar" ? (
            <>
              <Route path='/listpage' element={<ListPage />} />
              <Route path='/commisionarebattles' element={<CommisionareBattlesPage />} />
              <Route path='/sessionlogall' element={<SessionLogPageAll />} />
              <Route path='/money' element={<MoneyPage />} />
              <Route path='/statistics' element={<StatisticsPage />} />
              <Route path='/membership' element={<MembershipPage />} />
            </>
          ) : null}

          <Route path='*' element={<Error404Page />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
