import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";

import axios from "axios";

import "react-toastify/dist/ReactToastify.css";

const SideDrawerReferees = ({ onClose }) => {
  const [, setSudije] = useState([]);
  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [rating, setRating] = useState("1");
  const [price, setPrice] = useState("0");

  useEffect(() => {
    fetchReferees();
  }, []);

  const fetchReferees = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee`)
      .then((response) => {
        setSudije(response.data);
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      });
  };

  const handleCreateReferee = () => {
    if (!ime || !prezime) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const newReferee = {
      firstName: ime,
      lastName: prezime,
      refereeRating: Number(rating),
      price: Number(price),
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/referee`, newReferee)
      .then((response) => {
        toast.success("Uspješno ste kreirali sudiju");
        fetchReferees();
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while creating the referee");
        }
      });
  };

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.3 }}
        className='fixed z-20 right-0 top-0 h-screen bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg
  md:w-2/5 w-full lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5'
      >
        <div className='p-5'>
          <div className='flex flex-row justify-left items-center '>
            <img
              src='icons/leftArrow.png'
              alt='delete'
              className='w-6 h-6 cursor-pointer '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            ></img>
            <h1
              className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110  '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            >
              Back
            </h1>
          </div>

          <div className='my-4 mx-5'>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='firstName'>
              Ime:
            </label>
            <input
              id='firstName'
              placeholder='Unesite ime sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
          </div>
          <div className='my-4 mx-5'>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='lastName'>
              Prezime:
            </label>
            <input
              id='lastName'
              placeholder='Unesite prezime sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={prezime}
              onChange={(e) => setPrezime(e.target.value)}
            />
          </div>
          <div className='my-4 mx-5 text-left '>
            <label className='block mb-2 text-2xl font-bold text-gray-200' htmlFor='rating'>
              Rating:
            </label>
            <select
              id='refereeRating'
              className='border rounded px-2 py-1 w-full'
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              {[...Array(10)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='mt-2 mb-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='price'>
              Članarina:
            </label>
            <input
              id='price'
              placeholder='Unesite članarinu'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className='flex items-center justify-end mx-5'>
            <button
              className='border text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-10 rounded'
              onClick={() => {
                handleCreateReferee();
              }}
            >
              Kreiraj
            </button>
          </div>
        </div>
        <ToastContainer />
      </motion.div>
    </>
  );
};

export default SideDrawerReferees;
