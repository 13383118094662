const CreateButton = ({ buttonName, onClick }) => {
  return (
    <div className='flex pb-2 '>
      <div className='flex-1 '></div>
      <button
        className='flex-shrink-0 border-2 px-10 py-3 font-semibold border-1 border-black text-white rounded-md bg-gradient-to-r from-indigo-800 to-indigo-700 transform transition-transform hover:scale-105'
        onClick={onClick}
      >
        {buttonName}
      </button>
    </div>
  );
};

export default CreateButton;
