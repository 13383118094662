import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import DatePicker from "react-datepicker";

import TeamASelect from "../selectOptions/TeamASelect";
import TeamBSelect from "../selectOptions/TeamBSelect";
import SeniorCategorySelect from "../selectOptions/SeniorCategorySelect";
import LoadingSpinner from "../other/LoadingSpinner";
import ZapisnikSelect from "../selectOptions/ZapisnikSelect";
import SemaforSelect from "../selectOptions/SemaforSelect";
import TasterSelect from "../selectOptions/TasterSelect";
import StatisticSelect from "../selectOptions/StatisticSelect";
import StatisticTwoSelect from "../selectOptions/StatisticTwoSelect";
import HoverTooltip from "../other/HoverTooltip";

import "react-toastify/dist/ReactToastify.css";

const SeniorBattlesTable = ({ onDelete }) => {
  const [, setBattles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage] = useState(1);
  const [battlesPerPage] = useState(12);
  const [, setTotalBattlesCount] = useState(0);
  const [halls, setHalls] = useState([]);
  const [helpers, setHelpers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [, setCategories] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamA, setTeamA] = useState("");
  const [teamB, setTeamB] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [isTableVisible] = useState(true);
  const [selectedHallRating, setSelectedHallRating] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [, setSelectedHelper] = useState("");
  const [selectedData, setSelectedData] = useState({
    category: "",
    teamA: "",
    teamB: "",
    hall: "",
    date: new Date(),
    helpers: [],
  });

  const getUniqueCategories = (teamsData) => {
    const uniqueCategories = [];
    teamsData.forEach((team) => {
      if (!uniqueCategories.includes(team.category)) {
        uniqueCategories.push(team.category);
      }
    });
    return uniqueCategories;
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/hall`)
      .then((response) => {
        setHalls(response.data);
        setSelectedData((prevData) => ({
          ...prevData,
          hall: response.data[0],
        }));
      })
      .catch((error) => {
        console.error("Error fetching halls:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`)
      .then((response) => {
        const allHelpers = response.data.helpers;

        const availableHelpers = allHelpers.filter((helper) => {
          if (!helper.startDate || !helper.endDate) {
            return true;
          }
          return selectedDate < helper.startDate || selectedDate > helper.endDate;
        });

        setHelpers(availableHelpers);
      })
      .catch((error) => {
        console.error("Error fetching helpers:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/teams?page=1&limit=9999`)
      .then((response) => {
        const teamsData = response.data.teams;
        setTeams(teamsData);
        setFilteredTeams(teamsData);
        const uniqueCategories = getUniqueCategories(teamsData);
        setCategories(uniqueCategories);
      })
      .catch((error) => {
        console.error("Error fetching teams:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchBattles();
  }, []);

  useEffect(() => {
    fetchBattles();
  }, [currentPage, battlesPerPage, selectedData]);

  const fetchBattles = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle`)
      .then((response) => {
        setBattles(response.data);
        setTotalBattlesCount(response.data.length);
      })
      .catch((error) => {
        console.error("Error fetching battles:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    const hallSelect = document.getElementById("hallSelect");
    const selectedHall = hallSelect ? hallSelect.value : "";

    const selectedHelper1 = document.getElementById("helperSelect1")?.value || "";
    const selectedHelper2 = document.getElementById("helperSelect2")?.value || "";
    const selectedHelper3 = document.getElementById("helperSelect3")?.value || "";
    const selectedHelper4 = document.getElementById("helperSelect4")?.value || "";
    const selectedHelper5 = document.getElementById("helperSelect5")?.value || "";
    const selectedHelper6 = document.getElementById("helperSelect6")?.value || "";

    const requestData = {
      category: selectedCategory,
      teamA: teamA,
      teamB: teamB,
      hall: selectedHall,
      helpers: [
        selectedHelper1,
        selectedHelper2,
        selectedHelper3,
        selectedHelper4,
        selectedHelper5,
        selectedHelper6,
      ].filter(Boolean),
      date: selectedDate,
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle`, requestData)
      .then((response) => {
        toast.success("Uspješno ste kreirali utakmicu");
        setTimeout(() => {
          onDelete();
        }, 1500);
      })

      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDraft = () => {
    const hallId = document.getElementById("hallSelect")?.value || "";

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/draftSeniorBattle`, {
        category: selectedCategory,
        teamA: teamA,
        teamB: teamB,
        hall: hallId,
        date: selectedDate,
      })
      .then((response) => {
        toast.success("Uspješno ste kreirali utakmicu");
        setTimeout(() => {
          onDelete();
        }, 1500);
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
  };

  const handleDeleteTable = () => {
    onDelete();
  };

  const handleHelperSelect = (selectedHelperId) => {
    setSelectedHelper(selectedHelperId);
  };

  useEffect(() => {
    const teamsForSelectedCategory = teams.filter((team) => team.category === selectedCategory);
    setFilteredTeams(teamsForSelectedCategory);
    setTeamA("");
    setTeamB("");
  }, [selectedCategory, teams]);

  return (
    <div className='flex flex-col items-center justify-center '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          {isTableVisible && (
            <table className='text-left border-2 border-gray-900 mb-5'>
              {" "}
              <thead className=' py-3 text-sm border-2 border-gray-900'>
                {" "}
                <tr>
                  <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                    {" "}
                    <div class='flex flex-col items-center  '>
                      <span class='text-gray-900 text-center '>KATEGORIJA</span>
                      <td className='py-2 text-center text-gray-900 border-gray-900 px-1'>
                        <SeniorCategorySelect
                          selectedCategory={selectedCategory}
                          onCategoryChange={setSelectedCategory}
                        />
                      </td>
                    </div>
                  </th>
                  <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                    <div class='flex flex-col items-center '>
                      <span class='text-gray-900 text-center'>UTAKMICA</span>
                    </div>
                  </th>
                  <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                    {" "}
                    <div class='flex flex-col items-center'>
                      <td className='py-2 text-center font-bold text-gray-900 border-gray-900'>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          showTimeSelect
                          timeFormat='HH:mm'
                          timeIntervals={15}
                          className='text-center'
                          dateFormat='dd.MM.yyyy HH:mm'
                        />
                      </td>
                      <span className='text-gray-900 text-center'>
                        <select
                          className='w-44'
                          id='hallSelect'
                          onChange={(e) => {
                            const selectedHallId = e.target.value;
                            const selectedHall = halls.find((hall) => hall._id === selectedHallId);
                            setSelectedHallRating(selectedHall ? selectedHall.rating : 1);
                          }}
                        >
                          {halls.map((hall) => (
                            <option key={hall._id} value={hall._id}>
                              {hall.name}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </th>
                  <tr>
                    <th className='py-4 text-red-600 text-center border-r-2 bg-gray-200 border-gray-900 px-8 border-b-2'>
                      <TeamASelect filteredTeams={filteredTeams} teamA={teamA} onTeamAChange={setTeamA} />{" "}
                    </th>
                    <th className='py-4 text-red-600 text-center border-b-2 bg-gray-200 border-gray-900 px-8  border-r-2'>
                      <TeamBSelect filteredTeams={filteredTeams} teamB={teamB} onTeamBChange={setTeamB} />
                    </th>
                  </tr>
                  <tr className='bg-white px-2 text-center'>
                    <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Zapisnik</td>
                    <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  border-r-2'>
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                        name='Zapisnik'
                        helperselect='helperSelect1'
                      />
                    </td>
                  </tr>
                  <tr className='bg-white px-2 text-center'>
                    <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Pom. zapisnik</td>
                    <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  border-r-2'>
                      {" "}
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                        name='Pom. zapisnik'
                        helperselect='helperSelect6'
                      />
                    </td>
                  </tr>
                  <tr className='bg-white px-2 text-center'>
                    <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>Semafor</td>
                    <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  border-r-2 '>
                      <SemaforSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                        selectedHallRating={selectedHallRating}
                      />
                    </td>
                  </tr>
                  <tr className='bg-white px-2 text-center'>
                    <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>24/14</td>
                    <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  border-r-2 '>
                      {" "}
                      <TasterSelect helpers={helpers} battleDate={selectedDate} onSelectHelper={handleHelperSelect} />
                    </td>
                  </tr>
                  <tr className='bg-white px-2 text-center'>
                    <td className='border-r-2  border-gray-900 px-2 py-3 font-bold text-sm'>Statistika</td>
                    <td className=' border-gray-900 px-2 py-3 font-bold border-r-2'>
                      <StatisticSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                      />
                      <StatisticTwoSelect
                        helpers={helpers}
                        battleDate={selectedDate}
                        onSelectHelper={handleHelperSelect}
                      />
                    </td>
                  </tr>

                  <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                    <div class='flex flex-col items-center'>
                      <span className='text-gray-900 pb-2'>AKCIJE</span>
                      <div className=' flex items-center justify-center'>
                        <img
                          src='icons/draftIcon.svg'
                          alt='save'
                          className='w-5  cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                          onClick={handleDraft}
                        />
                        <img
                          src='icons/saveIcon.svg'
                          alt='save'
                          className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                          onClick={handleSave}
                        />

                        <img
                          src='icons/deleteIcon.png'
                          alt='delete'
                          className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                          onClick={handleDeleteTable}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          )}
        </React.Fragment>
      )}
      <ToastContainer />
    </div>
  );
};

export default SeniorBattlesTable;
