import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import "react-toastify/dist/ReactToastify.css";

const EditSideDrawerClubs = ({ selectedClub, onClose }) => {
  const [, setKlub] = useState([]);
  const [ime, setIme] = useState(selectedClub.name);
  const [kategorija, setKategorija] = useState(selectedClub.category);
  const [rating, setRating] = useState(String(selectedClub.rating));

  useEffect(() => {
    fetchClubs();
  }, []);

  const fetchClubs = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/teams`)
      .then((response) => {
        setKlub(response.data);
      })
      .catch((error) => {
        console.error("Error fetching clubs:", error);
      });
  };

  const handleUpdateClub = () => {
    if (!ime || !kategorija) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const updateClub = {
      name: ime,
      rating: Number(rating),
      category: kategorija,
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/teams/${selectedClub._id}`, updateClub)
      .then((response) => {
        toast.success("Uspješno ste kreirali klub");
        fetchClubs();
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      });
  };

  return (
    <div className='fixed z-20 w-full right-0 top-0 h-screen md:w-2/5 lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg'>
      <div className='p-5'>
        <div className='flex flex-row justify-left items-center '>
          <img src='icons/leftArrow.png' alt='delete' className='w-6 h-6 cursor-pointer ' onClick={onClose}></img>
          <h1
            className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110'
            onClick={onClose}
          >
            Back
          </h1>
        </div>

        <div className='my-4 mx-5'>
          <label className='block mb-2 text-xl text-left font-bold text-gray-200' htmlFor='clubName'>
            Naziv kluba:
          </label>
          <input
            id='clubName'
            placeholder='Unesite ime sudije'
            type='text'
            className='border rounded px-2 py-1 w-full'
            value={ime}
            onChange={(e) => setIme(e.target.value)}
          />
        </div>
        <div className='my-4 mx-5'>
          <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='clubCategory'>
            Kategorija:
          </label>
          <select
            id='clubCategory'
            className='border rounded px-2 py-1 w-full'
            value={kategorija}
            onChange={(e) => setKategorija(e.target.value)}
          >
            <option value=''>Odaberite kategoriju</option>
            <option value='PLBIHM'>PL BIH Muški</option>
            <option value='PLBIHZ'>PL BIH Žene</option>
            <option value='SenioriA1'>Seniori A1</option>
            <option value='SenioriA2'>Seniori A2</option>
            <option value='SenioriA1Z'>Seniori A1 Žene</option>
            <option value='Juniori'>Juniori</option>
            <option value='Kadeti'>Kadeti</option>
            <option value='KadetiB'>Kadeti B</option>
            <option value='Pioniri'>Pioniri</option>
            <option value='PioniriB'>Pioniri B</option>
            <option value='Minibasket'>Minibasket</option>
            <option value='Juniorke'>Juniorke</option>
            <option value='Kadetkinje'>Kadetkinje</option>
            <option value='Pionirke'>Pionirke</option>
            <option value='MinibasketZ'>Minibasket djevojčice</option>
            <option value='Ostalo'>Ostalo</option>
            <option value='Turnir'>Turnir</option>

            <option value='Reprezentacija'>Reprezentacija</option>
          </select>
        </div>
        <div className='my-4 mx-5 text-left '>
          <label className='block mb-2 text-xl font-bold text-gray-200' htmlFor='clubRating'>
            Rating:
          </label>
          <select
            id='clubRating'
            className='border rounded px-2 py-1 w-full'
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          >
            {[...Array(13)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className='mx-5 text-left text-red-600 font-semibold text-sm'>
          <p>11 - Seniori A1 Žene</p>
        </div>
        <div className='mx-5 text-left text-red-600 font-semibold text-sm'>
          <p>12 - Seniori A1, A2, PBIHŽ</p>
        </div>
        <div className='mx-5 text-left text-red-600 font-semibold text-sm'>
          <p>13 - Prvenstvo BiH muškarci</p>
        </div>
        <div className='mx-5 text-left text-red-600 font-semibold text-sm'>
          <p>14 - Reprezentacija</p>
        </div>
        <div className='flex items-center justify-end mx-5'>
          <button
            className='border-2 text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-xl'
            onClick={() => {
              handleUpdateClub();
            }}
          >
            Uredi
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditSideDrawerClubs;
