import React, { useState } from "react";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HoverTooltip from "../other/HoverTooltip";

const PoCommisionareBattlesTable = ({ battle, onDelete }) => {
  const [, setActiveBattles] = useState([]);
  const [isTableVisible, setTableVisible] = useState(true);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  const handleDelete = (battleId) => {
    setTableVisible(false);
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battleId}`)
      .then(() => {
        setActiveBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((battle) => battle._id !== battleId);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno obrisana");
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleDone = (battle) => {
    const finishedBattleData = {
      category: battle.category,
      matchNumber: battle.matchNumber,
      teamA: battle.teamA._id,
      teamB: battle.teamB._id,
      hall: battle.hall._id,
      date: battle.date,
      referees: battle.referees.map((referee) => referee._id),
      helpers: battle.helpers.map((helper) => helper._id),
      ostaloPrice: battle.ostaloPrice,
      ostaloHelperPrice: battle.ostaloHelperPrice,
      turnirPrice: battle.turnirPrice,
      turnirHelperPrice: battle.turnirHelperPrice,
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle`, finishedBattleData)
      .then((response) => {
        console.log("Battle data sent to the backend:", response.data);
        setActiveBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((b) => b._id !== battle._id);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno spašena!");
        setTableVisible(false);
      })
      .catch((error) => {
        console.error("Error sending battle data to the backend:", error);
        toast.error("Error sending battle data!");
      });

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battle._id}`)
      .then(() => {
        console.log("Battle deleted from the backend");
      })
      .catch((error) => {
        console.error("Error deleting battle from the backend:", error);
        toast.error("Error deleting battle!");
      });
  };

  const handleDraft = () => {
    const hallId = document.getElementById("hallSelect")?.value || "";

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/draftBattle`, {
        category: battle.category,
        matchNumber: battle.matchNumber,
        helpers: battle.helpers.map((helper) => helper._id),
        teamA: battle.teamA,
        teamB: battle.teamB,
        hall: battle.hall,
        date: battle.date,
      })
      .then((response) => {
        toast.success("Uspješno ste prebacili utakmicu u predstojeće utakmice");
        setTableVisible(false);
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/battles/${battle._id}`)
      .then(() => {
        console.log("Battle deleted from the backend");
      })
      .catch((error) => {
        console.error("Error deleting battle from the backend:", error);
        toast.error("Error deleting battle!");
      });
  };

  return (
    <div>
      {isTableVisible && (
        <table key={battle._id} className='w-full text-left border-2 border-gray-900 mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 px-6'>Kategorija</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Br.</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'> {battle.teamA?.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'> {battle.teamB?.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-200 text-center'>Mjesto,</span>
                  <span class='text-gray-200 text-center'>Dvorana</span>
                </div>
              </th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center'>
                  <span class='text-gray-200 text-center'>Datum,</span>
                  <span class='text-gray-200 text-center'>Vrijeme</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            <tr>
              <th className='py-1 text-black text-center border-r-2 border-gray-900'>{battle.category}</th>
              <td className='py-2 w-1 text-center border-r-2 border-gray-900 px-2 w-10'>{battle.matchNumber}</td>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900 '>
                <div className='flex flex-col items-center w-44'>
                  <span className='text-blue-400 text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                  {battle.helpers.map((helper) => (
                    <span key={helper._id} className='font-semibold'>
                      {" "}
                      {helper.firstName} {helper.lastName}
                    </span>
                  ))}
                </div>
              </th>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
                <div className='flex flex-col items-center w-44'>
                  <span className='text-red-600 text-center font-bold pb-1'>SUDIJE:</span>
                  {battle.referees.map((referee) => (
                    <span key={referee._id} className='font-semibold'>
                      {" "}
                      {referee.firstName} {referee.lastName}
                    </span>
                  ))}
                </div>
              </th>
              <td className='py-2 px-5 text-center border-r-2 border-gray-900 font-bold w-60'>
                {" "}
                {battle.hall ? battle.hall.name : ""}
              </td>
              <td className='py-2 text-center font-bold border-r-2 border-gray-900 w-40'>{formatDate(battle.date)}</td>
            </tr>
          </tbody>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default PoCommisionareBattlesTable;
