import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerReferees from "../sidedrawer/EditSideDrawerReferees";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";
import PasswordModal from "../modals/PasswordModal";
import "react-toastify/dist/ReactToastify.css";

const RefereesTable = ({ onClose, searchQuery }) => {
  const [sudije, setSudije] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReferee, setSelectedReferee] = useState(null);
  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRefereesPerPage, setSelectedRefereesPerPage] = useState(12);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const limit = 12;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/referee?page=${currentPage}&limit=${selectedRefereesPerPage}&firstName=${searchQuery}`
      )
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedRefereesPerPage, searchQuery]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  useEffect(() => {
  }, [sudije]);

  const handleDelete = (referee) => {
    setShowPasswordModal(true);
    setSelectedReferee(referee);
  };

  const handlePasswordSubmit = () => {
    if (password === "12344321") {
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const hasAbsenceDates = (referee) => {
    return referee.startDate && referee.endDate;
  };

  const handleDeleteConfirmation = (refereeId) => {
    toast.success("Uspješno ste obrisali sudiju");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/referee/${refereeId}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali sudiju");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedReferee(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedReferee(null);
  };

  const handleEditButtonClick = (selectedRefereeData) => {
    setIsEditSideDrawerOpen(true);

    const formattedStartDate = selectedRefereeData.startDate ? new Date(selectedRefereeData.startDate) : null;
    const formattedEndDate = selectedRefereeData.endDate ? new Date(selectedRefereeData.endDate) : null;

    setSelectedReferee({
      ...selectedRefereeData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleSort = () => {
    setIsSortingAsc(!isSortingAsc);

    const sortDirection = isSortingAsc ? "asc" : "desc";
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=${currentPage}&limit=${limit}&sort=${sortDirection}`)
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRefereesPerPageChange = (e) => {
    const newRefereesPerPage = parseInt(e.target.value, 10);
    setSelectedRefereesPerPage(newRefereesPerPage);
    setCurrentPage(1);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=${newRefereesPerPage}`)
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filteredSudije = sudije.filter((sudija) => {
    return sudija.firstName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <div className='flex flex-col items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10 '>
              <tr>
                <th className='py-5 px-5 text-gray-200'>Ime</th>
                <th className='py-5 px-5 text-gray-200'>Prezime</th>
                <th
                  className='py-5 px-5 text-gray-200 cursor-pointer items-center justify-center align-middle text-center '
                  onClick={handleSort}
                >
                  Rating{" "}
                  {isSortingAsc ? (
                    <span className='transform -rotate-90 inline-block text-gray-200 '>🡆</span>
                  ) : (
                    <span className='transform rotate-90 inline-block text-gray-200'>🡆</span>
                  )}
                </th>

                <th className='py-5 px-5 text-gray-200 text-center'>Datum početka odsustva</th>

                <th className='py-5 px-5 text-gray-200 text-center'>Datum završetka odsustva</th>
                <th className='py-5 px-5 text-gray-200 text-center'>Članarina</th>

                <th className='py-5 px-5 text-gray-200 text-center'>Akcije</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {filteredSudije.map((sudija) => (
                <tr key={sudija._id} className=' cursor-pointer border border-gray-200'>
                  {" "}
                  <td className='py-3 px-5'>{sudija.firstName}</td>
                  <td className='py-3 px-5'>{sudija.lastName}</td>
                  <td className='py-3 px-5 text-center'>{sudija.refereeRating}</td>
                  {hasAbsenceDates(sudija) ? (
                    <>
                      <td className='py-3 px-5 text-center'>{formatDate(sudija.startDate)}</td>
                      <td className='py-3 px-5 text-center'>{formatDate(sudija.endDate)}</td>
                    </>
                  ) : (
                    <>
                      <td className='py-3 px-5 text-center'>
                        <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                          ✔️
                        </span>
                      </td>
                      <td className='py-3 px-5 text-center'>
                        <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                          ✔️
                        </span>
                      </td>
                    </>
                  )}
                  <td className='py-3 px-5 text-center'>
                    {" "}
                    {sudija.price} KM /{" "}
                    {sudija.refereeRating < 6 ? "80 KM" : sudija.refereeRating === 10 ? "240 KM" : "120 KM"}
                  </td>
                  <td className='py-3 text-center flex flex-row justify-center'>
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/editIcon.png'
                        alt='edit'
                        onClick={() => handleEditButtonClick(sudija)}
                        className='w-6 cursor-pointer'
                      />
                    </div>

                    {isEditSideDrawerOpen && (
                      <EditSideDrawerReferees selectedReferee={selectedReferee} onClose={handleEditSideDrawerClose} />
                    )}
                    <div className='icon-container transform transition-transform hover:scale-125'>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        onClick={() => handleDelete(sudija)}
                        className='w-6 cursor-pointer'
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='flex justify-between w-full'>
            <div className='my-5 px-5'>
              <div className='mr-2 mb-1'>Redovi:</div>
              <select
                className='px-2 py-1 border rounded-md'
                value={selectedRefereesPerPage}
                onChange={handleRefereesPerPageChange}
              >
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={36}>36</option>
              </select>
            </div>
            <div className='my-5 px-5 flex justify-center items-center'>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${selectedReferee?.firstName} ${selectedReferee?.lastName}`}
          onDelete={() => handleDeleteConfirmation(selectedReferee._id)}
          onCancel={handleDeleteCancel}
          type='sudiju'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default RefereesTable;
