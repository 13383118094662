import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const StatsRefTable = () => {
  const [categoryStats, setCategoryStats] = useState({});
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const loggedInUsername = localStorage.getItem("loggedInUsername");

  useEffect(() => {
    const loggedInUserId = localStorage.getItem("loggedInUserId");

    if (loggedInUserId) {
      const axiosRequests = [
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle/helper/categorystats/${loggedInUserId}`),
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/helper/categorystats/${loggedInUserId}`),
      ];

      Promise.all(axiosRequests)
        .then((responses) => {
          const combinedData = responses.reduce(
            (accumulator, response) => ({
              ...accumulator,
              ...response.data,
            }),
            {}
          );

          setCategoryStats(combinedData);
        })
        .catch((error) => {
          console.error("Greška prilikom dohvata statistike sudije:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const sum = Object.values(categoryStats).reduce((acc, val) => acc + val, 0);
    setTotal(sum);
  }, [categoryStats]);

  return (
    <div className='flex flex-col items-center justify-cente '>
      <React.Fragment>
        {isLoading ? (
          <div className='flex justify-center items-center h-80'>
            <LoadingSpinner />
          </div>
        ) : (
          <table className={`w-full md:w-4/5 lg:w-3/4 xl:w-3/5 text-left border-2 border-gray-900 mb-5`}>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  SUDIJA
                </th>
                <th className='py-2 text-gray-200 text-center text-lg border-r-2 bg-gradient-to-r from-indigo-800 to-indigo-800 border-gray-900'>
                  {loggedInUsername}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Reprezentacija BiH
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Reprezentacija || 0}{" "}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  PLBIHM
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.PLBIHM || 0}{" "}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  PLBIHZ
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.PLBIHZ || 0}{" "}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Seniori A1
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.SenioriA1 || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Seniori A2
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.SenioriA2 || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Seniori A1Z
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.SenioriA1Z || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Juniori
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Juniori || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Juniorke
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Juniorke || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Kadeti{" "}
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Kadeti || 0 + (categoryStats.KadetiB || 0)}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Kadetkinje
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Kadetkinje || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Pioniri
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Pioniri || 0 + categoryStats.Pioniri || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Pionirke{" "}
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Pionirke || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Minibasket{" "}
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Minibasket || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  MinibasketZ
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.MinibasketZ || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  Ostalo{" "}
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Ostalo || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                  Turnir{" "}
                </th>
                <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                  {categoryStats.Turnir || 0}
                </th>
              </tr>
            </tbody>
            <tbody className=' py-2 text-lg border-2 border-gray-900'>
              <tr>
                <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                  UKUPNO
                </th>
                <th className='py-2 text-gray-200 text-center text-xl font-extrabold bg-gradient-to-r from-indigo-600 to-indigo-600 border-r-2 border-gray-900'>
                  {total || 0}
                </th>
              </tr>
            </tbody>
          </table>
        )}
      </React.Fragment>
      <ToastContainer />
    </div>
  );
};

export default StatsRefTable;
