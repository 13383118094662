import React from "react";

const TeamBSelect = ({ filteredTeams, teamB, onTeamBChange }) => {
  return (
    <React.Fragment>
      <select className='w-36' value={teamB} onChange={(e) => onTeamBChange(e.target.value)}>
        <option value=''>Select Team B</option>
        {filteredTeams.map((team) => (
          <option key={team._id} value={team._id}>
            {team.name}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default TeamBSelect;
