import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const MoneyHelpTable = () => {
  const [helperBattleData, setHelperBattleData] = useState([]);
  const [helperSeniorBattleData, setHelperSeniorBattleData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchDataForHelper = async (helperId) => {
    try {
      const responseBattle = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/finishedBattle/helper/debt/${helperId}`
      );

      const responseSeniorBattle = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/helper/debt/${helperId}`
      );

      setHelperBattleData(responseBattle.data);
      setHelperSeniorBattleData(responseSeniorBattle.data);
    } catch (error) {
      console.error("Greška prilikom dohvatanja podataka:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loggedInUsername = localStorage.getItem("loggedInUsername");

  useEffect(() => {
    const loggedInUserId = localStorage.getItem("loggedInUserId");
    if (loggedInUserId) {
      fetchDataForHelper(loggedInUserId);
    }
  }, []);

  return (
    <div className='flex flex-col justify-center '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='flex justify-center items-center'></div>
      )}
      <div className='flex justify-center'>
        <table className={`w-full md:w-4/5 lg:w-3/4 xl:w-3/5 text-left border-2 border-gray-900 mb-5`}>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                POMOĆNI SUDIJA
              </th>
              <th className='py-2 text-gray-200 text-center text-lg border-r-2 bg-gradient-to-r from-indigo-800 to-indigo-800 border-gray-900'>
                {loggedInUsername}
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Reprezentacija BiH
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperReprezentacija} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                PLBIHM
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperPLBIHM} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                PLBIHZ
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperPLBIHZ} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                SenioriA1
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperSenioriA1} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                SenioriA2
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperSenioriA2} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                SenioriA1Z
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperSeniorBattleData.totalDebtHelperSenioriA1Z} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Juniori
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperJuniori} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Juniorke
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperJuniorke} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Kadeti
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperKadeti + helperBattleData.totalDebtHelperKadetiB} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Kadetkinje
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperKadetkinje} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Pioniri
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperPioniri + helperBattleData.totalDebtHelperPioniriB} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Pionirke
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperPionirke} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Minibasket
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperMinibasket} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                MinibasketŽ
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperMinibasketZ} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r  from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                Ostalo
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperOstalo} KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                Turnir
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {helperBattleData.totalDebtHelperTurnir} KM
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='flex justify-center'>
        <table className={`w-10/12 md:w-3/5 lg:w-2/4 xl:w-4/12 text-left border-2 border-gray-900 mb-5`}>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO ISPLAĆENO
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {Number(helperBattleData.totalPaidDebtHelperUkupno) +
                  Number(helperSeniorBattleData.totalPaidDebtHelperUkupno)}{" "}
                KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-600 to-indigo-600 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO NEPLAĆENO
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {Number(helperBattleData.totalDebtHelperUkupno) + Number(helperSeniorBattleData.totalDebtHelperUkupno)}{" "}
                KM
              </th>
            </tr>
          </tbody>
          <tbody className=' py-2 text-lg border-2 border-gray-900'>
            <tr>
              <th className='py-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO
              </th>
              <th className='py-2 text-gray-900 text-center border-r-2 px-1 border-gray-900'>
                {Number(helperBattleData.totalPaidUnPaid) + Number(helperSeniorBattleData.totalPaidUnPaid)} KM
              </th>
            </tr>
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
};

export default MoneyHelpTable;
