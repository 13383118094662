import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ListTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [commisionares, setCommisionares] = useState([]);
  const [sudije, setSudije] = useState([]);
  const [helperi, setHelperi] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commisionare`)
      .then((response) => {
        setCommisionares(response.data.commisionares);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee`)
      .then((response) => {
        setSudije(response.data.referees);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers`)
      .then((response) => {
        setHelperi(response.data.helpers);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data");
        setIsLoading(false);
      });
  }, []);

  const getRatingCommisionare = (rating) => {
    if (rating === 2) {
      return "PLBIH";
    } else if (rating === 1) {
      return "A1/A2 liga";
    } else {
      return "N/A";
    }
  };

  const getRatingReferee = (rating) => {
    if (rating === 10) {
      return "PLBIH";
    } else if (rating > 5 && rating <= 9) {
      return "A1/A2 liga";
    } else if (rating >= 1 && rating < 6) {
      return "Omladinska Liga";
    } else {
      return "N/A";
    }
  };

  return (
    <div className={` ${isMobile ? "" : "flex text-center justify-left"} justify-left  `}>
      <div className='w-4/12 pt-8  justify-center px-2'>
        <h2 className='text-xl font-bold mb-3'>LISTA SUDIJA</h2>

        <table className='text-left w-full '>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10'>
            <tr>
              <th className='py-5 px-3 text-gray-200'>Br.</th>
              <th className='py-5 px-3 text-gray-200'>Ime</th>
              <th className='py-5 px-3 text-gray-200'>Prezime</th>
              <th className='py-5 px-3 text-gray-200 cursor-pointer items-center justify-center align-middle text-center '>
                Liga
              </th>
            </tr>
          </thead>
          <tbody className='p-5 px-5'>
            {sudije
              .filter((referee) => referee.firstName !== "Admin")
              .map((referee, index) => (
                <tr key={index} className=' cursor-pointer border border-gray-200 '>
                  <td className='py-1 px-3 font-semibold'>{index + 1}.</td>
                  <td className='py-1 px-3'>{referee.firstName}</td>
                  <td className='py-1 px-3 text-left'>{referee.lastName}</td>
                  <td className='py-1 px-3 text-center'>{getRatingReferee(referee.refereeRating)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className='w-4/12  pt-8 px-2 '>
        <h2 className='text-xl font-bold mb-3'>LISTA KOMESARA</h2>

        <table className='text-left w-full '>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10'>
            <tr>
              <th className='py-5 px-3 text-gray-200'>Br.</th>
              <th className='py-5 px-3 text-gray-200'>Ime</th>
              <th className='py-5 px-3 text-gray-200'>Prezime</th>
              <th className='py-5 px-3 text-gray-200 cursor-pointer items-center justify-center align-middle text-center '>
                Liga
              </th>
            </tr>
          </thead>
          <tbody className='p-5 px-5'>
            {commisionares.map((commisionare, index) => (
              <tr key={index} className=' cursor-pointer border border-gray-200 '>
                <td className='py-1 px-3 font-semibold'>{index + 1}.</td>
                <td className='py-1 px-3 '>{commisionare.firstName}</td>
                <td className='py-1 px-3 text-left'>{commisionare.lastName}</td>
                <td className='py-1 px-3 text-center'>{getRatingCommisionare(commisionare.commisionareRating)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='w-4/12 pt-8 px-2 '>
        <h2 className='text-xl text-center font-bold mb-3'>LISTA POMOĆNIH SUDIJA</h2>

        <table className='text-left w-full'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-5 py-10'>
              <th className='py-5 px-3 text-gray-200'>Br.</th>
              <th className='py-3 px-3 text-gray-200'>Ime</th>
              <th className='py-3 px-3 text-gray-200'>Prezime</th>
          </thead>
          <tbody className='p-5 px-5'>
            {helperi
              .filter((helper) => helper.firstName !== "/")
              .map((helper, index) => (
                <tr key={index} className=' cursor-pointer border border-gray-200 '>
                  <td className='py-1 px-3 font-semibold'>{index + 1}.</td>
                  <td className='py-1 px-3'>{helper.firstName}</td>
                  <td className='py-1 px-3 text-left'>{helper.lastName}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListTable;
