import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import Switcher from "../other/Switcher";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PasswordModal from "../modals/PasswordModal";

import "react-toastify/dist/ReactToastify.css";

const PoSeniorListTable = ({ seniorBattle }) => {
  const [isPaid, setIsPaid] = useState(seniorBattle.isPaid);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [, setSelectedBattle] = useState(null);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  const handlePaidStatusChange = () => {
    const updatedIsPaid = !isPaid;
    setIsPaid(updatedIsPaid);

    const url = `${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/setpaid/${seniorBattle._id}`;

    axios
      .put(url, { isPaid: updatedIsPaid })
      .then((response) => {
        console.log("Paid status updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating paid status:", error);
      });
  };

  const handleDeleteConfirmation = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/${seniorBattle._id}`)
      .then(() => {
        toast.success("Utakmica je uspješno obrisana");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleDelete = (seniorBattleid) => {
    setShowPasswordModal(true);
    setSelectedBattle(seniorBattleid);
  };

  const handlePasswordSubmit = () => {
    // Proverite da li je uneta ispravna lozinka
    if (password === "12344321") {
      // Postavite odgovarajuću lozinku
      setShowPasswordModal(false);
      setShowDeleteModal(true);
    } else {
      // Prikazati poruku o neispravnoj lozinci
      toast.error("Neispravna lozinka. Pokušajte ponovo.");
    }
  };

  const handlePasswordCancel = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedBattle(null);
  };

  return (
    <div className='flex'>
      <>
        <table key={seniorBattle._id} className='text-left border-2 border-gray-900 mb-5 w-full'>
          <thead className=' py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900 w-32 relative'>
                <div className='flex flex-col items-center'>
                  <span className='text-gray-900 text-center '>UTAKMICA</span>
                  <img
                    src='icons/deleteIcon.png'
                    alt='delete'
                    className='absolute z-10 top-0 left-0 w-4 mr-1 mt-1 cursor-pointer h-4'
                    onClick={() => handleDelete(seniorBattle._id)}
                  />
                </div>
              </th>

              <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900 w-36'>
                <div class='flex flex-col items-center'>
                  <td className='py-2 text-center font-bold text-gray-900 border-gray-900'>
                    {seniorBattle && seniorBattle.date ? formatDate(seniorBattle.date) : ""}
                  </td>
                  <span class='text-gray-900 text-center'>
                    {" "}
                    {seniorBattle && seniorBattle.hall ? seniorBattle.hall.name : ""}
                  </span>
                </div>
              </th>

              <tr>
                <th className='py-2 h-14 justify-center items-center  text-red-600 text-center border-r-2 bg-gray-200 border-gray-900 px-2 border-b-2 w-48'>
                  {seniorBattle && seniorBattle.teamA ? seniorBattle.teamA.name : ""}
                </th>
                <th className='py-2 h-14 justify-center items-center  text-red-600 text-center bg-gray-200 border-gray-900 px-2 border-b-2 w-48'>
                  {seniorBattle && seniorBattle.teamB ? seniorBattle.teamB.name : ""}
                </th>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Zapisnik</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                  {seniorBattle?.helpers && seniorBattle.helpers[0]
                    ? `${seniorBattle.helpers[0]?.firstName} ${seniorBattle.helpers[0]?.lastName}`
                    : "No Helper"}
                </td>
              </tr>
              {seniorBattle.helpers[5] && (
                <tr className='bg-white px-2 text-center'>
                  <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Pom. zapisnik</td>
                  <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                    {seniorBattle?.helpers && seniorBattle.helpers[5]
                      ? `${seniorBattle.helpers[5]?.firstName} ${seniorBattle.helpers[5]?.lastName}`
                      : "No Helper"}
                  </td>
                </tr>
              )}
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>Semafor</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-bold  '>
                  {seniorBattle.helpers[1]
                    ? `${seniorBattle.helpers[1].firstName} ${seniorBattle.helpers[1].lastName}`
                    : "No Helper"}
                </td>{" "}
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td
                  className={`border-r-2 ${
                    !seniorBattle.helpers[3] ? "" : "border-b-2"
                  } border-gray-900 px-2 py-3 font-bold   text-sm`}
                >
                  24/14
                </td>
                <td
                  className={`
                ${!seniorBattle.helpers[3] ? "" : "border-b-2"} border-gray-900 px-2 py-3 font-bold  `}
                >
                  {seniorBattle.helpers[2]
                    ? `${seniorBattle.helpers[2].firstName} ${seniorBattle.helpers[2].lastName}`
                    : "No Helper"}
                </td>{" "}
              </tr>

              {seniorBattle.helpers[3] && (
                <tr className='bg-white px-2 text-center'>
                  <td className='border-r-2  border-gray-900 px-2 py-3 font-bold text-sm'>Statistika</td>
                  <td className=' border-gray-900 px-2 py-3 font-bold  '>
                    {seniorBattle.helpers[3] && seniorBattle.helpers[3].firstName !== "/"
                      ? `${seniorBattle.helpers[3].firstName} ${seniorBattle.helpers[3].lastName}, `
                      : ""}
                    {seniorBattle.helpers[4] && seniorBattle.helpers[4].firstName !== "/"
                      ? `${seniorBattle.helpers[4].firstName} ${seniorBattle.helpers[4].lastName}`
                      : ""}
                  </td>
                </tr>
              )}
            </tr>
          </thead>
        </table>

        <Switcher isPaid={isPaid} setIsPaid={handlePaidStatusChange} />
      </>
      {showPasswordModal && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          onPasswordSubmit={handlePasswordSubmit}
          onCancel={handlePasswordCancel}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${seniorBattle.category} | ${seniorBattle.teamA.name} - ${seniorBattle.teamB.name}`}
          onDelete={() => handleDeleteConfirmation(seniorBattle._id)}
          onCancel={handleDeleteCancel}
          type='utakmicu'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default PoSeniorListTable;
