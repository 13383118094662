import React, { useEffect, useState } from "react";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RefereeSelect from "../selectOptions/RefereeSelect";
import ZapisnikSelect from "../selectOptions/ZapisnikSelect";
import SemaforSelect from "../selectOptions/SemaforSelect";
import HoverTooltip from "../other/HoverTooltip";
import TasterSelect from "../selectOptions/TasterSelect";
import StatisticSelect from "../selectOptions/StatisticSelect";

const PoDraftBattleTable = ({ draftBattle }) => {
  const [isTableVisible, setTableVisible] = useState(true);
  const [, setSelectedReferee] = useState("");
  const [, setSelectedHelper] = useState("");
  const [helpers, setHelpers] = useState([]);
  const [referees, setReferees] = useState([]);
  const [selectedHallRating, setSelectedHallRating] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRefereesForSave, setSelectedRefereesForSave] = useState([]);
  const [selectedHelpersForSave, setSelectedHelpersForSave] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const refereesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=9999`);
        setReferees(refereesResponse.data.referees);
        const helpersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`);
        setHelpers(helpersResponse.data.helpers);

        console.error("Error fetching data:");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePut = () => {
    const selectedReferee1 = document.getElementById("refereeSelect1")?.value || "";
    const selectedReferee2 = document.getElementById("refereeSelect2")?.value || "";
    const selectedReferee3 = document.getElementById("refereeSelect3")?.value || "";
    const selectedHelper1 = document.getElementById("helperSelect1")?.value || "";
    const selectedHelper2 = document.getElementById("helperSelect2")?.value || "";
    const selectedHelper3 = document.getElementById("helperSelect3")?.value || "";
    const selectedHelper4 = document.getElementById("helperSelect4")?.value || "";

    const updatedData = {
      referees: [selectedReferee1, selectedReferee2, selectedReferee3].filter(Boolean),
      helpers: [selectedHelper1, selectedHelper2, selectedHelper3, selectedHelper4].filter(Boolean),
    };

    setSelectedRefereesForSave(updatedData.referees);
    setSelectedHelpersForSave(updatedData.helpers);

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/draftBattle/${draftBattle._id}`, updatedData)
      .then((response) => {
        toast.success("Uspješno ste ažurirali podatke");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Greška prilikom ažuriranja podataka", error);
      });
  };

  const handleEdit = () => {
    const updatedData = {
      referees: [],
      helpers: [],
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/draftBattle/${draftBattle._id}`, updatedData)
      .then((response) => {
        toast.success("Uspješno ste ažurirali podatke");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Greška prilikom ažuriranja podataka", error);
      });
  };

  const handleSave = async () => {
    const teamA = draftBattle.teamA._id.toString();
    const teamB = draftBattle.teamB._id.toString();
    const selectedReferee1 = document.getElementById("refereeSelect1")?.value || "";
    const selectedReferee2 = document.getElementById("refereeSelect2")?.value || "";
    const selectedReferee3 = document.getElementById("refereeSelect3")?.value || "";
    const hall = draftBattle.hall._id.toString();

    const updatedDraftBattle = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/draftBattle/${draftBattle._id}`);
    const savedData = {
      category: updatedDraftBattle.data.category,
      matchNumber: updatedDraftBattle.data.matchNumber,
      teamA: teamA,
      teamB: teamB,
      hall: hall,
      referees: [selectedReferee1, selectedReferee2, selectedReferee3].filter(Boolean),
      helpers: updatedDraftBattle.data.helpers || selectedHelpersForSave,
      date: updatedDraftBattle.data.date,
      ostaloPrice: updatedDraftBattle.data.ostaloPrice,
      ostaloHelperPrice: updatedDraftBattle.data.ostaloHelperPrice,
      turnirPrice: updatedDraftBattle.data.turnirPrice,
      turnirHelperPrice: updatedDraftBattle.data.turnirHelperPrice,
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/battles`, savedData)
      .then((response) => {
        toast.success("Uspješno ste prebacili utakmicu u aktivne utakmice");
        setTableVisible(false);
        axios
          .delete(`${process.env.REACT_APP_API_BASE_URL}/draftBattle/${draftBattle._id}`)
          .then(() => {
            console.log("Battle deleted from the backend");
          })
          .catch((error) => {
            console.error("Error deleting battle from the backend:", error);
            toast.error("Error deleting battle!");
          });
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/draftBattle/${draftBattle._id}`)
      .then(() => {
        toast.success("Utakmica je uspješno obrisana");
        setTableVisible(false);
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleRefereeSelect = (selectedRefereeId) => {
    setSelectedReferee(selectedRefereeId);
  };
  const handleHelperSelect = (selectedHelperId) => {
    setSelectedHelper(selectedHelperId);
  };

  const filteredHelpers = helpers.filter((helper) => {
    if (draftBattle.hall.rating === 3) {
      return helper.helperRating === 4;
    } else if (draftBattle.hall.rating === 2) {
      return helper.helperRating === 3 || helper.helperRating === 4;
    } else if (draftBattle.hall.rating === 1) {
      return helper.helperRating === 2 || helper.helperRating === 3 || helper.helperRating === 4;
    }
    return true;
  });

  return (
    <div>
      {isTableVisible && (
        <table className='w-full text-left border-2 border-gray-900 mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 px-6'>Kategorija</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Br.</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>{draftBattle.teamA.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>{draftBattle.teamB.name}</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-200 text-center'>Mjesto,</span>
                  <span class='text-gray-200 text-center'>Dvorana</span>
                </div>
              </th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center'>
                  <span class='text-gray-200 text-center'>Datum,</span>
                  <span class='text-gray-200 text-center'>Vrijeme</span>
                </div>
              </th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 px-6'>Akcije</th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            <tr>
              <th className='py-1 text-black text-center border-r-2 border-gray-900'>
                {draftBattle.category === "KadetiB"
                  ? "Kadeti B"
                  : draftBattle.category === "PioniriB"
                  ? "Pioniri B"
                  : draftBattle.category}
              </th>
              <td className='py-2 w-1 text-center border-r-2 border-gray-900 px-2 w-10'>{draftBattle.matchNumber}</td>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900 '>
                <div className='flex flex-col items-center w-44'>
                  <span className='text-blue-400 text-center font-bold pb-1'>POMOĆNE SUDIJE:</span>
                  {draftBattle.helpers && draftBattle.helpers.length > 0 ? (
                    draftBattle.helpers.map((helper) => (
                      <span key={helper._id} className='font-semibold'>
                        {" "}
                        {helper.firstName} {helper.lastName}
                      </span>
                    ))
                  ) : (
                    <>
                      {" "}
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={draftBattle.date}
                        onSelectHelper={handleHelperSelect}
                        name='Zapisnik'
                        helperselect='helperSelect1'
                      />
                      <SemaforSelect
                        helpers={filteredHelpers}
                        battleDate={draftBattle.date}
                        onSelectHelper={handleHelperSelect}
                        selectedHallRating={selectedHallRating}
                      />
                      {draftBattle.category === "Turnir" && (
                        <>
                          <TasterSelect
                            helpers={helpers}
                            battleDate={draftBattle.date}
                            onSelectHelper={handleHelperSelect}
                          />

                          <span className='text-blue-400 text-center font-bold pb-1'>STATISTIKA:</span>
                          <StatisticSelect
                            helpers={helpers}
                            battleDate={draftBattle.date}
                            onSelectHelper={handleHelperSelect}
                          />
                        </>
                      )}
                    </>
                  )}{" "}
                </div>
              </th>
              <th className='py-2 px-5 text-black text-center border-r-2 border-gray-900'>
                <div className='flex flex-col items-center w-44'>
                  <span className='text-red-600 text-center font-bold pb-1'>SUDIJE:</span>
                  <div className='flex flex-col items-center'>
                    <RefereeSelect
                      referees={referees}
                      onSelectReferee={handleRefereeSelect}
                      refereeNumber='Glavni sudija'
                      battleDate={draftBattle.date}
                      refereeSelect='refereeSelect1'
                    />

                    <RefereeSelect
                      referees={referees}
                      onSelectReferee={handleRefereeSelect}
                      refereeNumber='Prvi sudija'
                      battleDate={draftBattle.date}
                      refereeSelect='refereeSelect2'
                    />
                  </div>
                  <RefereeSelect
                    referees={referees}
                    onSelectReferee={handleRefereeSelect}
                    refereeNumber='Drugi sudija'
                    battleDate={draftBattle.date}
                    refereeSelect='refereeSelect3'
                  />
                </div>
              </th>
              <td className='py-2 px-5 text-center border-r-2 border-gray-900 font-bold w-60'>
                {draftBattle.hall.name}
              </td>
              <td className='py-2 text-center font-bold border-r-2 border-gray-900 w-40'>
                {formatDate(draftBattle.date)}
              </td>
              <td className='py-2 text-center items-center'>
                <div className=' flex items-center justify-center'>
                  <HoverTooltip text='AŽMAN' translate='-translate-y-7 -translate-x-7'>
                    <img
                      src='icons/saveGreenIcon.svg'
                      alt='greenSave'
                      className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                      onClick={(event) => {
                        event.stopPropagation();
                        handlePut();
                      }}
                    />
                  </HoverTooltip>

                  <HoverTooltip text='KASUMOVIĆ' translate='-translate-y-7 -translate-x-10'>
                    <img
                      src='icons/saveIcon.svg'
                      alt='save'
                      className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSave();
                      }}
                    />
                  </HoverTooltip>

                  <img
                    src='icons/deleteIcon.png'
                    alt='delete'
                    className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete();
                    }}
                  />

                  <HoverTooltip text='Edit' translate='-translate-y-7 -translate-x-4'>
                    <img
                      src='icons/editIcon.png'
                      alt='delete'
                      className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                      onClick={(event) => {
                        event.stopPropagation();
                        handleEdit();
                      }}
                    />
                  </HoverTooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default PoDraftBattleTable;
