const LogoBanner = () => {
  return (
    <div
      className='logo-banner flex h-full w-full items-center justify-center '
      style={{
        backgroundImage: 'url("/images/mobile-background.jpg")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className='h-1/2 w-full bg-cover bg-center'
        style={{
          backgroundImage: 'url("/images/logouksk.svg")',
          width: "390px",
          height: "420px",
        }}
      />
    </div>
  );
};

export default LogoBanner;
