import React, { useState } from "react";

import MainLayout from "../maincontent/MainLayout.jsx";
import CommisionareBattlesTable from "../tables/CommisionareBattlesTable.jsx";
import NavBar from "../navbars/NavBar.jsx";
import CommisionareSeniorBattlesTable from "../tables/CommisionareSeniorBattlesTable.jsx";

const CommisionareBattlesPage = () => {
  const [commisionarePage, setCommisionarePage] = useState(1);

  const navLabels = [`Omladinska liga`, "Stolovi - Seniori"];

  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>AKTIVNE UTAKMICE</div>
        <div className='mt-5 flex flex-col'>
          <div className='mb-2 flex flex-wrap justify-center gap-4 '>
            <NavBar navLabels={navLabels} handlePageSelect={(page) => setCommisionarePage(page)} />
          </div>
          <>
            {commisionarePage === 1 && <CommisionareBattlesTable />}
            {commisionarePage === 2 && <CommisionareSeniorBattlesTable />}
          </>
        </div>
      </div>
    </MainLayout>
  );
};

export default CommisionareBattlesPage;
