const SeniorCategorySelect = ({ selectedCategory, onCategoryChange }) => {
  return (
    <select
      id='clubSeniorCategory'
      className='border rounded px-2 py-1 w-28 text-black'
      value={selectedCategory}
      onChange={(e) => onCategoryChange(e.target.value)}
    >
      <option value=''>Kategorija</option>
      <option value='PLBIHM'>PL BIH Muški</option>
      <option value='PLBIHZ'>PL BIH Žene</option>
      <option value='SenioriA1'>Seniori A1</option>
      <option value='SenioriA2'>Seniori A2</option>
      <option value='SenioriA1Z'>Seniori A1 Žene</option>
      <option value='Reprezentacija'>Reprezentacija BiH</option>
    </select>
  );
};

export default SeniorCategorySelect;
