import React, { useState, useEffect } from "react";

import MainLayout from "../maincontent/MainLayout";
import ClubsTable from "../tables/ClubsTable";
import SideDrawerClubs from "../sidedrawer/SideDrawerClubs";
import CreateButton from "../other/CreateButton";
import EditSideDrawerClubs from "../sidedrawer/EditSideDrawerClubs";
import SortNavbar from "../navbars/SortNavbar";
import ResponsiveClubsTable from "../tables/ResponsiveClubsTable";
import ActiveCategorySelect from "../selectOptions/ActiveCategorySelect";

const ClubsPage = () => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCreateButtonClick = () => {
    setIsSideDrawerOpen(true);
  };

  const handleSideDrawerClose = () => {
    setIsSideDrawerOpen(false);
  };

  const handleEditButtonClick = (selectedClubData) => {
    setIsEditSideDrawerOpen(true);
    setSelectedClub(selectedClubData);
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const categories = [
    "Reprezentacija",
    "PLBIHM",
    "PLBIHZ",
    "SenioriA1",
    "SenioriA2",
    "SenioriA1Z",
    "Juniori",
    "Kadeti",
    "KadetiB",
    "Pioniri",
    "PioniriB",
    "Minibasket",
    "Juniorke",
    "Kadetkinje",
    "Pionirke",
    "MinibasketZ",
    "Ostalo",
    "Turnir",
  ];

  return (
    <MainLayout>
      <div className={`flex ${isMobile ? "flex-col " : ""} pt-20 px-9 justify-between text-center items-center`}>
        <div className={` ${isMobile ? "pb-3 text-4xl " : ""}text-center text-3xl font-bold `}>LISTA KLUBOVA</div>

        <div className='justify-end'>
          <CreateButton buttonName='Kreiraj klub' onClick={handleCreateButtonClick} />
        </div>
      </div>
      <div className='flex items-center justify-start mb-4 ml-10'>
        <ActiveCategorySelect
          onSelect={handleCategorySelect}
          categories={categories}
          className='border border-gray-700 flex rounded px-2 py-1 max-w-full text-black'
        />
      </div>
      {isSideDrawerOpen && <SideDrawerClubs onClose={handleSideDrawerClose} />}
      <div className='flex w-full justify-center items-center'>
        <div className='w-full px-8 pb-8 rounded-xl '>
          {isMobile ? (
            <ResponsiveClubsTable
              selectedCategory={selectedCategory}
              onEditButtonClick={handleEditButtonClick}
              isEditSideDrawerOpen={isEditSideDrawerOpen}
            />
          ) : (
            <ClubsTable
              selectedCategory={selectedCategory}
              onEditButtonClick={handleEditButtonClick}
              isEditSideDrawerOpen={isEditSideDrawerOpen}
            />
          )}

          {isEditSideDrawerOpen && (
            <EditSideDrawerClubs selectedClub={selectedClub} onClose={handleEditSideDrawerClose} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ClubsPage;
