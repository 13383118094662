import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import "react-toastify/dist/ReactToastify.css";

const PoCommisionareSeniorTable = ({ seniorBattle }) => {
  const [, setSeniorBattles] = useState([]);
  const [isTableVisible, setTableVisible] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle`)
      .then((response) => {
        setSeniorBattles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}. ${hour}:${minutes}h`;
  };

  const handleDelete = (seniorBattleId) => {
    setTableVisible(false);
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle/${seniorBattleId}`)
      .then(() => {
        setSeniorBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((seniorBattle) => seniorBattle._id !== seniorBattleId);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno obrisana!");
      })
      .catch((error) => {
        toast.error("Error deleting seniorBattle:", error);
      });
  };

  const handleDone = (seniorBattle) => {
    const finishedBattleData = {
      category: seniorBattle.category,
      matchNumber: seniorBattle.matchNumber,
      teamA: seniorBattle.teamA,
      teamB: seniorBattle.teamB,
      hall: seniorBattle.hall,
      date: seniorBattle.date,
      helpers: seniorBattle.helpers.map((helper) => helper._id),
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle`, finishedBattleData)
      .then((response) => {
        console.log("Battle data sent to the backend:", response.data);
        setSeniorBattles((prevBattles) => {
          if (Array.isArray(prevBattles)) {
            return prevBattles.filter((b) => b._id !== seniorBattle._id);
          }
          return prevBattles;
        });
        toast.success("Utakmica je uspješno spašena!");
        setTableVisible(false);
      })
      .catch((error) => {
        console.error("Error sending battle data to the backend:", error);
        toast.error("Error sending battle data!");
      });

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle/${seniorBattle._id}`)
      .then(() => {
        console.log("Battle deleted from the backend");
      })
      .catch((error) => {
        console.error("Error deleting battle from the backend:", error);
        toast.error("Error deleting battle!");
      });
  };

  return (
    <div>
      {isTableVisible && (
        <table key={seniorBattle._id} className='text-left  mb-5 w-full'>
          <thead className=' py-3 text-sm  '>
            <tr>
              <th className='py-1 px-4 text-gray-200 hidden text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center  '>
                  <span class='text-gray-900 text-center '>KATEGORIJA</span>
                  <td className='py-2 text-center text-gray-900 border-gray-900 px-1'>{seniorBattle.category} </td>
                </div>
              </th>
              <th className='pb-4 px-4 text-gray-200 text-center border-2 border-gray-900'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-900 text-center'>UTAKMICA</span>
                  <td className='py-2 text-center text-gray-900 border-gray-900 px-1 hidden'>
                    {seniorBattle.matchNumber}
                  </td>
                </div>
              </th>
              <th className='pb-4 px-4 w-52 text-gray-200 text-center border-r-2 border-t-2 border-b-2 border-gray-900'>
                <div class='flex flex-col items-center'>
                  <td className='py-2 text-center font-bold text-gray-900 border-gray-900'>
                    {formatDate(seniorBattle.date)}
                  </td>
                  <span class='text-gray-900 text-center'> {seniorBattle.hall ? seniorBattle.hall.name : ""}</span>
                </div>
              </th>
              <tr>
                <th className='pb-6 pt-3 border-t-2 text-red-600 text-center border-r-2 w-72 bg-gray-200 border-gray-900 px-8 border-b-2'>
                  {seniorBattle.teamA?.name}
                </th>
                <th className='pb-6 pt-3 text-red-600 border-t-2 border-r-2 text-center border-b-2 w-72  bg-gray-200 border-gray-900 px-8  '>
                  {seniorBattle.teamB?.name}
                </th>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 pb-6 pt-3 font-bold text-sm'>Zapisnik</td>
                <td className='border-b-2  border-r-2 border-gray-900 px-2 pb-6 pt-3 font-bold  '>
                  {seniorBattle.helpers[0]
                    ? `${seniorBattle.helpers[0].firstName} ${seniorBattle.helpers[0].lastName}`
                    : "No Helper"}
                </td>
              </tr>
              {seniorBattle.helpers[5] && (
                <tr className='bg-white px-2 text-center'>
                  <td className='border-r-2 border-b-2 border-gray-900 px-2 pb-6 pt-3 font-bold text-sm'>
                    Pom. zapisnik
                  </td>
                  <td className='border-b-2  border-r-2 border-gray-900 px-2 pb-6 pt-3 font-bold  '>
                    {seniorBattle.helpers[5]
                      ? `${seniorBattle.helpers[5].firstName} ${seniorBattle.helpers[5].lastName}`
                      : "No Helper"}
                  </td>
                </tr>
              )}
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 pb-6 pt-3 font-bold text-sm'>Semafor</td>
                <td className='border-b-2  border-r-2 border-gray-900 px-2 pb-6 pt-3 font-bold  '>
                  {seniorBattle.helpers[1]
                    ? `${seniorBattle.helpers[1].firstName} ${seniorBattle.helpers[1].lastName}`
                    : "No Helper"}
                </td>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 pb-6 pt-3 font-bold text-sm'>24/14</td>
                <td className='border-b-2  border-r-2 border-gray-900 px-2 pb-6 pt-3 font-bold  '>
                  {seniorBattle.helpers[2]
                    ? `${seniorBattle.helpers[2].firstName} ${seniorBattle.helpers[2].lastName}`
                    : "No Helper"}
                </td>
              </tr>
              {seniorBattle.helpers[3] && (
                <tr className='bg-white px-2 text-center'>
                  <td className='border-r-2 border-b-2  border-gray-900 px-2 pb-6 pt-3 font-bold text-sm'>
                    Statistika
                  </td>
                  <td className='border-gray-900 border-b-2  border-r-2 px-2 pb-6 pt-3 font-bold'>
                    {seniorBattle.helpers[3] && seniorBattle.helpers[3].firstName !== "/"
                      ? `${seniorBattle.helpers[3].firstName} ${seniorBattle.helpers[3].lastName}, `
                      : ""}
                    {seniorBattle.helpers[4] && seniorBattle.helpers[4].firstName !== "/"
                      ? `${seniorBattle.helpers[4].firstName} ${seniorBattle.helpers[4].lastName}`
                      : ""}
                  </td>
                </tr>
              )}
            </tr>
          </thead>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default PoCommisionareSeniorTable;
