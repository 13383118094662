import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import DatePicker from "react-datepicker";

import axios from "axios";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";

const MoneyHelperTable = () => {
  const [helperi, setHelperi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [helperDebts, setHelperDebts] = useState([]);
  const [helperSeniorDebts, setHelperSeniorDebts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredDebts, setFilteredDebts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const helperResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`);
        setHelperi(helperResponse.data.helpers);

        const debtResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle/helper/alldebt`, {
          params: {
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
          },
        });

        const debtSeniorResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/finishedSeniorBattle/helper/alldebt`
        );
        setHelperSeniorDebts(debtSeniorResponse.data);
        setFilteredDebts(debtResponse.data);
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const getCategoryDebtForHelper = (helperId, category) => {
    const helperDebt = filteredDebts.find((debt) => debt.helperId.includes(helperId));
    return helperDebt ? helperDebt[category] : 0;
  };

  const getCategorySeniorDebtForHelper = (helperId, category) => {
    const helperSeniorDebt = helperSeniorDebts.find((debt) => debt.helperId.includes(helperId));
    return helperSeniorDebt ? helperSeniorDebt[category] : 0;
  };

  const totalDebtForAllHelpers = helperi.reduce((total, helper) => {
    return total + Number(getCategoryDebtForHelper(helper._id, "totalDebt"));
  }, 0);

  const totalDebtForAllHelpersSeniors = helperi.reduce((total, helper) => {
    return total + Number(getCategorySeniorDebtForHelper(helper._id, "totalDebt"));
  }, 0);

  const totalDebtjkp = helperi.reduce((total, helper) => {
    const junioriDebt = getCategoryDebtForHelper(helper._id, "Juniori");
    const kadetiDebt = getCategoryDebtForHelper(helper._id, "Kadeti");
    const kadetibDebt = getCategoryDebtForHelper(helper._id, "KadetiB");
    const pioniriDebt = getCategoryDebtForHelper(helper._id, "Pioniri");
    const pioniribDebt = getCategoryDebtForHelper(helper._id, "PioniriB");

    return total + junioriDebt + kadetiDebt + kadetibDebt + pioniriDebt + pioniribDebt;
  }, 0);

  const downloadExcel = () => {
    try {
      const data = helperi.map((helper) => ({
        "Ime i prezime": `${helper.firstName} ${helper.lastName}`,
        "R - BIH": getCategorySeniorDebtForHelper(helper._id, "Reprezentacija"),
        PLBIHM: getCategorySeniorDebtForHelper(helper._id, "PLBIHM"),
        PLBIHZ: getCategorySeniorDebtForHelper(helper._id, "PLBIHZ"),
        A1: getCategorySeniorDebtForHelper(helper._id, "SenioriA1"),
        A2: getCategorySeniorDebtForHelper(helper._id, "SenioriA2"),
        A1Ž: getCategorySeniorDebtForHelper(helper._id, "SenioriA1Z"),
        Juniori: getCategoryDebtForHelper(helper._id, "Juniori"),
        Kadeti: getCategoryDebtForHelper(helper._id, "Kadeti") + getCategoryDebtForHelper(helper._id, "KadetiB"),
        Pioniri: getCategoryDebtForHelper(helper._id, "Pioniri") + getCategoryDebtForHelper(helper._id, "PioniriB"),
        MB: getCategoryDebtForHelper(helper._id, "Minibasket"),
        Juniorke: getCategoryDebtForHelper(helper._id, "Juniorke"),
        Kadetkinje: getCategoryDebtForHelper(helper._id, "Kadetkinje"),
        Pionirke: getCategoryDebtForHelper(helper._id, "Pionirke"),
        MBŽ: getCategoryDebtForHelper(helper._id, "MinibasketZ"),
        Ostalo: getCategoryDebtForHelper(helper._id, "Ostalo"),
        Turnir: getCategoryDebtForHelper(helper._id, "Turnir"),
        "UKUPNO J-K-P":
          getCategoryDebtForHelper(helper._id, "Juniori") +
          getCategoryDebtForHelper(helper._id, "Kadeti") +
          getCategoryDebtForHelper(helper._id, "KadetiB") +
          getCategoryDebtForHelper(helper._id, "Pioniri") +
          getCategoryDebtForHelper(helper._id, "PioniriB"),
        "UKUPNO O-LIGA": Number(getCategoryDebtForHelper(helper._id, "totalDebt")),
        "UKUPNO SENIORI": Number(getCategorySeniorDebtForHelper(helper._id, "totalDebt")),
      }));

      data.push({
        "Ime i prezime": "UKUPNO",
        "UKUPNO J-K-P": totalDebtjkp,
        "UKUPNO SENIORI": totalDebtForAllHelpersSeniors,
        "UKUPNO O-LIGA": totalDebtForAllHelpers,
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Helperi");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Dugovanja - Pomoćne sudije.xlsx";
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className=' flex flex-row  justify-center'>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText='Od datuma'
          className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText='Do datuma'
          className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
        />
      </div>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <table className='w-full text-left border-2 border-gray-900 mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900 w-52'>Ime i prezime</th>
              <th className='py-1 text-gray-200 text-center  border-r-2 border-gray-900'>R - BIH </th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>PLBIHM</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>PLBIHZ</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>A1</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>A2</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>A1Ž</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniori</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadeti</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pioniri</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>MB</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniorke</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadetkinje</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pionirke</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>MBŽ</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ostalo</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Turnir</th>

              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO J-K-P</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO O-LIGA</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO SENIORI</th>
            </tr>
          </thead>
          <tbody className='p-2 text-sm'>
            {helperi.map((helper) => {
              return (
                <tr key={helper._id} className='hover:bg-gray-400 transition cursor-pointer'>
                  {helper.firstName !== "/" ? (
                    <>
                      <th className='py-1 text-gray-800 text-center w-52 border-r-2 border-b-2 border-gray-900 px-5'>
                        {helper.firstName} {helper.lastName}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "Reprezentacija")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "PLBIHM")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "PLBIHZ")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "SenioriA1")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "SenioriA2")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategorySeniorDebtForHelper(helper._id, "SenioriA1Z")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Juniori")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Kadeti") +
                          getCategoryDebtForHelper(helper._id, "KadetiB")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Pioniri") +
                          getCategoryDebtForHelper(helper._id, "PioniriB")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Minibasket")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Juniorke")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Kadetkinje")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Pionirke")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "MinibasketZ")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Ostalo")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Turnir")}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 font-extrabold text-center border-r-2 border-gray-900 px-5'>
                        {getCategoryDebtForHelper(helper._id, "Juniori") +
                          getCategoryDebtForHelper(helper._id, "Kadeti") +
                          getCategoryDebtForHelper(helper._id, "KadetiB") +
                          getCategoryDebtForHelper(helper._id, "Pioniri") +
                          getCategoryDebtForHelper(helper._id, "PioniriB")}{" "}
                      </th>

                      <th className='py-1 text-gray-800 border-b-2 font-extrabold text-center border-r-2 border-gray-900 px-5'>
                        {Number(getCategoryDebtForHelper(helper._id, "totalDebt"))}
                      </th>
                      <th className='py-1 text-gray-800 border-b-2 font-extrabold text-center border-r-2 border-gray-900 px-5'>
                        {Number(getCategorySeniorDebtForHelper(helper._id, "totalDebt"))}
                      </th>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      <ToastContainer />
      <div className='flex justify-end'>
        <table className={`text-left border-2 border-gray-900 `}>
          <tbody className='py-1 text-base border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO J-K-P
              </th>
              <th className='py-1 px-2 text-gray-900 text-center border-r-2 border-gray-900'>{totalDebtjkp} KM</th>
            </tr>
          </tbody>
          <tbody className='py-1 text-base border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO O-LIGA
              </th>
              <th className='py-1 px-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {totalDebtForAllHelpers} KM
              </th>
            </tr>
          </tbody>
          <tbody className='py-1 text-base border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO SENIORI
              </th>
              <th className='py-1 px-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {totalDebtForAllHelpersSeniors} KM
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='flex mt-5 justify-end items-center bg-green-900 rounded px-3 py-2 transform transition-transform hover:scale-105'>
        <img src='icons/excelLogo.svg' alt='EXCEL' className='w-7 h-7 mr-2' />
        <button onClick={downloadExcel} className='text-white font-semibold'>
          Download Excel
        </button>
      </div>
    </div>
  );
};

export default MoneyHelperTable;
