import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import DatePicker from "react-datepicker";

import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";

const MoneyRefereeTable = () => {
  const [sudije, setSudije] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredDebts, setFilteredDebts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const refereeResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=9999`);
        setSudije(refereeResponse.data.referees);

        const debtResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle/referee/alldebt`, {
          params: {
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
          },
        });

        setFilteredDebts(debtResponse.data);
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const getCategoryDebtForReferee = (refereeId, category) => {
    const refereeDebt = filteredDebts.find((debt) => debt.refereeId.includes(refereeId));
    return refereeDebt ? refereeDebt[category] : 0;
  };

  const totalDebtForAllReferees = sudije.reduce((total, sudija) => {
    return total + getCategoryDebtForReferee(sudija._id, "totalDebt");
  }, 0);

  const totalDebtjkp = sudije.reduce((total, sudija) => {
    const junioriDebt = getCategoryDebtForReferee(sudija._id, "Juniori");
    const kadetiDebt = getCategoryDebtForReferee(sudija._id, "Kadeti");
    const kadetibDebt = getCategoryDebtForReferee(sudija._id, "KadetiB");
    const pioniriDebt = getCategoryDebtForReferee(sudija._id, "Pioniri");
    const pioniribDebt = getCategoryDebtForReferee(sudija._id, "PioniriB");

    return total + junioriDebt + kadetiDebt + kadetibDebt + pioniriDebt + pioniribDebt;
  }, 0);

  const downloadExcel = () => {
    try {
      const data = sudije.map((sudija) => ({
        "Ime i prezime": `${sudija.firstName} ${sudija.lastName}`,
        Juniori: getCategoryDebtForReferee(sudija._id, "Juniori"),
        Kadeti: getCategoryDebtForReferee(sudija._id, "Kadeti") + getCategoryDebtForReferee(sudija._id, "KadetiB"),
        Pioniri: getCategoryDebtForReferee(sudija._id, "Pioniri") + getCategoryDebtForReferee(sudija._id, "PioniriB"),
        Minibasket: getCategoryDebtForReferee(sudija._id, "Minibasket"),
        Juniorke: getCategoryDebtForReferee(sudija._id, "Juniorke"),
        Kadetkinje: getCategoryDebtForReferee(sudija._id, "Kadetkinje"),
        Pionirke: getCategoryDebtForReferee(sudija._id, "Pionirke"),
        MinibasketZ: getCategoryDebtForReferee(sudija._id, "MinibasketZ"),
        Ostalo: getCategoryDebtForReferee(sudija._id, "Ostalo"),
        Turnir: getCategoryDebtForReferee(sudija._id, "Turnir"),
        "UKUPNO J-K-P":
          getCategoryDebtForReferee(sudija._id, "Juniori") +
          getCategoryDebtForReferee(sudija._id, "Kadeti") +
          getCategoryDebtForReferee(sudija._id, "KadetiB") +
          getCategoryDebtForReferee(sudija._id, "Pioniri") +
          getCategoryDebtForReferee(sudija._id, "PioniriB"),
        UKUPNO: Number(getCategoryDebtForReferee(sudija._id, "totalDebt")),
      }));

      data.push({
        "Ime i prezime": "UKUPNO",
        "UKUPNO J-K-P": totalDebtjkp,
        UKUPNO: totalDebtForAllReferees,
      });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sudije");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Dugovanja - Sudije.xlsx";
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className=' flex flex-row  justify-center'>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText='Od datuma'
          className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          placeholderText='Do datuma'
          className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
        />
      </div>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <table className='w-full text-left border-2 border-gray-900 mb-5'>
          <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ime i prezime</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniori</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadeti</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pioniri</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Minibasket</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Juniorke</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Kadetkinje</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Pionirke</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>MinibasketZ</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Ostalo</th>
              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>Turnir</th>

              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO J-K-P</th>

              <th className='py-1 text-gray-200 text-center border-r-2 border-gray-900'>UKUPNO</th>
            </tr>
          </thead>
          <tbody className='p-2 text-sm'>
            {sudije.map((sudija) => {
              return (
                <tr key={sudija._id} className='hover:bg-gray-400 transition cursor-pointer'>
                  <th className='py-1 text-gray-800 text-center border-r-2 border-b-2 border-gray-900 px-5'>
                    {sudija.firstName} {sudija.lastName}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Juniori")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Kadeti") + getCategoryDebtForReferee(sudija._id, "KadetiB")}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Pioniri") +
                      getCategoryDebtForReferee(sudija._id, "PioniriB")}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Minibasket")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Juniorke")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Kadetkinje")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Pionirke")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "MinibasketZ")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Ostalo")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Turnir")}{" "}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 font-extrabold  text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "Juniori") +
                      getCategoryDebtForReferee(sudija._id, "Kadeti") +
                      getCategoryDebtForReferee(sudija._id, "KadetiB") +
                      getCategoryDebtForReferee(sudija._id, "Pioniri") +
                      getCategoryDebtForReferee(sudija._id, "PioniriB")}
                  </th>
                  <th className='py-1 text-gray-800 border-b-2 font-extrabold  text-center border-r-2 border-gray-900 px-5'>
                    {getCategoryDebtForReferee(sudija._id, "totalDebt")}{" "}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <ToastContainer />
      <div className='flex justify-end'>
        <table className={`text-left border-2 border-gray-900 `}>
          <tbody className='py-1 text-base border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO
              </th>
              <th className='py-1 px-2 text-gray-900 text-center border-r-2 border-gray-900'>
                {totalDebtForAllReferees} KM
              </th>
            </tr>
          </tbody>
          <tbody className='py-1 text-base border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-2 bg-gradient-to-r from-indigo-800 to-indigo-800 text-gray-200 text-center border-r-2 border-gray-900'>
                UKUPNO J-K-P
              </th>
              <th className='py-1 px-2 text-gray-900 text-center border-r-2 border-gray-900'>{totalDebtjkp} KM</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='flex mt-5 justify-end items-center bg-green-900 rounded px-3 py-2 transform transition-transform hover:scale-105'>
        <img src='icons/excelLogo.svg' alt='EXCEL' className='w-7 h-7 mr-2' />
        <button onClick={downloadExcel} className='text-white font-semibold'>
          Download Excel
        </button>
      </div>
    </div>
  );
};

export default MoneyRefereeTable;
