const DeleteConfirmationModal = ({ dataName, onDelete, onCancel, type }) => {
  return (
    <div className='fixed inset-0 flex z-20 items-center justify-center bg-black bg-opacity-50'>
      <div className='bg-white px-4 pb-5 rounded-lg shadow-lg text-center'>
        <div className='flex justify-center'>
          <img src='icons/warning.svg' alt='delete' className='w-28 ml-1  justify-center items-center' />
        </div>
        <div>
          <p className='text-3xl text-gray-800 pb-5'>Jeste li sigurni?</p>
        </div>
        <p className='text-lg text-gray-600'>
          Jeste li sigurni da želite obrisati {type}: {dataName}?
        </p>
        <div className='flex justify-center mt-4'>
          <button
            className='border-2  bg-gray-400 text-white py-1 px-4  mr-2 transform transition-transform hover:scale-105'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className='border-2 text-white  bg-red-800 py-1 px-4  flex items-center transform transition-transform hover:scale-105'
            onClick={() => {
              onDelete();
            }}
          >
            Delete
            <img
              src='icons/deleteIcon.png'
              alt='delete'
              className='w-4 ml-1 cursor-pointer filter brightness-0 invert'
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
