import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

import EditSideDrawerReferees from "../sidedrawer/EditSideDrawerReferees";
import DeleteConfirmationModal from "../modals/DeleteConifirmationModal";
import PaginationButtons from "../other/PaginationButtons";
import LoadingSpinner from "../other/LoadingSpinner";

import "react-toastify/dist/ReactToastify.css";

const ResponsiveRefereeTable = ({ onClose, searchQuery }) => {
  const [sudije, setSudije] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReferee, setSelectedReferee] = useState(null);
  const [isSortingAsc, setIsSortingAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRefereesPerPage, setSelectedRefereesPerPage] = useState(12);
  const [isEditSideDrawerOpen, setIsEditSideDrawerOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const limit = 12;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/referee?page=${currentPage}&limit=${selectedRefereesPerPage}&firstName=${searchQuery}`
      )
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedRefereesPerPage, searchQuery]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  useEffect(() => {
  }, [sudije]);

  const handleDelete = (referee) => {
    setShowDeleteModal(true);
    setSelectedReferee(referee);
  };

  const hasAbsenceDates = (referee) => {
    return referee.startDate && referee.endDate;
  };

  const handleDeleteConfirmation = (refereeId) => {
    toast.success("Uspješno ste obrisali sudiju");

    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/referee/${refereeId}`)
      .then((response) => {
        toast.success("Uspješno ste obrisali sudiju");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setSelectedReferee(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedReferee(null);
  };

  const handleEditButtonClick = (selectedRefereeData) => {
    setIsEditSideDrawerOpen(true);

    const formattedStartDate = selectedRefereeData.startDate ? new Date(selectedRefereeData.startDate) : null;
    const formattedEndDate = selectedRefereeData.endDate ? new Date(selectedRefereeData.endDate) : null;

    setSelectedReferee({
      ...selectedRefereeData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEditSideDrawerClose = () => {
    setIsEditSideDrawerOpen(false);
  };

  const handleSort = () => {
    setIsSortingAsc(!isSortingAsc);

    const sortDirection = isSortingAsc ? "asc" : "desc";
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=${currentPage}&limit=${limit}&sort=${sortDirection}`)
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRefereesPerPageChange = (e) => {
    const newRefereesPerPage = parseInt(e.target.value, 10);
    setSelectedRefereesPerPage(newRefereesPerPage);
    setCurrentPage(1);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/referee?page=1&limit=${newRefereesPerPage}`)
      .then((response) => {
        setSudije(response.data.referees);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        toast.error("Error fetching referees:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filteredSudije = sudije.filter((sudija) => {
    return sudija.firstName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <div className='flex flex-col w-full items-center justify-center bg-white shadow-xl border-2 border-gray-100 '>
      {isLoading ? (
        <div className='flex justify-center items-center h-80'>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <table className='w-full text-left  '>
            <thead className='bg-gradient-to-r from-indigo-800 to-indigo-700 p-2 py-2 '>
              <tr>
                <th className='py-3 px-5 text-sm text-center text-gray-200'>Ime i prezime</th>
                <th className='py-3 px-5 text-gray-200 text-sm text-center'>Datum početka odsustva</th>
                <th className='py-3 px-5 text-gray-200 text-sm text-center'>Datum završetka odsustva</th>
              </tr>
            </thead>
            <tbody className='p-5 px-5'>
              {filteredSudije.map((sudija, index) => (
                <React.Fragment key={sudija._id}>
                  <tr
                    key={sudija._id}
                    className={`hover:bg-gray-200  border border-gray-200 cursor-pointer ${
                      expandedRow === index ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setExpandedRow(index === expandedRow ? null : index)}
                  >
                    <td className='py-2 px-5 font-semibold'>
                      {sudija.firstName} {sudija.lastName}
                    </td>
                    {hasAbsenceDates(sudija) ? (
                      <>
                        <td className='py-2 px-5 text-center '>{formatDate(sudija.startDate)}</td>
                        <td className='py-2 px-5 text-center'>{formatDate(sudija.endDate)}</td>
                      </>
                    ) : (
                      <>
                        <td className='py-2 px-5 text-center'>
                          <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                            ✔️
                          </span>
                        </td>
                        <td className='py-2 px-5 text-center'>
                          <span role='img' aria-label='Checkmark' style={{ color: "green" }}>
                            ✔️
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan='3' className='px-10 py-2'>
                        <div>
                          <div className='flex justify-between font-medium '>
                            <p>Rating:</p>
                            <p className=' justify-end'>{sudija.refereeRating}</p>
                          </div>
                          <div className='flex justify-between  font-medium '>
                            <p>Članarina:</p>
                            <p className=' justify-end'>
                              {sudija.price} KM / {sudija.refereeRating === 10 ? "240 KM" : "120 KM"}
                            </p>
                          </div>
                        </div>
                        <div className='text-center justify-center items-center flex flex-col py-2'>
                          <button
                            onClick={() => handleEditButtonClick(sudija)}
                            className='border-2 w-full text-center text-white bg-gradient-to-r from-green-900 to-green-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg mb-2'
                          >
                            Uredi
                          </button>
                          <button
                            // onClick={() => handleDelete(sudija)}
                            className=' cursor-not-allowed border-2 w-full text-white bg-gradient-to-r from-red-900 to-red-800 transform transition-transform hover:scale-105 py-2 px-8 rounded-lg'
                          >
                            Obriši
                          </button>
                        </div>
                        {isEditSideDrawerOpen && (
                          <EditSideDrawerReferees
                            selectedReferee={selectedReferee}
                            onClose={handleEditSideDrawerClose}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <div className='flex justify-between w-full'>
        <div className='my-5 px-5'>
          <div className='mr-2 mb-1'>Redovi:</div>
          <select
            className='px-2 py-1 border rounded-md'
            value={selectedRefereesPerPage}
            onChange={handleRefereesPerPageChange}
          >
            <option value={12}>12</option>
            <option value={24}>24</option>
            <option value={36}>36</option>
          </select>
        </div>
        <div className='my-5 px-5 flex justify-center items-center'>
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onPrevPage={handlePrevPage}
            onNextPage={handleNextPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          dataName={`${selectedReferee?.firstName} ${selectedReferee?.lastName}`}
          onDelete={() => handleDeleteConfirmation(selectedReferee._id)}
          onCancel={handleDeleteCancel}
          type='sudiju'
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ResponsiveRefereeTable;
