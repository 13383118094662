import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const EditSideDrawerHelpers = ({ selectedHelper, onClose }) => {
  const [, setHelperi] = useState([]);
  const [ime, setIme] = useState(selectedHelper.firstName);
  const [prezime, setPrezime] = useState(selectedHelper.lastName);
  const [rating, setRating] = useState(String(selectedHelper.helperRating));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reason, setReason] = useState(selectedHelper.reason);
  const [statistika, setStatistika] = useState(selectedHelper.statistic);
  const [price, setPrice] = useState(String(selectedHelper.price));

  useEffect(() => {
    fetchHelpers();
  }, []);

  const fetchHelpers = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/helpers`)
      .then((response) => {
        setHelperi(response.data);
      })
      .catch((error) => {
        toast.error("Toastify error:", error);
      });
  };

  const handleUpdateHelper = () => {
    if (!ime || !prezime) {
      toast.error("Sva polja moraju biti popunjena");
      return;
    }

    const updateHelper = {
      firstName: ime,
      lastName: prezime,
      helperRating: Number(rating),
      statistic: statistika,
      reason: reason,
      price: Number(price),
    };

    if (!startDate) {
      updateHelper.startDate = null;
    } else if (startDate instanceof Date && !isNaN(startDate)) {
      updateHelper.startDate = startDate.toISOString();
    }

    if (!endDate) {
      updateHelper.endDate = null;
    } else if (endDate instanceof Date && !isNaN(endDate)) {
      updateHelper.endDate = endDate.toISOString();
    }

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/helpers/${selectedHelper._id}`, updateHelper)
      .then((response) => {
        toast.success("Uspješno ste updateovali podatke");

        fetchHelpers();
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while creating the helper");
        }
      });
  };

  useEffect(() => {
    setIme(selectedHelper.firstName);
    setPrezime(selectedHelper.lastName);
    setRating(String(selectedHelper.helperRating));
    const formattedStartDate = selectedHelper.startDate ? new Date(selectedHelper.startDate) : null;
    const formattedEndDate = selectedHelper.endDate ? new Date(selectedHelper.endDate) : null;
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setReason(reason);

    setStatistika(selectedHelper.statistic);
  }, [selectedHelper]);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <div className='fixed z-20 right-0 top-0 w-full  md:w-2/5 lg:w-1/3 xl:w-1/3 2xl:w-1/4 sm:w-3/5 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-l shadow-lg'>
        <div className='p-5 h-screen overflow-hidden overflow-y-auto'>
          <div className='flex flex-row justify-left items-center '>
            <img
              src='icons/leftArrow.png'
              alt='delete'
              className='w-6 h-6 cursor-pointer '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            ></img>
            <h1
              className='text-gray-200 text-lg pl-2 font-bold cursor-pointer transform transition-transform hover:scale-110  '
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
            >
              Back
            </h1>
          </div>

          <div className='my-4 mx-5'>
            <label className='block mb-2 text-xl text-left font-bold text-gray-200' htmlFor='firstName'>
              Ime:
            </label>
            <input
              id='firstName'
              placeholder='Unesite ime pomoćnog sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
          </div>
          <div className='my-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='lastName'>
              Prezime:
            </label>
            <input
              id='lastName'
              placeholder='Unesite prezime pomoćnog sudije'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={prezime}
              onChange={(e) => setPrezime(e.target.value)}
            />
          </div>
          <div className='mt-4 mb-1 mx-5 text-left '>
            <label className='block mb-2 text-xl font-bold text-gray-200' htmlFor='rating'>
              Rating:
            </label>
            <select
              id='helperRating'
              className='border rounded px-2 py-1 w-full'
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            >
              {[...Array(4)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='mx-5 mb-1 text-red-600 font-semibold text-sm text-left'>
            <p>1 - Zapisnik, 2 - 24/14, Semafor, Zapisnik</p>
            <p>3 - Napredno, 4 - Expert (Sve dvorane)</p>
          </div>
          <div className='mt-2 mb-4 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='price'>
              Članarina:
            </label>
            <input
              id='price'
              placeholder='Unesite članarinu'
              type='text'
              className='border rounded px-2 py-1 w-full'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className='mt-2 mx-5'>
            <label className='block mb-2 text-left text-xl font-bold text-gray-200' htmlFor='lastName'>
              Statistika:
            </label>
            <div className='flex items-center'>
              <label htmlFor='da' className='mr-2'>
                <span className='text-gray-200 font-semibold'>DA</span>
              </label>
              <input
                type='checkbox'
                id='da'
                name='odgovor'
                value='da'
                className='form-radio'
                checked={statistika === "da"}
                onChange={() => setStatistika("da")}
              />
            </div>
            <div className='flex items-center'>
              <label htmlFor='ne' className='mr-2'>
                <span className='text-gray-200 font-semibold'>NE</span>
              </label>
              <input
                type='checkbox'
                id='ne'
                name='odgovor'
                value='ne'
                className='form-radio'
                checked={statistika === "ne"}
                onChange={() => setStatistika("ne")}
              />
            </div>
          </div>

          {startDate !== null && endDate !== null ? (
            <>
              <div className='my-4 mx-5 text-left'>
                <label className='block mb-2 text-lg font-bold text-gray-200' htmlFor='startDate'>
                  Datum i vrijeme početka odsustva:
                </label>
                <DatePicker
                  id='startDate'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  dateFormat='dd.MM.yyyy. HH:mm'
                  timeFormat='HH:mm'
                  className='border rounded px-2 py-1 w-36 text-left'
                />
              </div>
              <div className='my-4 mx-5 text-left'>
                <label className='block mb-2 text-lg font-bold text-gray-200' htmlFor='endDate'>
                  Datum i vrijeme završetka odsustva:
                </label>
                <DatePicker
                  id='endDate'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  dateFormat='dd.MM.yyyy. HH:mm'
                  timeFormat='HH:mm'
                  className='border rounded px-2 py-1 w-36 text-left'
                />
              </div>
              <div className='mb-4 mx-5 text-left'>
                <label className='block mb-2 text-lg font-bold text-gray-200'>Razlog odsustva:</label>
                <textarea
                  className='w-full px-4 text-base text-left  py-2 border border-gray-900 rounded-lg'
                  rows='4'
                  value={reason}
                  onChange={handleReasonChange}
                ></textarea>
              </div>
            </>
          ) : (
            <div className='my-4 mx-5'>
              <button
                className='border flex text-white bg-gradient-to-r from-green-600 to-green-800 transform transition-transform hover:scale-105 py-1 px-10 rounded'
                onClick={() => {
                  setStartDate(new Date());
                  setEndDate(new Date());
                }}
              >
                Dodaj odsustvo
              </button>
            </div>
          )}
          <div className='flex items-center justify-end mx-5'>
            <button
              className='border text-white bg-gradient-to-r from-blue-600 to-blue-800 transform transition-transform hover:scale-105 py-2 px-10 rounded'
              onClick={() => {
                handleUpdateHelper();
              }}
            >
              Uredi
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditSideDrawerHelpers;
