import { useState, useEffect } from "react";

const RefereeSearch = ({ onSearch, placeholder }) => {
  const [query, setQuery] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    onSearch(inputValue);
  };

  return (
    <div className='flex px-8 pt-2 justify-end'>
      <input
        type='text'
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
        className={`w-full ${
          isMobile ? "w-full" : ""
        } px-4 py-2  rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 `}
      />
      <img src='icons/searchIcon.svg' alt='Search Icon' className='ml-2 h-6 w-6 self-center' />
    </div>
  );
};

export default RefereeSearch;
