const RefereeSelect = ({ referees, onSelectReferee, refereeSelect, refereeNumber, battleDate }) => {
  const filteredReferees = referees.filter((referee) => {
    if (!referee.startDate || !referee.endDate) {
      return true;
    }

    const battleDateObj = new Date(battleDate);
    return battleDateObj < new Date(referee.startDate) || battleDateObj > new Date(referee.endDate);
  });

  return (
    <div className='flex flex-col items-center'>
      <select
        className='text-gray-800 text-center referee-select font-semibold w-40'
        id={refereeSelect}
        onChange={(e) => onSelectReferee(e.target.value)}
      >
        <option value=''>{refereeNumber}</option>
        {filteredReferees.map((referee, index) => (
          <option key={index} value={referee._id}>
            {referee.firstName} {referee.lastName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RefereeSelect;
