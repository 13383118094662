import React from "react";

const TeamASelect = ({ filteredTeams, teamA, onTeamAChange }) => {
  return (
    <React.Fragment>
      <select className='w-36' value={teamA} onChange={(e) => onTeamAChange(e.target.value)}>
        <option value=''>Select Team A</option>
        {filteredTeams.map((team) => (
          <option key={team._id} value={team._id}>
            {team.name}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default TeamASelect;
