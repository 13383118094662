import React, { useEffect, useState } from "react";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HoverTooltip from "../other/HoverTooltip";
import ZapisnikSelect from "../selectOptions/ZapisnikSelect";
import SemaforSelect from "../selectOptions/SemaforSelect";
import TasterSelect from "../selectOptions/TasterSelect";
import StatisticSelect from "../selectOptions/StatisticSelect";
import StatisticTwoSelect from "../selectOptions/StatisticTwoSelect";

const PoDraftSeniorBattleTable = ({ draftSeniorBattle }) => {
  const [isTableVisible, setTableVisible] = useState(true);
  const [, setSelectedHelper] = useState("");
  const [helpers, setHelpers] = useState([]);
  const [selectedHallRating, setSelectedHallRating] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHelpersForSave, setSelectedHelpersForSave] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hour}:${minutes}h`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const helpersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/helpers?page=1&limit=9999`);
        setHelpers(helpersResponse.data.helpers);
        console.error("Error fetching data:");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSave = () => {
    const selectedHelper1 = document.getElementById("helperSelect1")?.value || "";
    const selectedHelper2 = document.getElementById("helperSelect2")?.value || "";
    const selectedHelper3 = document.getElementById("helperSelect3")?.value || "";
    const selectedHelper4 = document.getElementById("helperSelect4")?.value || "";
    const selectedHelper5 = document.getElementById("helperSelect5")?.value || "";
    const selectedHelper6 = document.getElementById("helperSelect6")?.value || "";
    console.log("Selected Helper 1:", selectedHelper1);
    console.log("Selected Helper 6:", selectedHelper6);
    const teamA = draftSeniorBattle.teamA._id.toString();
    const teamB = draftSeniorBattle.teamB._id.toString();
    const hall = draftSeniorBattle.hall._id.toString();

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/seniorBattle`, {
        category: draftSeniorBattle.category,
        teamA: teamA,
        teamB: teamB,
        hall: hall,
        helpers: [
          selectedHelper1,
          selectedHelper2,
          selectedHelper3,
          selectedHelper4,
          selectedHelper5,
          selectedHelper6,
        ].filter(Boolean),
        date: draftSeniorBattle.date,
      })

      .then((response) => {
        toast.success("Uspješno ste kreirali utakmicu");
        setTableVisible(false);
        axios
          .delete(`${process.env.REACT_APP_API_BASE_URL}/draftSeniorBattle/${draftSeniorBattle._id}`)
          .then(() => {
            console.log("Battle deleted from the backend");
          })
          .catch((error) => {
            console.error("Error deleting battle from the backend:", error);
            toast.error("Error deleting battle!");
          });
      })
      .catch((error) => {
        toast.error("Unesite sve podatke!", error);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/draftSeniorBattle/${draftSeniorBattle._id}`)
      .then(() => {
        toast.success("Utakmica je uspješno obrisana");
        setTableVisible(false);
      })
      .catch((error) => {
        toast.error("Error pri brisanju utakmice:", error);
      });
  };

  const handleHelperSelect = (selectedHelperId) => {
    setSelectedHelper(selectedHelperId);
  };

  const filteredHelpers = helpers.filter((helper) => {
    if (draftSeniorBattle.hall.rating === 3) {
      return helper.helperRating === 4;
    } else if (draftSeniorBattle.hall.rating === 2) {
      return helper.helperRating === 3 || helper.helperRating === 4;
    } else if (draftSeniorBattle.hall.rating === 1) {
      return helper.helperRating === 2 || helper.helperRating === 3 || helper.helperRating === 4;
    }
    return true;
  });

  const handleEdit = () => {
    const updatedData = {
      helpers: [],
    };

    axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/draftSeniorBattle/${draftSeniorBattle._id}`, updatedData)
      .then((response) => {
        toast.success("Uspješno ste ažurirali podatke");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Greška prilikom ažuriranja podataka", error);
      });
  };

  return (
    <div className='flex justify-center items-center'>
      {isTableVisible && (
        <table className='text-left border-2 border-gray-900 mb-5'>
          <thead className=' py-3 text-sm border-2 border-gray-900'>
            <tr>
              <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center  '>
                  <span class='text-gray-900 text-center '>KATEGORIJA</span>
                  <td className='py-2 text-center text-gray-900 border-gray-900 px-1'>{draftSeniorBattle.category}</td>
                </div>
              </th>
              <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center '>
                  <span class='text-gray-900 text-center'>UTAKMICA</span>
                </div>
              </th>
              <th className='py-1 px-4 w-40 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center'>
                  <td className='py-2 text-center font-bold text-gray-900 border-gray-900'>
                    {formatDate(draftSeniorBattle.date)}
                  </td>
                  <span className='text-gray-900 text-center'>{draftSeniorBattle.hall.name}</span>
                </div>
              </th>
              <tr>
                <th className='py-4 w-64 text-red-600 text-center border-r-2 bg-gray-200 border-gray-900 px-8 border-b-2'>
                  {draftSeniorBattle.teamA.name}{" "}
                </th>
                <th className='py-4 w-64 text-red-600 text-center border-b-2 bg-gray-200 border-gray-900 px-8  border-r-2'>
                  {draftSeniorBattle.teamB.name}{" "}
                </th>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Zapisnik</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-semibold border-r-2'>
                  {draftSeniorBattle.helpers[0] ? (
                    `${draftSeniorBattle.helpers[0].firstName} ${draftSeniorBattle.helpers[0].lastName}`
                  ) : (
                    <>
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={draftSeniorBattle.date}
                        onSelectHelper={handleHelperSelect}
                        name='Zapisnik'
                        helperselect='helperSelect1'
                      />
                    </>
                  )}
                </td>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold text-sm'>Pom. zapisnik</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-semibold  border-r-2'>
                  {draftSeniorBattle.helpers[5] ? (
                    `${draftSeniorBattle.helpers[5].firstName} ${draftSeniorBattle.helpers[5].lastName}`
                  ) : (
                    <>
                      <ZapisnikSelect
                        helpers={helpers}
                        battleDate={draftSeniorBattle.date}
                        onSelectHelper={handleHelperSelect}
                        name='Pom. zapisnik'
                        helperselect='helperSelect6'
                      />
                    </>
                  )}
                </td>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>Semafor</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-semibold  border-r-2 '>
                  {draftSeniorBattle.helpers[1] ? (
                    `${draftSeniorBattle.helpers[1].firstName} ${draftSeniorBattle.helpers[1].lastName}`
                  ) : (
                    <>
                      <SemaforSelect
                        helpers={filteredHelpers}
                        battleDate={draftSeniorBattle.date}
                        onSelectHelper={handleHelperSelect}
                        selectedHallRating={selectedHallRating}
                      />
                    </>
                  )}
                </td>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2 border-b-2 border-gray-900 px-2 py-3 font-bold   text-sm'>24/14</td>
                <td className='border-b-2 border-gray-900 px-2 py-3 font-semibold  border-r-2 '>
                  {draftSeniorBattle.helpers[2] ? (
                    `${draftSeniorBattle.helpers[2].firstName} ${draftSeniorBattle.helpers[2].lastName}`
                  ) : (
                    <>
                      <TasterSelect
                        helpers={helpers}
                        battleDate={draftSeniorBattle.date}
                        onSelectHelper={handleHelperSelect}
                      />
                    </>
                  )}
                </td>
              </tr>
              <tr className='bg-white px-2 text-center'>
                <td className='border-r-2  border-gray-900 px-2 py-3 font-bold text-sm'>Statistika</td>
                <td className='border-gray-900 px-2 py-3 font-semibold border-r-2'>
                  {draftSeniorBattle.helpers[3] && draftSeniorBattle.helpers[3].firstName !== "/"
                    ? `${draftSeniorBattle.helpers[3].firstName} ${draftSeniorBattle.helpers[3].lastName}, `
                    : ""}
                  {draftSeniorBattle.helpers[4] && draftSeniorBattle.helpers[4].firstName !== "/"
                    ? `${draftSeniorBattle.helpers[4].firstName} ${draftSeniorBattle.helpers[4].lastName}`
                    : ""}
                  {(!draftSeniorBattle.helpers[3] || draftSeniorBattle.helpers[3].firstName === "/") &&
                    (!draftSeniorBattle.helpers[4] || draftSeniorBattle.helpers[4].firstName === "/") && (
                      <>
                        <StatisticSelect
                          helpers={helpers}
                          battleDate={draftSeniorBattle.date}
                          onSelectHelper={handleHelperSelect}
                        />
                        <StatisticTwoSelect
                          helpers={helpers}
                          battleDate={draftSeniorBattle.date}
                          onSelectHelper={handleHelperSelect}
                        />
                      </>
                    )}
                </td>
              </tr>
              <th className='py-1 px-4 text-gray-200 text-center border-r-2 border-gray-900'>
                <div class='flex flex-col items-center'>
                  <span className='text-gray-900 pb-2'>AKCIJE</span>
                  <div className=' flex items-center justify-center'>
                    <HoverTooltip text='Save' translate='-translate-y-7 -translate-x-4'>
                      <img
                        src='icons/saveIcon.svg'
                        alt='save'
                        className='w-5 cursor-pointer ml-1 icon-container transform transition-transform hover:scale-125'
                        onClick={handleSave}
                      />
                    </HoverTooltip>
                    <HoverTooltip text='Delete' translate='-translate-y-7 -translate-x-4'>
                      <img
                        src='icons/deleteIcon.png'
                        alt='delete'
                        className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                        onClick={handleDelete}
                      />
                    </HoverTooltip>

                    <HoverTooltip text='Edit' translate='-translate-y-7 -translate-x-4'>
                      <img
                        src='icons/editIcon.png'
                        alt='delete'
                        className='w-6 cursor-pointer icon-container transform transition-transform hover:scale-125'
                        onClick={(event) => {
                          event.stopPropagation();
                          handleEdit();
                        }}
                      />
                    </HoverTooltip>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default PoDraftSeniorBattleTable;
