import React, { useState } from "react";

import MainLayout from "../maincontent/MainLayout.jsx";
import DraftBattleTable from "../tables/DraftBattleTable.jsx";

const DraftBattlePage = () => {
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>NAREDNE UTAKMICE</div>
        <div className='mt-5 flex flex-col'>
          <>
            <DraftBattleTable />
          </>
        </div>
      </div>
    </MainLayout>
  );
};

export default DraftBattlePage;
