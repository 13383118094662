import React, { useState } from "react";

import MainLayout from "../maincontent/MainLayout";
import NavBar from "../navbars/NavBar.jsx";
import MoneyRefereeTable from "../tables/MoneyRefereeTable";
import MoneyHelperTable from "../tables/MoneyHelperTable";

const MoneyPage = () => {
  const [activePage, setActivePage] = useState(1);

  const navLabels = [`Sudije`, "Pomoćne sudije"];

  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>DUGOVANJA</div>
        <div className='mt-5 flex flex-col'>
          <div className='mb-4 flex flex-wrap justify-center gap-4 '>
            <NavBar navLabels={navLabels} handlePageSelect={(page) => setActivePage(page)} />
          </div>
          <div className='w-full px-8 pb-8 rounded-xl '>
            {activePage === 1 && <MoneyRefereeTable />}
            {activePage === 2 && <MoneyHelperTable />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default MoneyPage;
