import React from "react";

import MainLayout from "../maincontent/MainLayout";
import ListBattlesTable from "../tables/ListBattlesTable";

const ListBattlesPage = () => {
  return (
    <MainLayout>
      <div className='mt-16'>
        <div className='justify-center text-center pb-5 mb-4 text-4xl font-bold'>ZAVRŠENE UTAKMICE</div>
        <div className='flex justify-center items-center'>
          <div className='w-11/12  rounded-xl '>
            <ListBattlesTable />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ListBattlesPage;
