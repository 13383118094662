const ActiveCategorySelect = ({ onSelect, categories, className }) => {
  const handleActiveCategoryChange = (event) => {
    const selectedActiveCategory = event.target.value;
    onSelect(selectedActiveCategory);
  };

  return (
    <select id='clubActiveCategory' className={className} onChange={handleActiveCategoryChange}>
      <option value=''>Izaberi kategoriju</option>
      {categories.map((category) => (
        <option key={category} value={category}>
          {category}
        </option>
      ))}
    </select>
  );
};

export default ActiveCategorySelect;
