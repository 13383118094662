const StatisticTwoSelect = ({ helpers, battleDate, onSelectHelper }) => {
  const filteredHelpers = helpers.filter((helper) => {
    if (!helper.startDate || !helper.endDate) {
      return true;
    }

    const battleDateObj = new Date(battleDate);
    return battleDateObj < new Date(helper.startDate) || battleDateObj > new Date(helper.endDate);
  });

  return (
    <select
      className='text-gray-800 text-center font-semibold'
      id='helperSelect5'
      onChange={(e) => onSelectHelper(e.target.value)}
    >
      <option value=''>Statistika</option>
      {filteredHelpers.map((helper, index) => (
        <option key={index} value={helper._id}>
          {helper.firstName} {helper.lastName}
        </option>
      ))}
    </select>
  );
};

export default StatisticTwoSelect;
