import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import axios from "axios";

import LoadingSpinner from "../other/LoadingSpinner";
import PaginationButtons from "../other/PaginationButtons";
import PoBattlesListTable from "../potable/PoBattlesListTable";
import ActiveCategorySelect from "../selectOptions/ActiveCategorySelect";

import "react-datepicker/dist/react-datepicker.css";

const ListBattlesTable = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeBattles, setActiveBattles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams({ page: currentPage, limit: 10 });
    if (selectedCategory) {
      params.append("category", selectedCategory);
    }
    if (searchQuery) {
      params.append("teamName", searchQuery);
    }

    if (startDate) {
      params.append("startDate", startDate.toISOString());
    }
    if (endDate) {
      params.append("endDate", endDate.toISOString());
    }

    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/finishedBattle?${params.toString()}`)
      .then((response) => {
        setActiveBattles(response.data.battles);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching battles data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, selectedCategory, searchQuery, startDate, endDate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const categories = [
    "Juniori",
    "Kadeti",
    "KadetiB",
    "Pioniri",
    "PioniriB",
    "Minibasket",
    "Juniorke",
    "Kadetkinje",
    "Pionirke",
    "MinibasketZ",
    "Ostalo",
    "Turnir",
  ];

  const battlesToMap = activeBattles || [];

  return (
    <div className='flex flex-col items-center justify-center '>
      <div>
        <React.Fragment>
          <div className='flex justify-between'>
            <div className=' flex flex-row'>
              <div className='flex  justify-center items-center mb-3'>
                <div className='flex items-center '>
                  <ActiveCategorySelect
                    onSelect={handleCategoryChange}
                    categories={categories}
                    className='border border-gray-700 flex rounded px-2 py-1 max-w-full text-black'
                  />
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <input
                  type='text'
                  placeholder='Pretraži timove...'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className='px-3 py-1 ml-3 border border-gray-700 rounded-md mb-3'
                />
                <div className='py-1 mb-3'>
                  <img src='icons/searchIcon.svg' alt='Search Icon' className='ml-2 h-6 w-6 self-center' />
                </div>
              </div>
            </div>
            <div className=' flex flex-row'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText='Od datuma'
                className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText='Do datuma'
                className='px-1 py-1 border w-32 text-center border-gray-700 rounded-md mb-3 ml-3'
              />
            </div>
          </div>

          {isLoading ? (
            <div className='flex justify-center items-center h-80'>
              <LoadingSpinner />
            </div>
          ) : (
            battlesToMap.map((battle) => <PoBattlesListTable key={battle._id} battle={battle} />)
          )}
        </React.Fragment>
        <PaginationButtons currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default ListBattlesTable;
